const CMS_MM_REQUEST_GENERIC = 'inquisit-app/CMS/CMS_MM_REQUEST_GENERIC';
const CMS_MM_SUCCESS_GENERIC = 'inquisit-app/CMS/CMS_MM_SUCCESS_GENERIC';
const CMS_MM_FAIL_GENERIC = 'inquisit-app/CMS/CMS_MM_FAIL_GENERIC';

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getMinimodules(selectedRoot, mmType) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_minimodules', {
      params: {
        root_id: selectedRoot,
        mm_type: mmType
      }
    })
  };
}

export function getBaseMinimodules(minimoduleBaseId) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_base_minimodules', {
      params: {
        minimodule_base_id: minimoduleBaseId
      }
    })
  };
}

export function getMinimoduleContentAndQuestions(minimoduleId) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_minimodule_content_and_questions', {
      params: {
        minimodule_id: minimoduleId
      }
    })
  };
}

export function getSets(minimodule_id) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_sets', {
      params: {
        minimodule_id: minimodule_id
      }
    })
  };
}

export function getCards(stack_set_id) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_cards', {
      params: {
        stack_set_id: stack_set_id
      }
    })
  };
}


export function getAllActivityTypes() {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_all_activity_types')
  };
}

export function getActivity(stack_id) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_stack_activity', {
      params: {
        stack_id: stack_id
      }
    })
  };
}

export function getStackActivityContent(stack_activity_id) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_stack_activity_content', {
      params: {
        stack_activity_id: stack_activity_id
      }
    })
  };
}

export function getKeyBehaviors(orgId) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/key_behaviors', {
      params: {
        org_id: orgId
      }
    })
  };
}

export function createMinimoduleBase(minimoduleBase) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_minimodule_base', {
      data: {
        minimodule_base: minimoduleBase,
        et_ind: 1,
      }
    })
  };
}

export function updateMinimoduleBase(minimoduleBase) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_minimodule_base', {
      data: {
        minimodule_base: minimoduleBase,
        et_ind: 1,
      }
    })
  };
}

export function createMinimodule(data) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_minimodule', {
      data: {
        minimodule: data.minimodule,
        rise_file: data.riseFile,
        et_ind: 1,
      }
    })
  };
}

export function updateMinimodule(data) {
  return {
    types: [CMS_MM_REQUEST_GENERIC, CMS_MM_SUCCESS_GENERIC, CMS_MM_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_minimodule', {
      data: {
        minimodule: data.minimodule,
        rise_file: data.riseFile,
        et_ind: 1,
      }
    })
  };
}
