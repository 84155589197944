const ENROLL_NEXT_STEP = 'inquisit-app/enroll/ENROLL_NEXT_STEP';
const ENROLL_PREV_STEP = 'inquisit-app/enroll/ENROLL_PREV_STEP';
const ENROLL_SET_STEP = 'inquisit-app/enroll/ENROLL_SET_STEP';
const ENROLL_UPDATE_COMPLETE_FLAG = 'inquisit-app/enroll/ENROLL_UPDATE_COMPLETE_FLAG';
const ENROLL_SET_WAITLIST_STATUS = 'inquisit-app/enroll/ENROLL_SET_WAITLIST_STATUS';

const ENROLL_SET_MENTEE_ID_AND_BASE_ID = 'inquisit-app/enroll/ENROLL_SET_MENTEE_ID_AND_BASE_ID';
const ENROLL_SET_MENTOR_ID = 'inquisit-app/enroll/ENROLL_SET_MENTOR_ID';
const ENROLL_SET_PROGRAM = 'inquisit-app/enroll/ENROLL_SET_PROGRAM';

const ENROLL_GENERAL_SUCCESS = 'inquisit-app/enroll/ENROLL_GENERAL_SUCCESS';
const ENROLL_LOAD_REQUESTED = 'inquisit-app/enroll/ENROLL_LOAD_REQUESTED';
const ENROLL_FAIL = 'inquisit-app/enroll/ENROLL_FAIL';
const ENROLL_SET_MENTEE_SUCCESS = 'inquisit-app/enroll/ENROLL_SET_MENTEE_SUCCESS';
const ENROLL_SET_AVAILABILITY_SUCCESS = 'inquisit-app/enroll/ENROLL_SET_AVAILABILITY_SUCCESS';
const ENROLL_SELECTED_MENTORS_SUCCESS = 'inquisit-app/enroll/ENROLL_SELECTED_MENTORS_SUCCESS';
const ENROLL_CALL_TIMES_SUCCESS = 'inquisit-app/enroll/ENROLL_CALL_TIMES_SUCCESS';
const ENROLL_NULL_CALL_TIMES_SUCCESS = 'inquisit-app/enroll/ENROLL_NULL_CALL_TIMES_SUCCESS';
const ENROLL_GET_MPB_ID = 'inquisit-app/enroll/ENROLL_GET_MPB_ID';
const ENROLL_CREATE_PROGRAM_NODE_SUCCESS = 'inquisit-app/enroll/ENROLL_CREATE_PROGRAM_NODE_SUCCESS';

const ENROLL_SET_PROGRAM_BASE_INFO = 'inquisit-app/enroll/ENROLL_SET_PROGRAM_BASE_INFO';
const ENROLL_REINITIALIZE_STATE = 'inquisit-app/enroll/ENROLL_REINITIALIZE_STATE';
const ENROLL_UPDATE_REMATCH_IND = 'inquisit-app/enroll/ENROLL_UPDATE_REMATCH_IND';

const MATCH_MODE_FLAG = 'inquisit-app/enroll/MATCH_MODE_FLAG';
const CHALLENGE_UPDATE_MODE_FLAG = 'inquisit-app/enroll/CHALLENGE_UPDATE_MODE_FLAG';

const initialState = {
  mentee_id: null,
  mentor_id: 0,
  programBase: {
    baseId: 0,
    nodeId: 0,
    program: ""
  },
  loading: false,
  steps: [
    { title: 'Mentee Information', desc: 'Basic information about the mentee', path: 'mentee-information'},
    { title: 'Challenges', desc: 'Prioritize and stage challenges.', path: 'challenges'},
    { title: 'Mentee\'s Availability', desc: 'Mentee\'s general availability', path: 'get-mentee-availability'},
    { title: 'Match with Mentor', desc: 'Select prospective best-fit mentor(s).', path: 'match-with-mentor'},
    { title: 'Schedule the First Call', desc: 'Pick date & time for the first call.', path: 'confirmation'}
  ],
  selectedMentors: [],
  callTimes: {},
  waitlistStatus: 0,
  step: 1,
  enrolled: false,
  matchMode: false,
  isRematch: false,
  challengeUpdateMode: false,
  selectedProgram: "mp"
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ENROLL_LOAD_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case ENROLL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ENROLL_NEXT_STEP:
      return {
        ...state,
        step: action.result.step
      };
    case ENROLL_PREV_STEP:
      return {
        ...state,
        step: action.result.step
      };
    case ENROLL_SET_STEP:
      return {
        ...state,
        step: action.result.step
      };
    case ENROLL_UPDATE_COMPLETE_FLAG:
      return {
        ...state,
        enrolled: action.result
      };
    case ENROLL_SET_WAITLIST_STATUS:
      return {
        ...state,
        waitlistStatus: action.result
      };
    case ENROLL_UPDATE_REMATCH_IND:
      return {
        ...state,
        isRematch: action.result
      };
    case ENROLL_SELECTED_MENTORS_SUCCESS:
      return {
        ...state,
        selectedMentors: action.result.selectedMentors
      };
    case ENROLL_SET_MENTEE_ID_AND_BASE_ID:
      return {
        ...state,
        mentee_id: action.result.mentee_id,
        programBase: {
          ...state.programBase, 
          baseId: action.result.base_id !== null ? action.result.base_id : state.programBase.baseId,
          program: action.result.program !== null ? action.result.program : state.programBase.baseId
        }
      };
    case ENROLL_SET_PROGRAM:
      return {
        ...state,
        selectedProgram: action.result.selectedProgram
      };
    case ENROLL_SET_MENTOR_ID:
      return {
        ...state,
        mentor_id: action.result.mentor_id
      };
    case ENROLL_CALL_TIMES_SUCCESS:
      return {
        ...state,
        callTimes: action.result.callTimes
      };
    case ENROLL_NULL_CALL_TIMES_SUCCESS:
      return {
        ...state,
        callTimes: action.result.callTimes
      };
    case ENROLL_SET_PROGRAM_BASE_INFO:
      return {
        ...state,
        programBase: action.result
      };
    case MATCH_MODE_FLAG:
      return {
        ...state,
        matchMode: action.result.matchMode
      };
    case CHALLENGE_UPDATE_MODE_FLAG:
      return {
        ...state,
        challengeUpdateMode: action.result.challengeUpdateMode
      };
    case ENROLL_GET_MPB_ID: 
      state.programBase.baseId = action.result.base_id;
      return {
        ...state,
        loading: false
      };
    case ENROLL_REINITIALIZE_STATE:
      return {
        ...state,
        step: 1,
        mentee_id: null,
        mentor_id: 0,
        programBase: {
          baseId: 0,
          nodeId: 0,
          program: ""
        },
        selectedMentors: [],
        callTimes: {},
        waitlistStatus: 0,
        enrolled: false,
        selectedProgram: "mp"
      };
    case ENROLL_GENERAL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export function getOutreachTargetDetails(data) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getoutreach/outreach_target_details', {
      params: {
        outreach_target_id: data.outreach_target_id
      }
    })
  };
}

export function getMenteeClinicalDemographicFields(data) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getenrolldata/get_mentee_demo_clinical_fields', {
      params: {
        org_id: data.org_id,
        mentee_id: data.mentee_id,
        outreach_target_id: data.outreach_target_id
      }
    })
  };
}

export function getMenteeDependencyFields(data) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getenrolldata/get_dependency_fields', {
      params: {
        mentee_id: data.mentee_id,
        attr_base_id: data.attr_base_id,
        outreach_target_id: data.outreach_target_id,
      }
    })
  };
}

export function setSelectedMentors(mentors) {
  return {
    type: ENROLL_SELECTED_MENTORS_SUCCESS,
    result: {
      selectedMentors: mentors
    }
  };
}

export function setCallTimes(times) {
  return {
    type: ENROLL_CALL_TIMES_SUCCESS,
    result: {
      callTimes: times
    }
  };
}

export function nullOutCallTimes() {
  return {
    type: ENROLL_NULL_CALL_TIMES_SUCCESS,
    result: {
      callTimes: {}
    }
  };
}

export function getTimezones() {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getcommondata/timezones', {
      params: {}
    })
  };
}

export function getPrimaryLanguages() {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getcommondata/primary_languages', {
      params: {}
    })
  };
}

export function getStateList() {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getcommondata/state_list', {
      params: {}
    })
  };
}

export function getMenteeAttributes(menteeId) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getmenteesdata/mentee_attributes', {
      params: {
        mentee_id: menteeId,
        admin_ind: 1
      }
    })
  };
}

export function getMatchFirstCallOverlappingDatesTimes(data) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getenrolldata/match_first_call_overlapping_dates_times', {
      params: {
        mentor_availability_type: data.mentorAvailabilityType,
        mentee_id: data.menteeId,
        start_date: data.startDate,
        end_date: data.endDate,
        available_days: data.availableDays
      }
    })
  };
}

export function getEnrollFilters(orgId, associatedOrgs) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getenrolldata/enroll_filters', {
      params: {
        org_id: orgId,
        associated_orgs: associatedOrgs
      }
    })
  };
}


// export function getMentorsMatchByAvailability(rootId, menteeId, associatedOrgs, program) {
//   return {
//     types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
//     promise: (client) => client.get('/getenrolldata/available_mentor_matches', {
//       params: {
//         root_id: rootId,
//         mentee_id: menteeId,
//         associated_orgs: associatedOrgs,
//         program: program
//       }
//     })
//   };
// }


export function sendMentorConfirmationEmail(object) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.post('/enroll/send_mentor_confirmation_email', {
      data: object
    })
  };
}

export function saveMenteeAvailability(object) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_SET_AVAILABILITY_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.post('/admin/setmenteedata/save_availability_data', {
      data: object
    })
  };
}




export function createProgramNode(data) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_CREATE_PROGRAM_NODE_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.post('/setenrolldata/create_program_node', {
      data: data
    })
  };
}

export function createMenteePersonalizedPathway(data) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.post('/setmobileappdata/generate_mentee_pathway', {
      data: {
        mentee_id: data.mentee_id,
        ppq_user_instance_id: data.ppq_user_instance_id
      }
    })
  };
}

export function clearPhoneNumberForExistingMenteesMentors(phoneNumber) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.post('/utility/clear_phone_number_for_existing_mentees_mentors', {
      data: {
        phone_number: phoneNumber
      }
    })
  };
}

export function setEnrollMenteeIdBaseId(menteeId, baseId, program = "mp") {
  return {
    type: ENROLL_SET_MENTEE_ID_AND_BASE_ID,
    result: {
      mentee_id: menteeId,
      base_id: baseId,
      program: program
    }
  };
}

export function setEnrollMentorId(mentorId) {
  return {
    type: ENROLL_SET_MENTOR_ID,
    result: {
      mentor_id: mentorId
    }
  };
}

export function setEnrollProgramBase(data) {
  return {
    type: ENROLL_SET_PROGRAM_BASE_INFO,
    result: {
      baseId: data.baseId,
      nodeId: data.nodeId
    }
  };
}

export function prevEnrollStep(stepValue) {
  return {
    type: ENROLL_PREV_STEP,
    result: {
      step: stepValue - 1
    }
  };
}

export function nextEnrollStep(stepValue) {
  return {
    type: ENROLL_NEXT_STEP,
    result: {
      step: stepValue + 1
    }
  };
}

export function setEnrollStep(stepValue) {
  return {
    type: ENROLL_SET_STEP,
    result: {
      step: stepValue
    }
  };
}

export function setWaitListStatus(status) {
  return {
    type: ENROLL_SET_WAITLIST_STATUS,
    result: status
  };
}

export function updateEnrolledFlag(enrolled) {
  return {
    type: ENROLL_UPDATE_COMPLETE_FLAG,
    result: enrolled
  };
}

export function setRematchedInd(val) {
  return {
    type: ENROLL_UPDATE_REMATCH_IND,
    result: val
  };
}

export function setMatchMode(mode) {
  return {
    type: MATCH_MODE_FLAG,
    result: {
      matchMode: mode
    }
  };
}

export function setChallengeMode(mode) {
  return {
    type: CHALLENGE_UPDATE_MODE_FLAG,
    result: {
      challengeUpdateMode: mode
    }
  };
}

export function setProgram(program) {
  return {
    type: ENROLL_SET_PROGRAM,
    result: {
      selectedProgram: program
    }
  };
}

export function getMenteeAvailableDaysTimes(data) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getmenteesdata/mentee_availability', {
      params: {
        mentee_id: data.menteeId,
      }
    })
  };
}

export function getMentorPhoneNumbers(data) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/getmentoraccount/phone_numbers', {
      params: {
        mentor_id: data.mentorId,
      }
    })
  };
}

export function resetEnrollStates() {
  return {
    type: ENROLL_REINITIALIZE_STATE,
    result: {
      reset: true
    }
  };
}

export function getApplicableMatchingProps(rootId) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/admin/general/get_applicable_matching_props', {
      params: {
        root_id: rootId,
      }
    })
  };
}

export function getApplicableNavigatorMatchingProps(rootId) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.get('/admin/general/get_applicable_navigator_matching_props', {
      params: {
        root_id: rootId,
      }
    })
  };
}


export function setApplicableMatchingProp(propObject) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.post('/admin/setadministrationdata/set_applicable_matching_prop', {
      data: { ...propObject, et_ind: 1 }
    })
  };
}

export function setApplicableNavigatorMatchingProp(propObject) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_GENERAL_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.post('/admin/setadministrationdata/set_applicable_navigator_matching_prop', {
      data: { ...propObject, et_ind: 1 }
    })
  };
}

export function saveMenteeInformation(object) {
  return {
    types: [ENROLL_LOAD_REQUESTED, ENROLL_SET_MENTEE_SUCCESS, ENROLL_FAIL],
    promise: (client) => client.post('enroll/set_mentee_information', {
      data: object
    })
  };
}