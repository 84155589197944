// const ACTIVATE_BIG_NUMBER = 'inquisit-app/mentee-list/activate-big-number';
const STORE_ES_MENTEE_DATA = 'inquisit-app/mentee-list/save-filtered-mentee-data';
const UPDATE_ALL_FILTERS = 'inquisit-app/mentee-list/update-all-filters';
const ADD_DASHBOARD_FILTER = 'inquisit-app/mentee-list/update-dashboard-filter';
const CLEAR_DASHBOARD_FILTER = 'inquisit-app/mentee-list/clear-dashboard-filter';
const RESET_FILTERS = 'inquisit-app/mentee-list/reset-filter';
const LOGOUT_SUCCESS = 'inquisit-app/auth/LOGOUT_SUCCESS';
const LIST_CUSTOM_FILTER = 'inquisit-app/mentee-list/list-custom-filter';
const RESET_LIST_CUSTOM_FILTERS = 'inquisit-app/mentee-list/reset-list-custom-filter';
const COMING_FROM_DASHBOARD = 'inquisit-app/mentee-list/coming-from-dashboard';
const initialState = {
  filterUpdater: 0,
  dashboardFilter: {}, 
  selectedSlug: 'call_today', 
  openAreasSlugs: [], 
  selectedSort: 'systemPrioritized', 
  searchTerm: '', 
  isMetricsExpanded: false,
  customFilter: {
    listingTypeOption: 'all',
    extraFilterTypeOptions: null,
    availableNowTypeOptions: null,
  },
  comingFromDashboard: false,
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
	    case UPDATE_ALL_FILTERS:
	        return {
		        ...state,
		        filters: action.result
	        };
      case ADD_DASHBOARD_FILTER:
        return {
          ...state,
          dashboardFilter: action.result
        };
      case CLEAR_DASHBOARD_FILTER:
        return {
          ...state,
          dashboardFilter: {}
        };
      case RESET_FILTERS:
        return {
            ...state,
            filter_updater:0
          };
      case STORE_ES_MENTEE_DATA: 
        return {
          ...state, 
          ...action.result
        }
      case LIST_CUSTOM_FILTER:
        return {
          ...state,
          customFilter: {...state.customFilter,...action.result}
        }
      case RESET_LIST_CUSTOM_FILTERS:
        return {
          ...state,
          customFilter: {
            listingTypeOption: 'all',
            extraFilterTypeOptions: null,
            availableNowTypeOptions: null,
          }
        }
      case COMING_FROM_DASHBOARD:
        return {
          ...state,
          comingFromDashboard: action.result
        }
      case LOGOUT_SUCCESS: 
        return {
          filterUpdater: 0,
          dashboardFilter: {}, 
          selectedSlug: 'call_today', 
          openAreasSlugs: [], 
          selectedSort: 'systemPrioritized', 
          searchTerm: '', 
          isMetricsExpanded: false,
          customFilter: {
            listingTypeOption: 'all',
            extraFilterTypeOptions: null,
            availableNowTypeOptions: null,
          },
        }; 
	    default:
	      	return state;
	}
}


export function storeESMenteeData(selectedOptions) {
  return {
    type: STORE_ES_MENTEE_DATA,
    result: {
      ...selectedOptions
    },
  };
}

export function addDashboardFilter(filterObj) {
  return {
    type: ADD_DASHBOARD_FILTER,
    result: filterObj
  };
}

export function clearDashboardFilter() {
  return {
    type: CLEAR_DASHBOARD_FILTER
  };
}

export function resetSelectedFilters() {
  return {
    type: RESET_FILTERS
  };
}

export function setListCustomFilter(filterObj) {
  return {
    type: LIST_CUSTOM_FILTER,
    result: filterObj
  };
}

export function resetListCustomFilter() {
  return {
    type: RESET_LIST_CUSTOM_FILTERS
  };
}

export function setComingFromDashboard(ind) {
  return {
    type: COMING_FROM_DASHBOARD,
    result: ind
  };
}