const GR_GOAL_REVIEW_LIST_REQUEST = 'inquisit-app/gr/GR_GOAL_REVIEW_LIST_REQUEST';
const GR_GOAL_REVIEW_LIST_SUCCESS = 'inquisit-app/gr/GR_GOAL_REVIEW_LIST_SUCCESS';
const GR_GOAL_REVIEW_LIST_FAIL = 'inquisit-app/gr/GR_GOAL_REVIEW_LIST_FAIL';
const GR_GENERIC_REQUEST = 'inquisit-app/gr/GR_GENERIC_REQUEST';
const GR_GENERIC_SUCCESS = 'inquisit-app/gr/GR_GENERIC_SUCCESS';
const GR_GENERIC_FAIL = 'inquisit-app/gr/GR_GENERIC_FAIL';

const initialState = {
  loading: false,
  goalReviewList: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GR_GOAL_REVIEW_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GR_GOAL_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        goalReviewList: action.result && action.result.data ? action.result.data : []
      };
    case GR_GOAL_REVIEW_LIST_FAIL:
      return {
        ...state,
        loading: false
      };
    case GR_GENERIC_REQUEST:
      return{
        ...state,
        loading: true
      };
    case GR_GENERIC_SUCCESS:
      return{
        ...state,
        loading: false
      };
    case GR_GENERIC_FAIL:
      return{
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export function getGoalReviewQueueList() {
  return {
    types: [GR_GOAL_REVIEW_LIST_REQUEST, GR_GOAL_REVIEW_LIST_SUCCESS, GR_GOAL_REVIEW_LIST_FAIL],
    promise: (client) => client.get('/goalreviews/get_goal_review_queue_list')
  };
}

export function updateGoalReviewQueueList(mentorId, goalId){
  return{
    types: [GR_GENERIC_REQUEST,GR_GENERIC_SUCCESS,GR_GENERIC_FAIL],
    promise: (client) => client.post('goalreviews/update_goal_review_queue_list',{
      data: {
        mentor_id: mentorId,
        goal_id: goalId
      }
    })
  };
}

export function getGoalById(goalId) {
  return {
    types: [GR_GENERIC_REQUEST,GR_GENERIC_SUCCESS,GR_GENERIC_FAIL],
    promise: (client) => client.get('es/get_goal_by_id',{
      params: {
        goal_id: goalId
      }
    })
  };
} 

export function setGoalScore(goalId, score) {
  return {
    types: [GR_GENERIC_REQUEST,GR_GENERIC_SUCCESS,GR_GENERIC_FAIL],
    promise: (client) => client.post('es/set_goal_score',{
      data: {
        goal_id: goalId,
        score: score
      }
    })
  };
}

export function setGoalMentorNote(data) {
  return {
    types: [GR_GENERIC_REQUEST,GR_GENERIC_SUCCESS,GR_GENERIC_FAIL],
    promise: (client) => client.post('mentor/mentorprofile/set_goal_note_to_mentor',{
      data: data
    })
  };
}