const LOAD_GENERIC = 'inquisit-app/copilot/LOAD_COPILOT_TP_GENERIC';
const SUCCESS_GENERIC = 'inquisit-app/copilot/SUCCESS_COPILOT_TP_GENERIC';
const FAIL_GENERIC = 'inquisit-app/copilot/FAIL_COPILOT_TP_GENERIC';

const GENERIC = [LOAD_GENERIC, SUCCESS_GENERIC, FAIL_GENERIC];
const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getConversationGuideTopicTalkingPoints(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_topic_talking_points', {
      params: data
    })
  };
}

export function getIssueTalkingPoints(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_issue_talking_points', {
      params: data
    })
  };
}

export function getTalkingPointsGuider(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_talking_points_guider', {
      params: data
    })
  };
}

export function getPNTalkingPointsGuider(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_pn_talking_points_guider', {
      params: data
    })
  };
}

export function getGoalTalkingPoints(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_goal_talking_points', {
      params: data
    })
  };
}

export function insertCustomTalkingPoint(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/insert_custom_talking_point', {
      params: data
    })
  };
}

export function checkUncheckTalkingPoint(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/check_uncheck_copilot_talking_point', {
      params: data
    })
  };
}

export function checkUncheckPNTalkingPoint(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/check_uncheck_pn_copilot_talking_point', {
      params: data
    })
  };
}


export function deleteCustomTalkingPoint(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/delete_copilot_custom_talking_point', {
      params: data
    })
  };
}


