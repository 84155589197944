const EXTERNAL_ENTITY_TX_REQUEST_GENERIC = 'inquisit-app/external_entity_tx/EXTERNAL_ENTITY_TX_REQUEST_GENERIC';
const EXTERNAL_ENTITY_TX_SUCCESS_GENERIC = 'inquisit-app/external_entity_tx/EXTERNAL_ENTITY_TX_SUCCESS_GENERIC';
const EXTERNAL_ENTITY_TX_FAIL_GENERIC = 'inquisit-app/external_entity_tx/EXTERNAL_ENTITY_TX_FAIL_GENERIC';
const GENERIC = [EXTERNAL_ENTITY_TX_REQUEST_GENERIC,EXTERNAL_ENTITY_TX_SUCCESS_GENERIC,EXTERNAL_ENTITY_TX_FAIL_GENERIC];

const CLEAR_EE_CALL_SID = 'inquisit-app/external_entity_tx/CLEAR_EE_CALL_SID';
const RESET_EXTERNAL_ENTITY_CALL_TX = 'inquisit-app/external_entity_tx/RESET_EXTERNAL_ENTITY_CALL_TX';
const SET_EE_CALL_SID = 'inquisit-app/external_entity_tx/SET_EE_CALL_SID';
const SET_EE_PN = 'inquisit-app/external_entity_tx/SET_EE_PN';
// const SET_EE_TX_ID = 'inquisit-app/external_entity_tx/SET_EE_TX_ID';
const TOGGLE_EE_MODAL = 'inquisit-app/external_entity_tx/TOGGLE_EE_MODAL';


const RESET_SOP_REQUEST = 'inquisit-app/sop/RESET_SOP_REQUEST';


const initialState = {
  externalEntityCallSid: null,
  phoneNumber: '',
  showStatusModal: false, 
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_SOP_REQUEST:
    case RESET_EXTERNAL_ENTITY_CALL_TX:
      return {
        ...state,
        phoneNumber: '',
        showStatusModal: false,
        externalEntityCallSid: null,
      }
    case CLEAR_EE_CALL_SID:
      return {
        ...state,
        externalEntityCallSid: null
      };
    case SET_EE_PN:
      return {
        ...state,
        phoneNumber: action.data
      };
    case SET_EE_CALL_SID:
      return {
        ...state,
        externalEntityCallSid: action.data
      };
    
    case TOGGLE_EE_MODAL:
      return {
        ...state,
        showStatusModal: action.data,
      }
    default:
      return state;
  }
}

//STATE

export function clearExternalEntityCallSid() {
  return {
    type: CLEAR_EE_CALL_SID,
  };
}

export function resetExternalEntityTx() {
  return {
    type: RESET_EXTERNAL_ENTITY_CALL_TX
  };
}

export function setExternalEntityCallSid(callSid) {
  return {
    type: SET_EE_CALL_SID,
    data: callSid
  };
}

export function setExternalEntityPhoneNumber(phoneNumber) {
  return {
    type: SET_EE_PN,
    data: phoneNumber
  };
}

export function toggleExternalEntityCallCompleteModal(shown) {
  return {
    type: TOGGLE_EE_MODAL,
    data: shown ? true : false,
  };
}

//GET

export function getExternalEntityCallTxStatusList(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/external_entity_tx/get_external_entity_call_tx_status_list', {
      params: params
    })
  };
}

export function getExternalEntityCallTxListByMenteeId(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/external_entity_tx/get_external_entity_call_tx_list_by_mentee_id', {
      params: params
    })
  };
}

export function getAllAdminsOcs(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/admin/general/get_admins_and_ocs', {
      params: params
    })
  };
}

//SET
export function updateExternalResourceCallWithId(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/external_entity_tx/update_external_entity_call_with_id', {
      data: data
    })
  };
}

export function updateOutreachExternalResourceCallWithCallSid(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/external_entity_tx/update_outreach_external_entity_call_with_call_sid', {
      data: data
    })
  };
}

export function addManualExternalEntityCallTx(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/external_entity_tx/add_manual_external_entity_call_tx', {
      data: data
    })
  };
}