import Pusher from 'pusher-js/with-encryption';
import { findIndex } from 'lodash';
import store from '../store';
import {
  pusherAuth,
  initializePusher,
  initializeMenteePusherChannel
} from '../redux/modules/pusher/pusher';
import { getInAppAlerts } from 'redux/modules/alerts';

export const PusherHelper = {
  init() {
    const state = store.getState();
    const user = state.auth.user;
    if(user && (state.pusher.userChannel === null || state.pusher.groupChannel === null)){
      const pusher_config = {
        appId: `${process.env.REACT_APP_PUSHER_APP_ID}`,
        key: `${process.env.REACT_APP_PUSHER_APP_KEY}`,
        secret: `${process.env.REACT_APP_PUSHER_APP_SECRET}`,
        cluster: "mt1",
        useTLS: true,
        encryptionMasterKeyBase64: `${process.env.REACT_APP_PUSHER_ENCRYPTION_KEY}`,
        authorizer:(channel) => {
          return {
            authorize: (socketId, callback) => {
              store.dispatch(pusherAuth(socketId, channel.name)).then((res) => {
                return res.result;
              }).then(data => { if(data && data.hasOwnProperty('auth') && data.hasOwnProperty('shared_secret')) callback(null, data)});
            }
          };
        }
      };
      // Pusher.log = msg => console.log(msg);
      const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_APP_KEY}`, pusher_config);
      const userChannel = state.pusher.userChannel ? state.pusher.userChannel : pusher.subscribe(`private-encrypted-single-${user.group}-${user.id}`);
      const groupChannel = state.pusher.groupChannel ? state.pusher.groupChannel : pusher.subscribe(`private-encrypted-group-${user.group}`);
      store.dispatch(getInAppAlerts());
      store.dispatch(initializePusher(userChannel, groupChannel));
    }
  },

  initMenteeChannel(menteeId) {
    const state = store.getState();
    const user = state.auth.user;
    if(user && (state.pusher.menteeChannel === null)){
      const pusher_config = {
        appId: `${process.env.REACT_APP_PUSHER_APP_ID}`,
        key: `${process.env.REACT_APP_PUSHER_APP_KEY}`,
        secret: `${process.env.REACT_APP_PUSHER_APP_SECRET}`,
        cluster: "mt1",
        useTLS: true,
        encryptionMasterKeyBase64: `${process.env.REACT_APP_PUSHER_ENCRYPTION_KEY}`,
        authorizer:(channel) => {
          return {
            authorize: (socketId, callback) => {
              store.dispatch(pusherAuth(socketId, channel.name)).then((res) => {
                return res.result;
              }).then(data => { if(data && data.hasOwnProperty('auth') && data.hasOwnProperty('shared_secret')) callback(null, data)});
            }
          };
        }
      };
      // Pusher.log = msg => console.log(msg);
      const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_APP_KEY}`, pusher_config);
      const menteeChannel = state.pusher.menteeChannel ? state.pusher.menteeChannel : pusher.subscribe(`private-encrypted-mentee-${menteeId}`);
      store.dispatch(initializeMenteePusherChannel(menteeChannel));
    }
  },
  disconnect() {
    const state = store.getState();
    if(state.pusher.userChannel !== null) state.pusher.userChannel.disconnect();
    if(state.pusher.groupChannel !== null) state.pusher.groupChannel.disconnect();
    store.dispatch(initializePusher(null, null));
  },
  menteeChannelDisconnect(){
    const state = store.getState();
    if(state.pusher.menteeChannel !== null) state.pusher.menteeChannel.disconnect();
    store.dispatch(initializeMenteePusherChannel(null));
  },
  isNotBound(channel, event){
    if(channel){
      let keys = Object.keys(channel.callbacks._callbacks);
      return findIndex(keys, (key) => {return key === '_'+event;}) === -1 ? true : false;
    }
  },
  isNotBoundToFunction(channel, event, callback){
    if(channel){
      let callbacks = channel.callbacks._callbacks['_'+event];
      return findIndex(callbacks, (func) => {return func.fn === callback}) === -1 ? true : false;
    }
  }
}