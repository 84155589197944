// @flow
const GENERIC_LOAD = 'GET_ES_GENERIC_MENTOR_DASHBOARD_REQUEST';
const GENERIC_SUCCESS = 'GET_ES_GENERIC_MENTOR_DASHBOARD_SUCCESS';
const GENERIC_FAIL = 'GET_ES_GENERIC_MENTOR_DASHBOARD_FAIL';
const GENERIC = [GENERIC_LOAD, GENERIC_SUCCESS, GENERIC_FAIL];
const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getEsMenteeInteractionPointsDashboardStats(mentor_id) {
  return {
    types: GENERIC,
    promise: client =>
      client.get(
        '/mentor/dashboard/mentee_interaction_points_dashboard_stats',
        mentor_id ? { params: { mentor_id: mentor_id } } : { params: null },
      ),
  };
}

export function getEsMenteeOutcomesDashboardStats(mentor_id) {
  return {
    types: GENERIC,
    promise: client =>
      client.get( '/mentor/dashboard/mentee_outcomes_dashboard_stats',
        mentor_id ? { params: { mentor_id: mentor_id } } : { params: null },
      ),
  };
}

export function getEsMenteeOutcomesDetails() {
  return {
    types: GENERIC,
    promise: client => client.get('/mentor/dashboard/get_mentee_outcomes_details'),
  };
}

export function getMentorDashboardStats() {
  return {
    types: GENERIC,
    promise: client => client.get('/mentor/dashboard/get_mentor_dashboard_stats'),
  };
}
