const GENERIC_REQUEST = 'inquisit-app/seq/GENERIC_REQUEST';
const GENERIC_SUCCESS = 'inquisit-app/seq/GENERIC_SUCCESS';
const GENERIC_FAIL = 'inquisit-app/seq/GENERIC_FAIL';

const initialState = {
  loading: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

/*
  *******
  *******
   Get
  *******
  *******
  */


export function getSequenceActions(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_sequence_actions', {
      params: data
    })
  };
}

export function getSequenceInstances(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_sequence_instances', {
      params: data
    })
  };
}

export function getSequenceInstanceDetails(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_sequence_instance_details', {
      params: data
    })
  };
}

export function getActiveConnectSequenceTimeline(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_active_connect_sequence_timeline', {
      params: data
    })
  };
}

export function getStepletDetails(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_steplet_details', {
      params: data
    })
  };
}


/*
  *******
  *******
   Set
  *******
  *******
  */

export function activateNewSequence(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('es/sequence/activate_new_sequence', {
      data: data
    })
  };
}

export function markSequenceReviewed(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('es/sequence/mark_sequence_reviewed', {
      data: data
    })
  };
}

export function saveSeqInstActionsProps(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('es/sequence/save_sequence_instance_actions_props', {
      data: data
    })
  };
}

export function abortESSequenceInstance(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('es/sequence/abort_es_sequence_instance', {
      data: data
    })
  };
}
