import _ from 'lodash';

const GENERIC_REQUEST = 'inquisit-app/outreach/console/GENERIC_LOAD_REQUESTED';
const GENERIC_FAIL = 'inquisit-app/outreach/console/GENERIC_LOAD_FAIL';
const GENERIC_SUCCESS = 'inquisit-app/outreach/console/GENERIC_SUCCESS';
const UPDATE_OUTREACH_CONSOLE_LISTING = 'inquisit-app/outreach/console/UPDATE_OUTREACH_CONSOLE_LISTING';
const UPDATE_LIST_PROGRESS = 'inquisit-app/copilot/UPDATE_LIST_PROGRESS';

const initialState = {
  outreachConsoleList: [],
  listProgress: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_OUTREACH_CONSOLE_LISTING: {
      let unionBy = true;
      let updatedOutreachConsoleList = state.outreachConsoleList;
      if (action.result.data.length > 0) {
        unionBy = false;
        // console.log(action.result.data);
        updatedOutreachConsoleList = unionBy 
        ? _.sortBy(_.unionBy([action.result.data], state.outreachConsoleList)) 
        : state.outreachConsoleList.concat(action.result.data);
      }
      return {
        ...state,
        outreachConsoleList: updatedOutreachConsoleList
      };
    }
    case UPDATE_LIST_PROGRESS:
      return {
        ...state,
        listProgress: action.result
      };
    default:
      return state;
  }
}

export function getOutreachConsoleListing(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/outreachconsole/outreach_console_listing', {
      params: data
    })
  };
}

export function getOutreachConsoleCapacity() {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/outreachconsole/outreach_console_capacity')
  };
}

export function updateOrgMonthlyEnrollmentTarget(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/outreachconsole/update_org_monthly_enrollment_target', {
      data: data
    })
  };
}

export function updateOrgOutreachPriority(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/outreachconsole/update_org_outreach_priority', {
      data: data
    })
  };
}

export function streamOutreachConsoleListing(params) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/outreachconsole/stream_outreach_console_listing', {
      params: params
    })
  };
}

export function updateOutreachConsoleList(data) {
  return {
    type: UPDATE_OUTREACH_CONSOLE_LISTING,
    result: {
      data
    }
  };
}

export function updateListProgress(data) {
  return{
    type: UPDATE_LIST_PROGRESS,
    result: data
  }
}