const LOAD_REQUEST = 'inquisit-app/chat/LOAD_REQUEST';
const FAIL_REQUEST = 'inquisit-app/chat/FAIL_REQUEST';
const SUCCESS_REQUEST = 'inquisit-app/chat/SUCCESS_REQUEST';

const CHANNELS_REQUEST = 'inquisit-app/chat/CHANNELS_REQUEST';

const initialState = {
  channels: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANNELS_REQUEST:
      return {
        ...state,
        channels: action.result
      };
    default:
      return state;
  }
}

export function getChannelsByRoot(rootId) {
  return {
    types: [LOAD_REQUEST, CHANNELS_REQUEST, FAIL_REQUEST],
    promise: (client) => client.get('/groupchat/get_channels', {
      params: {
        root_id: rootId,
      }
    })
  };
}

export function addUserToChat(data) {
  return {
    types: [LOAD_REQUEST, SUCCESS_REQUEST, FAIL_REQUEST],
    promise: (client) => client.post('/groupchat/add_to_channel', {
      data: {
        user_id: data.user_id,
        channel_id: data.channel_id,
        channel_name: data.channel_name
      }
    })
  };
}

export function updateUserGroupChat(data) {
  return {
    types: [LOAD_REQUEST, SUCCESS_REQUEST, FAIL_REQUEST],
    promise: (client) => client.post('/groupchat/update_group_chat', {
      data: data
    })
  };
}

export function markPeerNavigatorMessagesRead(data){
  return {
    types: [LOAD_REQUEST, SUCCESS_REQUEST, FAIL_REQUEST],
    promise: (client) => client.post('/setchatdata/mark_peer_navigator_messages_read', {
      data: data
    })
  };
}

export function sendPushNotificationAndSms(mentee_id, message, isBot, senderName, esProgramNodeId) {
  return {
    types: [LOAD_REQUEST, SUCCESS_REQUEST, FAIL_REQUEST],
    promise: (client) => client.post('/setchatdata/send_mentee_new_message_notification', {
      data: {
        mentee_id: mentee_id,
        message: message,
        is_bot: isBot,
        sender_name: senderName,
        es_program_node_id: esProgramNodeId
      }
    })
  };
}

export function sendMessageViaEmail(mentee_id, message) {
  return {
    types: [LOAD_REQUEST, SUCCESS_REQUEST, FAIL_REQUEST],
    promise: (client) => client.post('/setchatdata/send_mentee_message_via_email', {
      data: {
        mentee_id: mentee_id,
        message: message
      }
    })
  };
}
