// @flow
export const GOALS_REQUEST = 'GOALS_REQUEST';
export const GOALS_FAIL = 'GOALS_FAIL';

export const ACTIVATE_GOAL_REQUEST = 'ACTIVATE_GOAL_REQUEST';
export const ACTIVATE_GOAL_SUCCESS = 'ACTIVATE_GOAL_SUCCESS';
export const ACTIVATE_GOAL_FAIL = 'ACTIVATE_GOAL_FAIL';

export const GET_ES_MENTEE_GOAL_REQUEST = 'GET_ES_MENTEE_GOAL_REQUEST';
export const GET_ES_MENTEE_GOAL_SUCCESS = 'GET_ES_MENTEE_GOAL_SUCCESS';
export const GET_ES_MENTEE_GOAL_FAIL = 'GET_ES_MENTEE_GOAL_FAIL';

export const MARK_GOAL_COMPLETE_REQUEST = 'MARK_GOAL_COMPLETE_REQUEST';
export const MARK_GOAL_COMPLETE_SUCCESS = 'MARK_GOAL_COMPLETE_SUCCESS';
export const MARK_GOAL_COMPLETE_FAIL = 'MARK_GOAL_COMPLETE_FAIL';

export const UPDATE_FREQUENCY_SUCCESS = 'UPDATE_FREQUENCY_SUCCESS';
export const UPDATE_INTERVAL_SUCCESS = 'UPDATE_INTERVAL_SUCCESS';
export const UPDATE_TARGET_DATE_SUCCESS = 'UPDATE_TARGET_DATE_SUCCESS';

export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';

const initialState = {
  loading: false,
  byId: {},
  allIds: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // LOGIN/LOGOUT
    case ACTIVATE_GOAL_REQUEST:
      return { ...state, loading: true, error: '' };
    case ACTIVATE_GOAL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTIVATE_GOAL_FAIL:
      return { ...state, loading: false, error: action.data };

    case GET_ES_MENTEE_GOAL_REQUEST: {
      return { ...state, loading: true, error: '' };
    }

    case GET_ES_MENTEE_GOAL_SUCCESS: {
      let goals = [];
      if (action.result)
        goals = action.result.map(goal => ({ [goal.id]: goal }));
      return {
        ...state,
        loading: false,
        byId: Object.assign({}, ...goals),
      };
    }

    case MARK_GOAL_COMPLETE_SUCCESS: {
      if (action.result.status === 1) {
        const goal = state.goals[action.result.goal_id];
        return { ...state, byId: { ...state.byId, [goal.id]: goal } };
      }
      return { ...state };
    }

    default:
      return state;
  }
}

export function setActivateGoalSteplet(data) {
  return {
    types: [ACTIVATE_GOAL_REQUEST, ACTIVATE_GOAL_SUCCESS, ACTIVATE_GOAL_FAIL],
    promise: client =>
      client.post('/issues/update_goal_steplet', {
        data: data,
      }),
  };
}

export function getEsMenteeGoals(data) {
  return {
    types: [
      GET_ES_MENTEE_GOAL_REQUEST,
      GET_ES_MENTEE_GOAL_SUCCESS,
      GET_ES_MENTEE_GOAL_FAIL,
    ],
    promise: client =>
      client.get('/es/get_es_mentee_goals', {
        params: data,
      }),
  };
}

export function markGoalComplete(data) {
  return {
    types: [
      MARK_GOAL_COMPLETE_REQUEST,
      MARK_GOAL_COMPLETE_SUCCESS,
      MARK_GOAL_COMPLETE_FAIL,
    ],
    promise: client =>
      client.post('/es/set_es_mentee_goal_complete', {
        data: data,
      }),
  };
}

export function updateGoalDetail(data) {
  return {
    types: [
      MARK_GOAL_COMPLETE_REQUEST,
      MARK_GOAL_COMPLETE_SUCCESS,
      MARK_GOAL_COMPLETE_FAIL,
    ],
    promise: client =>
      client.post('/es/set_es_mentee_goal_details', {
        data: data,
      }),
  };
}

export function updateFrequency(data) {
  return {
    types: [GOALS_REQUEST, UPDATE_FREQUENCY_SUCCESS, GOALS_FAIL],
    promise: client =>
      client.post('/es/set_es_mentee_frequency', {
        data: data,
      }),
  };
}

export function updateInterval(data) {
  return {
    types: [GOALS_REQUEST, UPDATE_INTERVAL_SUCCESS, GOALS_FAIL],
    promise: client =>
      client.post('/es/set_es_mentee_interval', {
        data: data,
      }),
  };
}

export function updateTargetDate(data) {
  return {
    types: [GOALS_REQUEST, UPDATE_TARGET_DATE_SUCCESS, GOALS_FAIL],
    promise: client =>
      client.post('/es/set_es_mentee_target_date', {
        data: data,
      }),
  };
}

export function deleteGoal(data) {
  return {
    types: [GOALS_REQUEST, UPDATE_TARGET_DATE_SUCCESS, GOALS_FAIL],
    promise: client =>
      client.post('/es/set_es_mentee_delete_goal', {
        data: data,
      }),
  };
}

export function createGoal(data) {
  return {
    types: [GOALS_REQUEST, UPDATE_TARGET_DATE_SUCCESS, GOALS_FAIL],
    promise: client =>
      client.post('/es/create_es_mentee_goal', {
        data: data,
      }),
  };
}
export function getMenteeGoalStats(data) {
  return {
    types: [GOALS_REQUEST, GET_STATS_SUCCESS, GOALS_FAIL],
    promise: client =>
      client.get('/es/get_mentee_goal_stats', {
        params: data
      }),
  };
}
