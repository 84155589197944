const NOTES_LOAD_REQUESTED = 'inquisit-app/notes/NOTES_LOAD_REQUESTED';
const NOTES_LOAD_FAIL = 'inquisit-app/notes/NOTES_LOAD_FAIL';
const NOTES_CALL_SUCCESS = 'inquisit-app/notes/NOTES_CALL_SUCCESS';

const NOTES_SET = 'inquisit-app/notes/NOTES_SET';

const initialState = {
  adminNotes: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NOTES_LOAD_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case NOTES_SET:
      return {
        ...state,
        adminNotes: action.notes
      };
    case NOTES_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function emailNote(data) {
  return {
    types: [NOTES_LOAD_REQUESTED, NOTES_CALL_SUCCESS, NOTES_LOAD_FAIL],
    promise: (client) => client.post('/admin/email/email_tagged_note', {
      data: data
    })
  };
}

export function addAdminNote(data) {
  return {
    types: [NOTES_LOAD_REQUESTED, NOTES_CALL_SUCCESS, NOTES_LOAD_FAIL],
    promise: (client) => client.post('/admin/setmentormenteedata/add_admin_note', {
      data: data
    })
  };
}

export function getAdminList(data) {
  return {
    types: [NOTES_LOAD_REQUESTED, NOTES_CALL_SUCCESS, NOTES_LOAD_FAIL],
    promise: (client) => client.get('/admin/getadmindata/root_admin_list', {
      params: data
    })
  };
}

export function setNotes(data) {
  return {
    type: NOTES_SET,
    notes: data
  };
}

