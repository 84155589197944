const KEY_ISSUES_REQUESTED = 'inquisit-app/ki/KEY_ISSUES_REQUESTED';
const KEY_ISSUES_FAIL = 'inquisit-app/ki/KEY_ISSUES_FAIL';
const KEY_ISSUES_SUCCESS = 'inquisit-app/ki/KEY_ISSUES_SUCCESS';
const KEY_ISSUE_DETAILS_SUCCESS = 'inquisit-app/ki/KEY_ISSUE_DETAILS_SUCCESS';
const KEY_ISSUES_SNAPSHOT_SUCCESS = 'inquisit-app/ki/KEY_ISSUES_SNAPSHOT_SUCCESS';
const KEY_ISSUES_SNAPSHOT_DETAILS_SUCCESS = 'inquisit-app/ki/KEY_ISSUES_SNAPSHOT_DETAILS_SUCCESS';
const KEY_ISSUE_TYPE_STATUS_RP_SUCCESS = 'inquisit-app/ki/KEY_ISSUE_TYPE_STATUS_RP_SUCCESS';

const KEY_ISSUE_UPDATED_SUCCESS = 'inquisit-app/ki/KEY_ISSUE_UPDATED_SUCCESS';

const initialState = {
  keyIssues: [],
  keyIssueDetails: [],
  responsiblePartiesList: [],
  statusList: [],
  typeList: [],
  keyIssueSnapshotData: [],
  keyIssueSnapshotOrgDetails: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case KEY_ISSUES_REQUESTED:
      return {
        ...state,
      };
    case KEY_ISSUES_SUCCESS:
      return {
        ...state,
        keyIssues: action.result,
      };
    case KEY_ISSUE_DETAILS_SUCCESS:
      return {
        ...state,
        keyIssueDetails: action.result.key_issue_details,
        responsiblePartiesList: action.result.responsible_parties,
        statusList: action.result.statuses,
        typeList: action.result.issue_types,
      };
    case KEY_ISSUES_SNAPSHOT_SUCCESS: 
      return {
        ...state,
        keyIssueSnapshotData: action.result,
      };
    case KEY_ISSUES_SNAPSHOT_DETAILS_SUCCESS:
      return {
        ...state,
        keyIssueSnapshotOrgDetails: action.result,
      };
    case KEY_ISSUES_FAIL:
      return {
        ...state,
        error: action.error
      };
    case KEY_ISSUE_UPDATED_SUCCESS: 
      return {
        ...state,
      };
    case KEY_ISSUE_TYPE_STATUS_RP_SUCCESS: 
      return {
        ...state,
        responsiblePartiesList: action.result.responsible_parties,
        statusList: action.result.statuses,
        typeList: action.result.issue_types
      }
    default:
      return state;
  }
}

export function getKeyIssues(type, typeId, typePrimaryId) {
  return {
    types: [KEY_ISSUES_REQUESTED, KEY_ISSUES_SUCCESS, KEY_ISSUES_FAIL],
    promise: (client) => client.get('/admin/getkeyissuesdata/key_issues', {
      params: {
        type: type,
        type_id: typeId,
        type_primary_id: typePrimaryId
      }
    })
  };
}

export function getKeyIssueDetails(keyIssueId, rootId) {
  return {
    types: [KEY_ISSUES_REQUESTED, KEY_ISSUE_DETAILS_SUCCESS, KEY_ISSUES_FAIL],
    promise: (client) => client.get('/admin/getkeyissuesdata/key_issue_details', {
      params: {
        key_issue_id: keyIssueId,
        root_id: rootId,
      }
    })
  };
}



export function getKeyIssuesSnapshotDetailsPerOrg(orgName, issueTypeId) {
  return {
    types: [KEY_ISSUES_REQUESTED, KEY_ISSUES_SNAPSHOT_DETAILS_SUCCESS, KEY_ISSUES_FAIL],
    promise: (client) => client.get('/admin/getkeyissuesdata/key_issues_snapshot_details_per_org', {
      params: {
        org_name: orgName,
        issue_type_id: issueTypeId
      }
    })
  };
}

export function getKeyIssueTypeStatusRPList(rootId) {
  return {
    types: [KEY_ISSUES_REQUESTED, KEY_ISSUE_TYPE_STATUS_RP_SUCCESS, KEY_ISSUES_FAIL],
    promise: (client) => client.get('/admin/getkeyissuesdata/get_key_issue_type_status_rp_list', {
      params: {
        root_id: rootId
      }
    })
  };
}

// Post
export function addNewKeyIssue(iiType, iiTypeId, issueCat, issueType, issue, receiveAdditionalHelp, emergencyInd, responsibleParty, status, byType, byTypeId) {
  return {
    types: [KEY_ISSUES_REQUESTED, KEY_ISSUE_UPDATED_SUCCESS, KEY_ISSUES_FAIL],
    promise: (client) => client.post('/admin/setkeyissuesdata/add_new_key_issue', {
      data: {
        ii_type: iiType,
        ii_type_id: iiTypeId,
        issue_category: issueCat,
        issue_type: issueType,
        issue: issue,
        receive_additional_help: receiveAdditionalHelp,
        emergency_ind: emergencyInd,
        responsible_party: responsibleParty,
        status: status,
        by_type: byType,
        by_type_id: byTypeId
      }
    })
  };
}

export function addKeyIssueNewNote(issueId, newNote, byType, byTypeId) {
  return {
    types: [KEY_ISSUES_REQUESTED, KEY_ISSUE_UPDATED_SUCCESS, KEY_ISSUES_FAIL],
    promise: (client) => client.post('/admin/setkeyissuesdata/add_key_issue_new_note', {
      data: {
        issue_id: issueId,
        new_note: newNote,
        by_type: byType,
        by_type_id: byTypeId
      }
    })
  };
}
