import superagent from 'superagent';
import Throttle from 'superagent-throttle';
import config from '../config';

let throttle = new Throttle({
  active: true,     // set false to pause queue
  rate: 20,          // how many requests can be sent every `ratePer`
  ratePer: 1000,   // number of ms in which `rate` requests may be sent
  concurrent: 10     // how many requests can be sent concurrently
});
import store from 'store';
const methods = ['get', 'post', 'put', 'patch', 'del'];

export function formatUrl(path) {
  let adjustedPath = path[0] !== '/' ? (path[0] === ':' ? '' + path : '/' + path ): path;
  // Prepend host and port of the API server to the path.
  let apiHost = config.apiHost;
  if(path[0] === ':') {
    if (process.env.REACT_APP_GOLANG_APIHOST && adjustedPath.includes(":9000")) {
      adjustedPath = adjustedPath.replace(':9000', '');
      apiHost = process.env.REACT_APP_GOLANG_APIHOST;
    } else if (process.env.REACT_APP_APIHOST_LARAVEL && adjustedPath.includes(":peerapi")) {
      adjustedPath = adjustedPath.replace(':peerapi', '');
      apiHost = process.env.REACT_APP_APIHOST_LARAVEL;
    } else {
      apiHost = apiHost.replace('/platformapi', '');
    }
  }
  if(process.env.REACT_APP_NODE_ENV === 'sandbox' || process.env.REACT_APP_NODE_ENV === 'staging') {
    return 'https://' + apiHost + adjustedPath;
  }
  return '//' + apiHost + adjustedPath;
}

class _ApiClient {
  constructor() {
    methods.forEach((method) =>
      this[method] = (path, { params, data, port } = {}) => new Promise((resolve, reject) => {
        const stateCache = localStorage.getItem('redux') ? JSON.parse(localStorage.getItem('redux')) : {};
        let authTokenCache = stateCache.auth ? stateCache.auth.token : null;
        if (!authTokenCache && data && data.token) {
          authTokenCache = data.token;
        }
        const currentVersion = global.appVersion;
        const request = superagent[method](formatUrl(path));
        // .set('_token', authTokenCache);
        // .set('Accept', 'application/json')
        // .set('Content-Type', 'application/json');
        const route = window.location.hash.substring(1);
        const route_match = route.match(/^\/(?<org_id>\d+)\/(?:admin|es|navigator|supporter)(?:\/|$)/);
        let addlData = false;
        if (route.indexOf('/es/mentee/') > -1 || route.indexOf('/navigator/mentee/') > -1 || route.indexOf('/supporter/mentee/') > -1 || route.indexOf('/admin/mentees/') > -1) {
          let { menteeProfile } = store.getState().engagedSupport;
          if (menteeProfile?.id) {
            addlData = {};
            addlData['mentee_id'] = menteeProfile.id;
            addlData['mentee_master_id'] = menteeProfile.mentee_master_id;
            addlData['es_program_base_id'] = menteeProfile.epb_id;
            addlData['es_program_node_id'] = menteeProfile.es_program_node_id;
            addlData['es_program_pn_node_id'] = menteeProfile.es_program_pn_node_id;
            addlData['es_program_supporter_node_id'] = menteeProfile.es_program_supporter_node_id;
            addlData['es_program_supporter_base_id'] = menteeProfile.es_program_supporter_base_id;
            addlData['org'] = route_match?.groups?.org_id || menteeProfile.associated_org_id;
            addlData['user_relation'] = menteeProfile.user_relation;
          } else {
            menteeProfile = store.getState().mentee.menteeProfile;
            if (menteeProfile?.id) {
              addlData = {};
              addlData['mentee_id'] = menteeProfile.id;
              addlData['mentee_master_id'] = menteeProfile.mentee_master_id;
              addlData['es_program_base_id'] = menteeProfile.epb_id;
              addlData['es_program_node_id'] = menteeProfile.es_program_node_id;
              addlData['es_program_pn_node_id'] = menteeProfile.es_program_pn_node_id;
              addlData['es_program_supporter_node_id'] = menteeProfile.es_program_supporter_node_id;
              addlData['es_program_supporter_base_id'] = menteeProfile.es_program_supporter_base_id;
              addlData['org'] = route_match?.groups?.org_id || menteeProfile.associated_org_id;
              addlData['user_relation'] = menteeProfile.user_relation;
            }
          }
        }

        const { user, selectedOrg } = store.getState().auth;
        if (user?.id && !(addlData?.org)) {
          if (['get', 'del'].includes(method)) {
            if(!params) params = {};
            if(!params.org) params['org'] = params.org_ids || params.org_id || route_match?.groups?.org_id || user?.associated_orgs_str || user?.all_associated_orgs || selectedOrg;
          } else {
            if(!data) data = {};
            if(!data.org) data['org'] = data.org_ids || data.org_id || (params && (params.org || params.org_ids || params.org_id)) || route_match?.groups?.org_id || user?.associated_orgs_str || user?.all_associated_orgs || selectedOrg;
          }
        }
        if (params) {
          params['route'] = route;
          if (addlData) params['profile_data'] = addlData;
          if (currentVersion) params['current_app_version'] = currentVersion;
          request.query(params);
        }
        // request.query({token: authTokenCache});
        if (port) {
          request.set('port', port)
        }
        
        if (authTokenCache) {
          request.set('Authorization', 'Bearer ' + authTokenCache);
        }

        request.withCredentials = true;
        if (data) {
          data['route'] = route;
          if (user?.id && !(addlData?.org) && !data.org) data['org'] = data.org_ids || data.org_id || (params && (params.org || params.org_ids || params.org_id)) || route_match?.groups?.org_id || user?.associated_orgs_str || user?.all_associated_orgs || selectedOrg;
          if (addlData) data['profile_data'] = addlData;
          if (currentVersion) data['current_app_version'] = currentVersion;
          request.send(data);
        }
        request.use(throttle.plugin());
        request.end((err, res) => {
          // console.log("===============");
          // console.log(res.text);
          if (!err) {
            let parseResponse = false;
            try {
              parseResponse = JSON.parse(res.text);
            } catch(e) {
            }
            resolve(parseResponse);
          } else {
            if (res && res.text) {
              try {
                reject(JSON.parse(res.text));
              } catch (e) {
                reject('error');
              }
            } else {
              reject(err);
            }
          }
          // console.log("===============");
        });
        // request.end((err, { body } = {}) => err ? reject(body || err) : resolve(body));
      }));
  }
}

const ApiClient = _ApiClient;

export default ApiClient;
