import _ from 'lodash';

const LOAD_GENERIC = 'inquisit-app/pn_copilot/LOAD_COPILOT_GENERIC';
const SUCCESS_GENERIC = 'inquisit-app/pn_copilot/SUCCESS_COPILOT_GENERIC';
const FAIL_GENERIC = 'inquisit-app/pn_copilot/FAIL_COPILOT_GENERIC';
const LOAD_PRE_CALL_LIST = 'inquisit-app/pn_copilot/LOAD_PRE_CALL_LIST';
const SUCCESS_PRE_CALL_LIST = 'inquisit-app/pn_copilot/SUCCESS_PRE_CALL_LIST'; 
const FAIL_PRE_CALL_LIST = 'inquisit-app/pn_copilot/FAIL_PRE_CALL_LIST';
const UPDATE_PRE_CALL_LIST = 'inquisit-app/pn_copilot/UPDATE_PRE_CALL_LIST';
const UPDATE_LIST_PROGRESS = 'inquisit-app/pn_copilot/UPDATE_LIST_PROGRESS';
const RESET_PRECALL_LIST = 'inquisit-app/pn_copilot/RESET_PRECALL_LIST';

const LOAD_CONVERSATION_GUIDE_LIST = 'inquisit-app/pn_copilot/LOAD_CONVERSATION_GUIDE_LIST';
const SUCCESS_CONVERSATION_GUIDE_LIST = 'inquisit-app/pn_copilot/SUCCESS_CONVERSATION_GUIDE_LIST'; 
const ADD_CONVERSATION_GUIDE_LIST_ITEM = 'inquisit-app/pn_copilot/ADD_CONVERSATION_GUIDE_LIST_ITEM'; 
const FAIL_CONVERSATION_GUIDE_LIST = 'inquisit-app/pn_copilot/FAIL_CONVERSATION_GUIDE_LIST';
const UPDATE_CONVERSATION_GUIDE_LIST = 'inquisit-app/pn_copilot/UPDATE_CONVERSATION_GUIDE_LIST';
const RESET_CONVERSATION_GUIDE_LIST = "inquisit-app/pn_copilot/RESET_CONVERSATION_GUIDE_LIST"

const LOAD_POST_CALL_LIST = 'inquisit-app/pn_copilot/LOAD_POST_CALL_LIST';
const SUCCESS_POST_CALL_LIST = 'inquisit-app/pn_copilot/SUCCESS_POST_CALL_LIST'; 
const FAIL_POST_CALL_LIST = 'inquisit-app/pn_copilot/FAIL_POST_CALL_LIST';
const UPDATE_POST_CALL_LIST = 'inquisit-app/pn_copilot/UPDATE_POST_CALL_LIST';
const RESET_POST_CALL_LIST = 'inquisit-app/pn_copilot/RESET_POST_CALL_LIST';

const ACKNOWLEDGE_PRE_CALL_CHECKLIST_ITEM = 'inquisit-app/pn_copilot/ACKNOWLEDGE_PRE_CALL_CHECKLIST_ITEM';
const ACKNOWLEDGE_POST_CALL_CHECKLIST_ITEM = 'inquisit-app/pn_copilot/ACKNOWLEDGE_POST_CALL_CHECKLIST_ITEM';
const ACKNOWLEDGE_POST_CALL_ITEM = 'inquisit-app/pn_copilot/ACKNOWLEDGE_POST_CALL_ITEM';
const ACKNOWLEDGE_CONVERSATION_GUIDE_ITEM = 'inquisit-app/copilot/ACKNOWLEDGE_CONVERSATION_GUIDE_ITEM';
const LEVEL_TWO = 'inquisit-app/pn_copilot/LEVEL_TWO';
const LEVEL_THREE = 'inquisit-app/pn_copilot/LEVEL_THREE';

const SUCCESS_ISSUES_SS = 'inquisit-app/issue/SUCCESS_ISSUES_SS';
const SUCCESS_WORKBOOK_PROGRESS = 'inquisit-app/pn_copilot/SUCCESS_WORKBOOK_PROGRESS'; 
const FAIL_WORKBOOK_PROGRESS = 'inquisit-app/pn_copilot/FAIL_WORKBOOK_PROGRESS';
const RESET_COPILOT = 'inquisit-app/pn_copilot/RESET_COPILOT';
const COPILOT_DATA = 'inquisit-app/pn_copilot/COPILOT_DATA';

const GENERIC = [LOAD_GENERIC, SUCCESS_GENERIC, FAIL_GENERIC];

const initialState = {
  preCallListLoading: true,
  preCallList: [],
  conversationGuideListLoading: true,
  conversationGuideList: [],
  postCallChecklistLoading: false,
  postCallChecklist: [],
  levelTwoData: false,
  levelThreeData: false,
  talkingPointProgressBarHidden: false,
  workbook: {
    progress: 0,
    checklist: {},
    masterChecklist: {}
  },
  callingDisabled: true,
  ss_id: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PRE_CALL_LIST:
      return {
        ...state,
        preCallListLoading: true
      };
    case SUCCESS_PRE_CALL_LIST:
      return {
        ...state,
        preCallListLoading: false,
        preCallList: action.result.success ? action.result.list : [],
        callingDisabled: action.result.success ? action.result.all_acknowledged : false
      };
    case FAIL_PRE_CALL_LIST:
      return {
        ...state,
        preCallListLoading: false,
        preCallList: []
      };
    case UPDATE_PRE_CALL_LIST: {
      return {
        ...state,
        preCallListLoading: false,
        preCallList: _.sortBy(_.unionBy([action.result.data], state.preCallList, 'slug'), 'sequence')
      };
    }
    case UPDATE_LIST_PROGRESS:
      return {
        ...state,
        listProgress: action.result
    };  
      case SUCCESS_ISSUES_SS:
      return {
        ...state,
        ss_id: action.result
      };
    case LOAD_CONVERSATION_GUIDE_LIST:
      return {
        ...state,
        conversationGuideListLoading: true
      };
    case SUCCESS_CONVERSATION_GUIDE_LIST:
      return {
        ...state,
        conversationGuideListLoading: false,
        conversationGuideList: action.result.success ? action.result.list : []
      };
    case RESET_CONVERSATION_GUIDE_LIST:
      return {
        ...state,
        conversationGuideListLoading: false,
        conversationGuideList: []
      };   

      case RESET_PRECALL_LIST:
      return {
        ...state,
        preCallListLoading: false,
        preCallList: []
      }; 

      case RESET_POST_CALL_LIST:
      return {
        ...state,
        postCallChecklistLoading: false,
        postCallChecklist: []
      }; 

    case ADD_CONVERSATION_GUIDE_LIST_ITEM:
      return {
        ...state,
        conversationGuideListLoading: false,
        conversationGuideList: action.result.success ? [...state.conversationGuideList, action.result.item] : state.conversationGuideList
      };

    case UPDATE_CONVERSATION_GUIDE_LIST: {
      return {
        ...state,
        conversationGuideListLoading: false,
        conversationGuideList: _.sortBy(_.unionBy([...state.conversationGuideList, action.result.data], 'id'), 'sequence')
      };
    }
    case UPDATE_POST_CALL_LIST: {
      return {
        ...state,
        postCallChecklistLoading: false,
        postCallChecklist: _.sortBy(_.unionBy([action.result.data], state.postCallChecklist, 'slug'), 'sequence')
      };
    }  
    case FAIL_CONVERSATION_GUIDE_LIST:
      return {
        ...state,
        conversationGuideListLoading: false,
        conversationGuideList: []
      };
    case LOAD_POST_CALL_LIST:
      return {
        ...state,
        postCallChecklistLoading: true
      };
    case SUCCESS_POST_CALL_LIST:
      return {
        ...state,
        postCallChecklistLoading: false,
        postCallChecklist: action.result.success ? action.result.list : []
      };
    case FAIL_POST_CALL_LIST:
      return {
        ...state,
        postCallChecklistLoading: false,
        postCallChecklist: []
      };
    case SUCCESS_WORKBOOK_PROGRESS:
      return {
        ...state,
        workbook: {
          progress: action.result && action.result.progress ? action.result.progress : 0,
          checklist: action.result && action.result.call_progress_checklist ? action.result.call_progress_checklist : {},
          masterChecklist: action.result && action.result.master_checklist ? action.result.master_checklist : {}
        }
      };
    case FAIL_WORKBOOK_PROGRESS:
      return {
        ...state,
        workbook: {
          progress: 0,
          checklist: {},
          masterChecklist: {}
        }
      };
    case ACKNOWLEDGE_PRE_CALL_CHECKLIST_ITEM: {
      let { preCallList, levelTwoData } = state;
      const index = _.findIndex(preCallList, (item) => { return parseInt(item.id, 10) === parseInt(action.result.id, 10) });
      if (index !== -1 && preCallList[index].checklist[action.result.checklistIndex]) {
        preCallList[index].checklist[action.result.checklistIndex]['is_checked'] = preCallList[index].checklist[action.result.checklistIndex]['is_checked'] ? 0 : 1;
        if (preCallList[index].checklist.length === 1 && action.result.isChecked === 1) {
          preCallList[index].is_acknowledged = 1;
          preCallList[index].is_checked = 1;
        } else {
          const allChecked = preCallList[index].checklist.filter((item) => item.is_checked);
          if (allChecked.length === preCallList[index].checklist.length) {
            preCallList[index].is_acknowledged = 1;
            preCallList[index].is_checked = 1;
          } else {
            preCallList[index].is_acknowledged = 0;
            preCallList[index].is_checked = 0;
          }
        }
      }
      if (levelTwoData.id === action.result.id && levelTwoData.checklist[action.result.checklistIndex]) levelTwoData.checklist[action.result.checklistIndex]['is_checked'] = levelTwoData.checklist[action.result.checklistIndex]['is_checked'] ? 0 : 1;
      return {
        ...state,
        preCallList,
        levelTwoData
      };
    }
    case ACKNOWLEDGE_POST_CALL_ITEM: {
      let { postCallChecklist } = state;
      const index = _.findIndex(postCallChecklist, (item) => { return parseInt(item.id, 10) === parseInt(action.result.id, 10) });
      if (index !== -1 && postCallChecklist[index]) {
        postCallChecklist[index].acknowledged_ind = 1;
      }
      return {
        ...state,
        postCallChecklist
      };
    }

    case ACKNOWLEDGE_CONVERSATION_GUIDE_ITEM: {
      let { conversationGuideList } = state;
      const index = _.findIndex(conversationGuideList, (item) => { return parseInt(item.id, 10) === parseInt(action.result.id, 10) });
      if (index !== -1 && conversationGuideList[index]) {
        conversationGuideList[index]['acknowledged_ind'] = 1;
      }
      return {
        ...state,
        conversationGuideList
      };
    }

    case ACKNOWLEDGE_POST_CALL_CHECKLIST_ITEM: {
      let levelTwoData2 = state.levelTwoData;
      let { postCallChecklist } = state;
      const ind = _.findIndex(postCallChecklist, (item) => { return parseInt(item.id, 10) === parseInt(action.result.id, 10) });
      if (ind !== -1) {
        postCallChecklist[ind].checklist[action.result.checklistIndex]['is_checked'] = postCallChecklist[ind].checklist[action.result.checklistIndex]['is_checked'] ? 0 : 1;
      }
      if (levelTwoData2.id === action.result.id) levelTwoData2.checklist[action.result.checklistIndex]['is_checked'] = levelTwoData2.checklist[action.result.checklistIndex]['is_checked'] ? 0 : 1;
      return {
        ...state,
        postCallChecklist,
        levelTwoData2
      };
    }
    case LEVEL_TWO:
      return {
        ...state,
        levelTwoData: action.result.data
      };
    case LEVEL_THREE:
      return {
        ...state,
        levelThreeData: action.result.data,
        talkingPointProgressBarHidden: action.result.data===false ? false : true
      };
    case COPILOT_DATA:
      return {
        ...state,
        ...action.result.data,
      };
    case RESET_COPILOT:
      return initialState;
    default:
      return state;
  }
}

// Get
export function getPNCopilotPreCallPlanning(data) {
  return {
    types: [!data.loadingInd ? LOAD_PRE_CALL_LIST : LOAD_GENERIC, SUCCESS_PRE_CALL_LIST, FAIL_PRE_CALL_LIST],
    promise: (client) => client.get('/copilot/get_pn_pre_call_planning_list', {
      params: data
    })
  };
}



export function getPNCopilotConversationGuide(data) {
  return {
    types: [!data.loadingInd ? LOAD_CONVERSATION_GUIDE_LIST : LOAD_GENERIC, SUCCESS_CONVERSATION_GUIDE_LIST, FAIL_CONVERSATION_GUIDE_LIST],
    promise: (client) => client.get('/copilot/get_pn_call_conversation_guide', {
      params: data
    })
  };
}



export function getPNCopilotPostCallChecklist(data) {
  return {
    types: [!data.loadingInd ? LOAD_POST_CALL_LIST : LOAD_GENERIC, SUCCESS_POST_CALL_LIST, FAIL_POST_CALL_LIST],
    promise: (client) => client.get('/copilot/get_pn_post_call_checklist', {
      params: data
    })
  };
}


export function getCopilotConversationGuideMaterialTopicData(menteeId, language) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_material_topic_data', {
      params: {
        mentee_id: menteeId,
        language
      }
    })
  };
}

export function storeSSValue(data) {
  return{
    type: SUCCESS_ISSUES_SS,
    result: data
  }
}

export function getIssuesRequiringUpdatesList(menteeId, mode) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_issues_requiring_updates_list', {
      params: {
        mentee_id: menteeId,
        mode
      }
    })
  };
}

export function getIssueGuiderCoveredIssuesList(menteeId) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_issue_guiders_covered_issues_list', {
      params: {
        mentee_id: menteeId
      }
    })
  };
}

export function getTracksRequiringUpdatesList(menteeId, callNum) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_tracks_requiring_updates_list', {
      params: {
        mentee_id: menteeId,
        call_num: callNum
      }
    })
  };
}

export function getPNCallsAttemptsByType(esProgramPnNodeId, type, manualReviewQueueId = null, org = null) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_pn_call_attempts_by_type', {
      params: {
        manual_review_queue_id: manualReviewQueueId,
        es_program_pn_node_id: esProgramPnNodeId,
        type: type,
        org: org,
      }
    })
  };
}

export function getWorkbookCompensation(esProgramPNNodeId) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_pn_workbook_compensation', {
      params: {
        es_program_pn_node_id: esProgramPNNodeId
      }
    })
  };
}

export function getUnsentStepletsWithContent(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_unsent_steplets_with_content', {
      params: data
    })
  };
}

export function getIssuesCoveredInCurrentWorkbook(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_pn_issues_covered_in_current_workbook', {
      params: data
    })
  };
}

export function getActiveIssuesWithCCPUpdates(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/copilot/get_active_issues_with_ccp_updates', {
      params: data
    })
  };
}

export function getPrecallIssueAreas(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/pncopilot/get_precall_issueAreas', {
      params: data
    })
  };
}

// Set
export function restCopilotConversationGuide() {
  return{
    type: RESET_CONVERSATION_GUIDE_LIST,
  }
}

export function restCopilotPreCallList() {
  return{
    type: RESET_PRECALL_LIST,
  }
}

export function restCopilotPostCallList() {
  return{
    type: RESET_POST_CALL_LIST,
  }
}

export function updatePreCallList(data) {
  return {
    type: UPDATE_PRE_CALL_LIST,
    result: {
      data
    }
  };
}

export function updateConversationGuideList(data) {
  return {
    type: UPDATE_CONVERSATION_GUIDE_LIST,
    result: {
      data
    }
  };
}

export function updatePostCallCheckList(data) {
  return {
    type: UPDATE_POST_CALL_LIST,
    result: {
      data
    }
  };
}

export function updateListProgress(data) {
  return{
    type: UPDATE_LIST_PROGRESS,
    result: data
  }
}

export function setPreCallTopicAcknowledged(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/pncopilot/set_pre_call_topic_acknowledged', {
      data: data
    })
  };
}



export function setPostCallTopicAcknowledged(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/set_pn_post_call_topic_acknowledged', {
      data: data
    })
  };
}

export function updatePostCallWorkbookRequirement(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/update_workbook_requirement', {
      data: data
    })
  };
}

export function pinToPNConversationGuide(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/pin_to_pn_conversation_guide', {
      data: data
    })
  };
}


export function unpinFromConversationGuide(data){
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/unpin_from_conversation_guide', {
      data: data
    })
  };
}

export function deleteFromConversationGuide(data){
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/delete_from_conversation_guide_pinned', {
      data: data
    })
  };
}


export function updateConversationGuideTopicSequence(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/update_conversation_guide_topic_sequence', {
      data: data
    })
  };
}

export function deletePNConversationGuideTopic(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/pncopilot/delete_conversation_guide_topic', {
      data: data
    })
  };
}


export function addManualCompensation(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/add_pn_manual_compensation', {
      data: data
    })
  };
}

export function removeManualCompensation(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/remove_pn_manual_compensation', {
      data: data
    })
  };
}

export function updateManualCompensation(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/update_pn_manual_compensation', {
      data: data
    })
  };
}

export function dismissPeerBotCheckinQuestion(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/dismiss_peerbot_checkin_topic_question', {
      data: data
    })
  };
}

export function processMenteeStatusSurvey(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/process_mentee_status_survey', {
      data: data
    })
  };
}

export function addCompensation(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/add_compensation', {
      data: data
    })
  };
}

export function closePNWorkbook(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/close_pn_workbook', {
      data: data
    })
  };
}

export function evaluateWorkbookProgress(data) {
  return {
    types: [GENERIC, SUCCESS_WORKBOOK_PROGRESS, FAIL_WORKBOOK_PROGRESS],
    promise: client => client.post('/copilot/evaluate_workbook_progress', {
      data: data
    })
  };
}

export function addCCPFeedbackSurveyToCC(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/add_ccp_feedback_survey_to_cc', {
      data: data
    })
  };
}

export function removeCCPFeedbackSurveyFromCC(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/remove_ccp_feedback_survey_cc_task', {
      data: data
    })
  };
}

export function updatePostCallTopicMetadata(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/copilot/set_post_call_metadata', {
      data: data
    })
  };
}

export function acknowledgePreCallChecklistItem(id, checklistIndex) {
  return {
    type: ACKNOWLEDGE_PRE_CALL_CHECKLIST_ITEM,
    result: {
      id,
      checklistIndex
    }
  };
}

export function acknowledgePostCallChecklistItem(id, checklistIndex) {
  return {
    type: ACKNOWLEDGE_POST_CALL_CHECKLIST_ITEM,
    result: {
      id,
      checklistIndex
    }
  };
}

export function acknowledgePostCallItem(id) {
  return {
    type: ACKNOWLEDGE_POST_CALL_ITEM,
    result: {
      id,
    }
  };
}

export function acknowledgeConversationGuideItem(id) {
  return {
    type: ACKNOWLEDGE_CONVERSATION_GUIDE_ITEM,
    result: {
      id,
    }
  };
}

export function setLevelData(level, data) {
  return {
    type: level === 2 ? LEVEL_TWO : LEVEL_THREE,
    result: {
      data
    }
  };
}

export function setCopilotData(data) {
  return {
    type: COPILOT_DATA,
    result: {
      data
    }
  };
}

export function setConversationGuideList(data) {
  return {
    type: SUCCESS_CONVERSATION_GUIDE_LIST,
    result: {
      success: true,
      list: data
    }
  };
}

export function addItemConversationGuideList(data) {
  return {
    type: ADD_CONVERSATION_GUIDE_LIST_ITEM,
    result: {
      success: true,
      item: data
    }
  };
}

export function resetCopilot() {
  return {
    type: RESET_COPILOT,
    result: {
    }
  };
}
