// @flow
const GENERIC_REQUEST = 'inquisit-app/mentorSOP/GENERIC';

const GENERIC = [GENERIC_REQUEST, GENERIC_REQUEST, GENERIC_REQUEST];

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
 
  switch (action.type) {
    default:
      return state;
  }
}

/*
 * GET
 */

export function getMentorSOPList(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('mentor/mentorprofile/get_mentor_sop_list', {
      params: data
    })
  };
}

export function getMentorSOPMenteeList(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('mentor/mentorprofile/get_mentor_sop_mentee_list', {
      params: data
    })
  };
}

export function getMentorMenteeSOPTimeline(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('mentor/mentorprofile/get_mentor_mentee_sop_timeline', {
      params: data
    })
  };
}


/*
 * POST
 */


export function resumeMentorSOP(data) {
  return {
    types: GENERIC,
    promise: client => client.post('mentor/mentorprofile/resume_sop',{
      data: data
    })
  };
}

export function suspendMentorSOP(data) {
  return {
    types: GENERIC,
    promise: client => client.post('mentor/mentorprofile/suspend_sop',{
      data: data
    })
  };
}

export function resolveMentorSOP(data) {
  return {
    types: GENERIC,
    promise: client => client.post('mentor/mentorprofile/resolve_sop',{
      data: data
    })
  };
}

