const CMS_PC_REQUEST_GENERIC = 'inquisit-app/CMS/CMS_PC_REQUEST_GENERIC';
const CMS_PC_SUCCESS_GENERIC = 'inquisit-app/CMS/CMS_PC_SUCCESS_GENERIC';
const CMS_PC_FAIL_GENERIC = 'inquisit-app/CMS/CMS_PC_FAIL_GENERIC';
const GENERIC_TYPES = [CMS_PC_REQUEST_GENERIC, CMS_PC_SUCCESS_GENERIC, CMS_PC_FAIL_GENERIC];
const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getPaperCollateralBinders(org_id) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/get_pc_binders', {
      params: {
        org_id: org_id
      }
    })
  };
}

export function getPaperCollateral(pcId) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/get_paper_collateral', {
      params: {
        pc_id: pcId
      }
    })
  };
}

export function getAllPaperCollaterals() {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/get_all_paper_collaterals')
  };
}

export function createPaperCollateral(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/create_paper_collateral', {
      data: {
        pc: data.pc,
        file: data.file
      }
    })
  };
}

export function updatePaperCollateral(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/update_binderless_paper_collateral', {
      data: {
        pc: data.pc,
        file: data.file
      }
    })
  };
}
