import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import config from 'config';
let fbConfig = {
    apiKey: config.FIREBASE.KEY,
	authDomain: config.FIREBASE.DOMAIN,
	databaseURL: config.FIREBASE.URL,
	storageBucket: config.FIREBASE.BUCKET
};

export default !firebase.apps.length ? firebase.initializeApp(fbConfig) : firebase.app();