// @flow
const GENERIC_LOAD = 'GET_ES_GENERIC_OVERVIEW_REQUEST';
const GENERIC_SUCCESS = 'GET_ES_GENERIC_OVERVIEW_SUCCESS';
const GENERIC_FAIL = 'GET_ES_GENERIC_OVERVIEW_FAIL';
const GENERIC = [GENERIC_LOAD, GENERIC_SUCCESS,GENERIC_FAIL];
const initialState = {

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getMenteeResponseRate(data){
  return {
    types: GENERIC,
    promise: client =>
      client.get('es/get_es_mentee_response_rate',  {
        params: data
      }),
  };
}
