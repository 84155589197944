const LOAD_REQUESTED = 'inquisit-app/issueArea/LOAD_REQUESTED';
const GENERIC_SUCCESS = 'inquisit-app/issueArea/GENERIC_SUCCESS';
const REQUEST_FAIL = 'inquisit-app/issueArea/REQUEST_FAIL';
const LOAD_ISSUES_AREA = 'inquisit-app/issueArea/LOAD_ISSUES_AREA';
const SUCCESS_ISSUES_AREA = 'inquisit-app/issueArea/SUCCESS_ISSUES_AREA';
const FAIL_ISSUES_AREA = 'inquisit-app/issueArea/FAIL_ISSUES_AREA';
const SUCCESS_SORT_ISSUES_AREA = 'inquisit-app/issueArea/SUCCESS_SORT_ISSUES_AREA';
const SUCCESS_ISSUES_AREA_NOTES_INDICATOR = 'inquisit-app/issueArea/SUCCESS_ISSUES_AREA_NOTES_INDICATOR';
const SUCCESS_ISSUES_AREA_VERSION = 'inquisit-app/issueArea/SUCCESS_ISSUES_AREA_VERSION';
const SUCCESS_SORT_ISSUES_AREA_PIN = 'inquisit-app/issueArea/SUCCESS_SORT_ISSUES_AREA_PIN'

const initialState = {
  loading: true,
  areas: {},
  areasNoteIndicatator: {},
  areaVersion: ''
};

const GENERIC = [LOAD_REQUESTED, GENERIC_SUCCESS, REQUEST_FAIL];

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ISSUES_AREA:
      return {
        ...state,
        loading: true,
        areas: {},
        isPinned: 0
      };

    case SUCCESS_ISSUES_AREA:
      if (action.result && action.result.areas) {
        Object.entries(action.result.areas).map((area) => {
          state.areasNoteIndicatator[area[1].title.es_issue_area_id] = area[1].title.note_acknowledged_ind;
        })
      }
      return {
        ...state,
        loading: false,
        areas: action.result ? action.result.areas : {},
      };
    case FAIL_ISSUES_AREA:
      return {
        ...state,
        loading: false,
        areas: {}
      };
    case SUCCESS_SORT_ISSUES_AREA:

      return {
        ...state,
        areas: action.result.areas
      };
    case SUCCESS_ISSUES_AREA_VERSION:
      action.result.version ? state.areaVersion= action.result.version : state.areaVersion = '';
      return {
        ...state
      };  
    case SUCCESS_ISSUES_AREA_NOTES_INDICATOR:
      action.result.es_issue_area_id === 'void' ? state.areasNoteIndicatator = {} : state.areasNoteIndicatator[action.result.es_issue_area_id] = 1
      return {
        ...state
      }
    case SUCCESS_SORT_ISSUES_AREA_PIN:
      return {
        ...state,
        isPinned: action.result.pinState
      }


    default:
      return state;
  }
}

export function getMenteeIssueAreas(menteeId, menteeMasterId, esProgramBaseId, slug, tab) {

  return {
    types: [LOAD_ISSUES_AREA, SUCCESS_ISSUES_AREA, FAIL_ISSUES_AREA],
    promise: (client) => client.get('/issue/get_mentee_issue_areas', {
      params: {
        mentee_id: menteeId,
        mentee_master_id: menteeMasterId,
        es_program_base_id: esProgramBaseId,
        slug,
        tab,
      }
    })
  };
}

export function addIssuesArea(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/issues/add_issues_area', {
      data: data
    })
  };
}

export function toggleDesignateImportantIssueArea(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/toggle_designate_important_issue_area', {
        data: data,
      })
  }
}

export function updateAreaAcknowledgeNote(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/update_area_acknowledge_note', {
        data: data,
      }),
  };
}

export function changeIssueAreaNoteIndicator(es_issue_area_id) {
  return {
    type: SUCCESS_ISSUES_AREA_NOTES_INDICATOR,
    result: {
      es_issue_area_id: es_issue_area_id
    }
  };
}
export function updateIssueAreasSequence(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/update_issues_area_sequence', {
        data: data,
      })
  }
}

export function setSortedActiveIssuesAreas(newAreas) {

  return {
    type: SUCCESS_SORT_ISSUES_AREA,
    result: {
      areas: newAreas
    }
  };
}

export function setPinState(newPinState) {
  return {
    type: SUCCESS_SORT_ISSUES_AREA_PIN,
    result: {
      pinState: newPinState
    }
  };
}


export function updateIssueAreas(data) {
  return {
    type: SUCCESS_ISSUES_AREA,
    result: {
      areas: data
    }
  };
}

export function getMenteeIssueAreasToAddList(menteeId, view) {
  return {
    types: GENERIC,
    promise: (client) => client.get('issue/get_mentee_issue_areas_to_add_list', {
      params: {
        mentee_id: menteeId,
        view: view
      }
    })
  };
}
export function getAreaIssuesToAdd(menteeId, issIssueAreaId) {
  return {
    types: GENERIC,
    promise: (client) => client.get('issue/get_area_issues_to_add', {
      params: {
        mentee_id: menteeId,
        iss_issue_area_id: issIssueAreaId
      }
    })
  };
}

export function getMenteeIssueAreaDetails(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('issue/get_mentee_issue_area_details', {
      params: data
    })
  };
}


export function getMenteeIssueAreaMapping(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/issue/get_mentee_issue_area_mapping', {
      params: data
    })
  };
}

export function getIssueAreaUnexploredIssueQuestions(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/issue/get_area_unexplored_issue_questions', {
      params: data
    })
  };
}

export function getIssueAreasProgress(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/issues/get_issue_areas_progress', {
      params: data
    })
  };
}


export function getIndividualIssueArea(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/issues/get_individual_issue_area', {
      params: data
    })
  };
}



export function saveCoveredAreaTalkingPoint(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/save_covered_area_talking_point', {
        data: data
      }),
  };
}

export function updateIssueAreaNoteAcknowledgement(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/update_note_acknowledgement', {
        data: data
      }),
  };
}

export function upsertPMIssueAreaGuiderProgress(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/upsert_pm_issue_area_guider_progress', {
        data: data
      }),
  };
}

export function updateAreaDraftVersion(version) {

  return {
    type: SUCCESS_ISSUES_AREA_VERSION,
    result: {
      version: version
    }
  };
}