const GENERIC_REQUEST = 'inquisit-app/ADMINISTRATIVE_TASK/GENERIC_REQUEST';
const GENERIC_SUCCESS = 'inquisit-app/ADMINISTRATIVE_TASK/GENERIC_SUCCESS';
const GENERIC_FAIL = 'inquisit-app/ADMINISTRATIVE_TASK/GENERIC_FAIL';

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getAdministrativeTaskList(rootId, orgId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/admin/task/list', {
      params: {
        root_id: rootId,
        org_id: orgId
      }
    })
  };
}

export function getAdministrativeTaskTypes(rootId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/admin/task/types', {
      params: {
        root_id: rootId
      }
    })
  };
}

export function getCompensationPendingApprovalMentorData(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/admin/compensation/mentor/data', {
      params: data
    })
  };
}


export function updateTaskStatus(type_id, type_group, task_id, close_reason) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/admin/task/update', {
      data: {
        type_id,
        type_group,
        task_id,
        close_reason
      }
    })
  };
}

export function updateCompensationStatus(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/admin/compensation/update', {
      data: data
    })
  };
}
