const PAYMENT_GENERIC_REQUEST = 'inquisit-app/payment/PAYMENT_GENERIC_REQUEST';
const PAYMENT_GENERIC_SUCCESS = 'inquisit-app/payment/PAYMENT_GENERIC_SUCCESS';
const PAYMENT_GENERIC_FAIL = 'inquisit-app/payment/PAYMENT_GENERIC_FAIL';

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

/*
*******
*******
 Get
*******
*******
*/
export function getESMentorPayment(data) {
  return {
    types: [PAYMENT_GENERIC_REQUEST, PAYMENT_GENERIC_SUCCESS, PAYMENT_GENERIC_FAIL],
    promise: client =>
      client.get('/es/get_es_payment_data', {
        params: data,
      }),
  };
}

export function getCompPaymentTypes(data) {
  return {
    types: [PAYMENT_GENERIC_REQUEST, PAYMENT_GENERIC_SUCCESS, PAYMENT_GENERIC_FAIL],
    promise: client =>
      client.get('/es/get_compensation_payment_types', {
        params: data,
      }),
  };
}

/*
*******
*******
 Post
*******
*******
*/

export function addManualCompensationRequest(data) {
  return {
    types: [PAYMENT_GENERIC_REQUEST, PAYMENT_GENERIC_SUCCESS, PAYMENT_GENERIC_FAIL],
    promise: client =>
      client.post('/es/add_manual_compensation_request', {
        data: data,
      }),
  };
}

export function updateCompensationRequest(data) {
  return {
    types: [PAYMENT_GENERIC_REQUEST, PAYMENT_GENERIC_SUCCESS, PAYMENT_GENERIC_FAIL],
    promise: client =>
      client.post('/es/update_compensation_request', {
        data: data,
      }),
  };
}

export function deleteCompensationRequest(data) {
  return {
    types: [PAYMENT_GENERIC_REQUEST, PAYMENT_GENERIC_SUCCESS, PAYMENT_GENERIC_FAIL],
    promise: client =>
      client.post('/es/delete_compensation_request', {
        data: data,
      }),
  };
}

