import React from 'react'
// export default class DynamicImport extends React.Component {
//   state = {
//     component: null
//   }
//   componentDidMount() {
//     this.props.load()
//       .then((component) => {
//         this.setState({
//           component: component.default 
//             ? component.default 
//             : component
//         })
//       })
//   }  render() {
//     return this.props.children(this.state.component)
//   }
// }

export default class DynamicImport extends React.Component {
  state = {
    component: null
  }
  _isMounted = false
  componentDidMount() {
    this._isMounted = true;
    this.loadComponent(4);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadComponent = (attemptsLeft) => {
    return new Promise((resolve, reject) => {
      this.props.load()
      .then(this.resolveFn).catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          this.loadComponent(attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
    });
  }

  resolveFn = (component) => {
    if (this._isMounted) {
      this.setState({
        component: component.default 
          ? component.default 
          : component
      });
    }
  }

  render() {
    return this.props.children(this.state.component )
  }
}