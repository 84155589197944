import CONFIG from 'config';
import { UserManager, WebStorageStateStore } from "oidc-client-ts";

const INITIALIZE = 'inquisit-app/userManager/INITIALIZE';
const SETAUTH = 'inquisit-app/userManager/SETAUTH';
const SETUSERINFO = 'inquisit-app/userManager/SETUSERINFO';


const initialState = {
    authenticated: false, 
    userInfo: null, 
    userManager: new UserManager({
        userStore: new WebStorageStateStore({ prefix:"zitadel_", store: window.localStorage }),
        ...CONFIG.AUTHCONFIG,
    }),
};

export default function reducer(state = initialState, action = {}) {
    switch(action.type) {
        case INITIALIZE:
            return {
                ...state,
                ...action.result,
            }
        case SETAUTH:
            return {
                ...state,
                authenticated: action.result
            }
        case SETUSERINFO:
            return {
                ...state,
                userInfo: action.result
            }
        default:
            return state;
    }

}

export function initializeUserManagerStore(data){
    return {
        type: INITIALIZE,
        result: data
    }
}

export function setAuthenticated(data){
    return {
      type: SETAUTH,
      result: data
    }
}

export function setUserInfo(data){
    return {
      type: SETUSERINFO,
      result: data
    }
}