const GENERIC_LOAD_REQUESTED = 'inquisit-app/analytics/GENERIC_LOAD_REQUESTED';
const GENERIC_LOAD_FAIL = 'inquisit-app/analytics/GENERIC_LOAD_FAIL';
const GENERIC_SUCCESS = 'inquisit-app/analytics/GENERIC_SUCCESS';

const CONVERSION_FUNNEL_SUCCESS = 'inquisit-app/analytics/CONVERSION_FUNNEL_SUCCESS';
const BATCH_SUCCESS = 'inquisit-app/analytics/BATCH_SUCCESS';

const initialState = {
  conversionFunnel: [[{total:0}],[{total:0}],[{total:0}],[{total:0}]], // {'1_0_num_prospects': 1, '2_0_num_ready_for_phone_follow_up': 1, '3_0_num_spoken_to': 1}
  orgBatches: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CONVERSION_FUNNEL_SUCCESS:
      return {
        ...state,
        conversionFunnel: action.result
      };
    case BATCH_SUCCESS:
      return {
        ...state,
        orgBatches: action.result
      };
    default:
      return state;
  }
}

export function getOrgBatchDetails(orgId) {
  return {
    types: [GENERIC_LOAD_REQUESTED, BATCH_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/analytics/get_org_batch_details', {
      params: {
        org_id: orgId
      }
    })
  };
}



export function getCohortAppRetentionRate(obj) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_cohort_app_retention_rate', {
      params: {
        start_date: obj.startDate,
        end_date: obj.endDate,
        org_id: obj.orgId,
        root_id: obj.rootId
      }
    })
  };
}

export function getIssuesAndAlerts(obj) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_issues_and_alerts', {
      params: {
        date: obj.date,
        org_id: obj.orgId,
        root_id: obj.rootId
      }
    })
  };
}

export function getChallengesStats(obj) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_challenges_stats', {
      params: {
        start_date: obj.startDate,
        end_date: obj.endDate,
        org_id: obj.orgId,
        root_id: obj.rootId
      }
    })
  };
}

export function getPostsCommunityStats(obj) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_posts_community_stats', {
      params: {
        start_date: obj.startDate,
        end_date: obj.endDate,
        org_id: obj.orgId,
        root_id: obj.rootId
      }
    })
  };
}

export function getMinimodulesStats(obj) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_minimodules_stats', {
      params: {
        start_date: obj.startDate,
        end_date: obj.endDate,
        org_id: obj.orgId,
        root_id: obj.rootId
      }
    })
  };
}

export function getMessagesStats(obj) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_messages_stats', {
      params: {
        start_date: obj.startDate,
        end_date: obj.endDate,
        org_id: obj.orgId,
        root_id: obj.rootId
      }
    })
  };
}

export function getWeeklyActivationStats(obj) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_weekly_activation_stats', {
      params: {
        start_date: obj.startDate,
        end_date: obj.endDate,
        org_id: obj.orgId,
        root_id: obj.rootId
      }
    })
  };
}

export function getEsMentorAnalytics(mentorId, interval, startDate = null, endDate = null) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_es_mentor_analytics', {
      params: {
        mentor_id: mentorId,
        interval: interval,
        start_date: startDate,
        end_date: endDate,
      }
    })
  };
}

export function getJITAnalytics(orgId, rootId, interval, startDate = null, endDate = null) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_jit_analytics', {
      params: {
        org_id: orgId,
        root_id: rootId,
        interval: interval,
        start_date: startDate,
        end_date: endDate,
      }
    })
  };
}

export function getJITAnalyticsIssueDetails(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_jit_analytics_issue_details', {
      params: {
        issue_ids: data.issueIds,
        mentee_ids: data.menteeIds,
      }
    })
  };
}

export function getStepletsAnalytics(orgId, rootId, interval, startDate = null, endDate = null) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/analytics/get_steplets_aggregate_stats', {
      params: {
        org_id: orgId,
        root_id: rootId,
        interval: interval,
        start_date: startDate,
        end_date: endDate,
      }
    })
  };
}

export function getESPerformanceMetrics(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_es_performance_metrics', {
      params: data
    })
  };
}

export function getPNPerformanceMetrics(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_pn_performance_metrics', {
      params: data
    })
  };
}

export function getAppUsageAnalytics(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_app_usage_stats', {
      params: {
        start_date: data.startDate,
        end_date: data.endDate,
        org_id: data.orgId,
        root_id: data.rootId,
        interval: data.interval
      }
    })
  };
}

export function getMenteeCallStats(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/analytics/get_mentee_call_stats', {
      params: data
    })
  };
}
