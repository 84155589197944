import React, { Suspense } from 'react';
import { Route, HashRouter } from 'react-router-dom';
import DynamicImport from 'components/Common/DynamicImport/DynamicImport';

import Loader from 'containers/Loader/Loader';

const App = (props) => (
  <DynamicImport load={() => import('containers/App/App')}>
    {(Component) => Component === null
      ? <Loader />
      : <Component {...props} />}
  </DynamicImport>
);

export default () => {

  return (<>
    <HashRouter>
      <div>
          <Suspense fallback={''}>
          <Route path="/" render={(props) => (<App {...props} />)} />
          </Suspense>
      </div>
    </HashRouter>
    </>
  );
};
