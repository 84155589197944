const GENERIC_LOAD_REQUESTED = 'inquisit-app/dashboard/GENERIC_LOAD_REQUESTED';
const GENERIC_LOAD_SUCCESS = 'inquisit-app/dashboard/GENERIC_LOAD_REQUESTED';
const GENERIC_LOAD_FAIL = 'inquisit-app/dashboard/GENERIC_LOAD_FAIL';

const MENTOR_LOAD_REQUESTED = 'inquisit-app/dashboard/MENTOR_LOAD_REQUESTED';
const MENTOR_SOP_LOAD_SUCCESS = 'inquisit-app/dashboard/MENTOR_SOP_LOAD_SUCCESS';
const MENTOR_SOP_CALL_LIST_LOAD_SUCCESS = 'inquisit-app/dashboard/MENTOR_SOP_CALL_LIST_LOAD_SUCCESS';
const MENTEE_SOP_LOAD_SUCCESS = 'inquisit-app/dashboard/MENTEE_SOP_LOAD_SUCCESS';
const MENTEE_SOP_CALL_LIST_LOAD_SUCCESS = 'inquisit-app/dashboard/MENTEE_SOP_CALL_LIST_LOAD_SUCCESS';
const SOP_LIST_SUCCESS = 'inquisit-app/dashboard/SOP_LIST_SUCCESS';
const MENTOR_LOAD_FAIL = 'inquisit-app/dashboard/MENTOR_LOAD_FAIL';

const MENTOR_ACTIVITY_SUMMARY_LOAD_SUCCESS = 'inquisit-app/dashboard/MENTOR_ACTIVITY_SUMMARY_LOAD_SUCCESS';
const MENTOR_CALL_TX_LOAD_SUCCESS = 'inquisit-app/dashboard/MENTOR_CALL_TX_LOAD_SUCCESS';

const GROUP_CHAT_STATS_LOAD_SUCCESS = 'inquisit-app/dashboard/GROUP_CHAT_STATS_LOAD_SUCCESS';

const WAITLIST_SNAPSHOT_SUCCESS = 'inquisit-app/dashboard/WAITLIST_SNAPSHOT_SUCCESS';
const WEEKLY_ENROLLMENT_SUCCESS = 'inquisit-app/dashboard/WEEKLY_ENROLLMENT_SUCCESS';

const OUTREACH_UPCOMING_SCHEDULED_CALLS_SUCCESS = 'inquisit-app/dashboard/OUTREACH_UPCOMING_SCHEDULED_CALLS_SUCCESS'
const OUTREACH_MISSED_SCHEDULED_CALLS_SUCCESS = 'inquisit-app/dashboard/OUTREACH_MISSED_SCHEDULED_CALLS_SUCCESS'

const SET_SELECTED_TAB = 'inquisit-app/dashboard/SET_SELECTED_TAB'

const initialState = {
  pendingCalls: {
    mentors: 0,
    mentees: 0
  },
  mentorIssues: [],
  mentorOpCallList: [],
  mentorEfCallList: [],
  menteeIssues: [],
  menteeOpCallList: [],
  menteeEfCallList: [],
  activitySummaryCoreStats: {},
  callTxStats: {},
  callStatus: {
    scheduled: {
      count: 57
    },
    missed: {
      count: 3,
      percent: -3,
      periodDays: 30
    },
    successful: {
      count: 25,
      percent: 50
    },
    voicemailLeft: {
      count: 20,
      percent: 40
    },
    someoneElseAnswered: {
      count: 5,
      percent: 10
    },
    averageTime: '35m: 32s'
  },
  miniModules: {
    completed: 56,
    all: 78
  },
  menteeResponses: {
    checkInQuestionsPercent: 76,
    trackerQuestionsPercent: 76,
  },
  groupChatStats: [],
  waitlistSnapshot: {
    mh: [],
    rw: []
  },
  weeklyNewEnrollments: {
    chart_properties: {
      data: []
    }
  },
  outreachScheduledCalls: {
    upcoming: [],
    missed: []
  },
  selectedTab: 'outreach_and_enrollment',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MENTOR_LOAD_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case MENTOR_SOP_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        mentorIssues: (action.result) ? action.result : []
      };
    case MENTOR_SOP_CALL_LIST_LOAD_SUCCESS: 
      return {
        ...state,
        loading: false,
        mentorOpCallList: (action.result.op_list) ? action.result.op_list : [],
        mentorEfCallList: (action.result.ef_list) ? action.result.ef_list : []
      }
    case MENTEE_SOP_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        menteeIssues: (action.result) ? action.result : []
      };
    case MENTEE_SOP_CALL_LIST_LOAD_SUCCESS: 
      return {
        ...state,
        loading: false,
        menteeOpCallList: (action.result.op_list) ? action.result.op_list : [],
        menteeEfCallList: (action.result.ef_list) ? action.result.ef_list : []
      }
    case MENTOR_ACTIVITY_SUMMARY_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        activitySummaryCoreStats: action.result
      };
    case GROUP_CHAT_STATS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        groupChatStats: (action.result) ? action.result : []
      };
    case MENTOR_CALL_TX_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        callTxStats: action.result.call_tx_breakdown
      };
    case MENTOR_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case WAITLIST_SNAPSHOT_SUCCESS:
      state.waitlistSnapshot.mh = action.result.mh_snapshot;
      state.waitlistSnapshot.rw = action.result.rw_snapshot;
      return {
        ...state
      }
    case WEEKLY_ENROLLMENT_SUCCESS: 
      if(action.result) state.weeklyNewEnrollments = action.result;
      else state.weeklyNewEnrollments = { chart_properties: { data: [] }};
      return {
        ...state
      }
    case OUTREACH_UPCOMING_SCHEDULED_CALLS_SUCCESS:
      state.outreachScheduledCalls.upcoming = action.result
      return {
        ...state
      }
    case OUTREACH_MISSED_SCHEDULED_CALLS_SUCCESS:
      state.outreachScheduledCalls.missed = action.result
      return {
        ...state
      }
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.data.selectedTab
      }
    default:
      return state;
  }
}

export function getMentorSOPSnapshot(orgIds) {
  return {
    types: [MENTOR_LOAD_REQUESTED, MENTOR_SOP_LOAD_SUCCESS, MENTOR_LOAD_FAIL],
    promise: (client) => client.get('/admin/sop/get_sop_mentor_op_eff_list', {
      params: {
        org_ids: orgIds
      }
    })
  };
}

export function getMenteeSOPSnapshot(orgIds) {
  return {
    types: [MENTOR_LOAD_REQUESTED, MENTEE_SOP_LOAD_SUCCESS, MENTOR_LOAD_FAIL],
    promise: (client) => client.get('/admin/sop/get_sop_mentee_op_eff_list', {
      params: {
        org_ids: orgIds
      }
    })
  };
}

export function getSOPList(group) {
  return {
    types: [MENTOR_LOAD_REQUESTED, SOP_LIST_SUCCESS, MENTOR_LOAD_FAIL],
    promise: (client) => client.get('/admin/sop/get_sop_list_by_group', {
      params: {
        group: group
      }
    })
  };
}

export function getActivitySummaryCoreStats(data) {
  return {
    types: [MENTOR_LOAD_REQUESTED, MENTOR_ACTIVITY_SUMMARY_LOAD_SUCCESS, MENTOR_LOAD_FAIL],
    promise: (client) => client.get('/admin/general/get_activity_summary_core_stats', {
      params: {
        admin_id: data.admin_id,
        root_id: data.root_id
      }
    })
  };
}

export function getCallTxStats(data) {
  return {
    types: [MENTOR_LOAD_REQUESTED, MENTOR_CALL_TX_LOAD_SUCCESS, MENTOR_LOAD_FAIL],
    promise: (client) => client.get('/admin/general/get_call_tx_stats', {
      params: data
    })
  };
}

export function getGroupChatStats(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GROUP_CHAT_STATS_LOAD_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/general/get_group_chat_aggregate_data', {
      params: data
    })
  };
}

export function getEsListStats(orgId, rootId, mentorId) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_LOAD_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/es/get_es_list_stats', {
      params: {
        org_id: orgId,
        root_id: rootId,
        mentor_id: mentorId
      }
    })
  }
}

export function getEnrollmentStatsData(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_LOAD_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/dashboard/analytics/get_enrollment_stats_data', {
      params: data
    })
  }
}

export function getHealthfirstHIEData() {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_LOAD_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/getoutreach/get_undismissed_healthfirst_hie_data', {
      params: {}
    })
  }
}

// Post/Set Data
export function setSelectedTab(selectedTab) {
  return {
    type: SET_SELECTED_TAB,
    data: {
      selectedTab: selectedTab
    }
  };
}

export function dismissHIEProspect(mp_hie_id) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_LOAD_SUCCESS, GENERIC_LOAD_FAIL],
    promise: client =>
      client.post('/setoutreach/dismiss_hie_prospect', {
        data: {
          mp_hie_id: mp_hie_id
        },
      }),
  };
}

