const GR_SCORE_SUMMARY_REQUEST = 'inquisit-app/gr/GR_SCORE_SUMMARY_REQUEST';
const GR_SCORE_SUMMARY_SUCCESS = 'inquisit-app/gr/GR_SCORE_SUMMARY_SUCCESS';
const GR_SCORE_SUMMARY_FAIL = 'inquisit-app/gr/GR_SCORE_SUMMARY_FAIL';
const GR_GENERIC_REQUEST = 'inquisit-app/gr/GR_GENERIC_REQUEST';
const GR_GENERIC_SUCCESS = 'inquisit-app/gr/GR_GENERIC_SUCCESS';
const GR_GENERIC_FAIL = 'inquisit-app/gr/GR_GENERIC_FAIL';

const initialState = {
  loading: false,
  summary: [],
  fetched: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GR_SCORE_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GR_SCORE_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        summary: action.result && action.result.data ? action.result.data : [],
        fetched: action.result && action.result.success ? action.result.success : false 
      };
    case GR_SCORE_SUMMARY_FAIL:
      return {
        ...state,
        loading: false
      };
    case GR_GENERIC_REQUEST:
      return{
        ...state,
         loading: true
      };
    case GR_GENERIC_SUCCESS:
      return{
        ...state
      };
    case GR_GENERIC_FAIL:
      return{
        ...state
      };
    default:
      return state;
  }
}

export function getGoalReviewSummary(mentorId) {
	return {
		types: [GR_SCORE_SUMMARY_REQUEST, GR_SCORE_SUMMARY_SUCCESS, GR_SCORE_SUMMARY_FAIL],
		promise: (client) => client.get('goalreviews/get_goal_review_summary',{
			params: {
				mentor_id: mentorId
			}
		})
	};
}


