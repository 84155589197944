const CMS_ISSUE_GUIDER_REQUEST_GENERIC = 'inquisit-app/CMS/CMS_ISSUE_GUIDER_REQUEST_GENERIC';
const CMS_ISSUE_GUIDER_SUCCESS_GENERIC = 'inquisit-app/CMS/CMS_ISSUE_GUIDER_SUCCESS_GENERIC';
const CMS_ISSUE_GUIDER_FAIL_GENERIC = 'inquisit-app/CMS/CMS_ISSUE_GUIDER_FAIL_GENERIC';
const ISSUE_GUIDER_FLOW = 'inquisit-app/CMS/ISSUE_GUIDER_FLOW';

const GENERIC_TYPES = [CMS_ISSUE_GUIDER_REQUEST_GENERIC, CMS_ISSUE_GUIDER_SUCCESS_GENERIC, CMS_ISSUE_GUIDER_FAIL_GENERIC];
const initialState = {
  copiedIssueFlow: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ISSUE_GUIDER_FLOW:
      return {
        ...state,
        copiedIssueFlow: action.result.data
      };
    default:
      return state;
  }
}

// GET

// export function getIssueGuiderTree(data) {
//   return {
//     types: GENERIC_TYPES,
//     promise: (client) => client.get('getcmsdata/issue_guider/get_issue_guider_tree', {
//       params: data
//     })
//   };
// }

export function getIssueGuiderTreeByVersion(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/issue_guider/get_issue_guider_tree_by_version', {
      params: data
    })
  };
}

export function getPNissueGuiderTreeByVersion(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/issue_guider/get_pn_issue_guider_tree_by_version', {
      params: data
    })
  };
}

export function getIssueQuestions(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/issue_guider/get_issue_questions', {
      params: data
    })
  };
}

export function getIssueIntentMasterData(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/issue_guider/get_issue_intent_master_data', {
      params: data
    })
  };
}

export function getIssueGuiderVersions(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/issue_guider/get_issue_guider_versions', {
      params: data
    })
  };
}


// POST

export function copyIssueFlow(data) {
  return {
    type: ISSUE_GUIDER_FLOW,
    result: {
      data
    }
  };
}

export function processAndStoreIssueGuiderTree(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/issue_guider/process_and_store_issue_guider_tree', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function processAndStoreIssueGuiderTreeNew(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/issue_guider/process_and_store_issue_guider_tree_new', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function processAndStorePNIssueGuiderTree(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/issue_guider/process_and_store_pn_issue_guider_tree', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function processAndStorePNIssueGuiderTreeNew(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/issue_guider/process_and_store_pn_issue_guider_tree_new', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function publishIssueGuiderVersion(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/issue_guider/publish_issue_guider_version', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function publishRecipeGuiderVersion(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/recipe/publish_recipe_guider_version', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function publishPNIssueGuiderVersion(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/issue_guider/publish_pn_issue_guider_version', {
      data: { ...data, et_ind: 1 }
    })
  };
}

