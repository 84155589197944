const CR_CALL_REVIEW_LIST_REQUEST = 'inquisit-app/cr/CR_CALL_REVIEW_LIST_REQUEST';
const CR_CALL_REVIEW_LIST_SUCCESS = 'inquisit-app/cr/CR_CALL_REVIEW_LIST_SUCCESS';
const CR_CALL_REVIEW_LIST_FAIL = 'inquisit-app/cr/CR_CALL_REVIEW_LIST_FAIL';
const CR_GENERIC_SUCCESS = 'inquisit-app/cr/CR_GENERIC_SUCCESS';
const CR_GENERIC_REQUEST = 'inquisit-app/cr/CR_GENERIC_REQUEST';
const CR_GENERIC_FAIL = 'inquisit-app/cr/CR_GENERIC_FAIL';
const CR_UPDATE_CALL_REVIEW_MODE = 'inquisit-app/cr/CR_UPDATE_CALL_REVIEW_MODE';
const CR_UPDATE_ACTIVE_CR_INDEX = 'inquisit-app/cr/CR_UPDATE_ACTIVE_CR_INDEX';

const initialState = {
  loading: false,
  callReviewList: [],
  formOpen: false,
  indexActiveCallReviewList: null,
  callReviewMode: 'list',
  rubricId: null,
  redFlagRubricId: null,
  mentorMatchSurveyId: null,
  callNotesSurveyId: null,
  competencyDomainSurveyId: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CR_CALL_REVIEW_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CR_CALL_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        callReviewList: action.result && action.result.data ? action.result.data : [],
        rubricId: action.result && action.result.rubric_ss_id ? action.result.rubric_ss_id : null,
        redFlagRubricId: action.result && action.result.red_flag_rubric_ss_id ? action.result.red_flag_rubric_ss_id : null,
        mentorMatchSurveyId: action.result && action.result.mentor_match_survey_id ? action.result.mentor_match_survey_id : null,
        callNotesSurveyId: action.result && action.result.call_review_notes_survey_ss_id ? action.result.call_review_notes_survey_ss_id : null,
        competencyDomainSurveyId: action.result && action.result.competency_domains_survery_ss_id ? action.result.competency_domains_survery_ss_id : null

      };
    case CR_CALL_REVIEW_LIST_FAIL:
      return {
        ...state,
        loading: false
      };
    case CR_UPDATE_ACTIVE_CR_INDEX:
      return {
        ...state,
        indexActiveCallReviewList: action.result.indexActiveCallReviewList
      }
    case CR_UPDATE_CALL_REVIEW_MODE:
      return {
        ...state,
        callReviewMode: action.result.callReviewMode,
      }
    case CR_GENERIC_SUCCESS:
      return {
        ...state
      }
    default:
      return state;
  }
}

export function updateActiveCRIndex(indexActiveCallReviewList) {
  return {
    type: CR_UPDATE_ACTIVE_CR_INDEX,
    result: {indexActiveCallReviewList: indexActiveCallReviewList}
  };
}

export function updateCallReviewMode(mode) {
  return {
    type: CR_UPDATE_CALL_REVIEW_MODE,
    result: {callReviewMode: mode}
  };
}

export function getCallReviewQueueList(selectedOrg, selectedRoot) {
  return {
    types: [CR_CALL_REVIEW_LIST_REQUEST, CR_CALL_REVIEW_LIST_SUCCESS, CR_CALL_REVIEW_LIST_FAIL],
    promise: (client) => client.get('/callreviews/get_call_review_queue_list', {
      params: {
        org_id: selectedOrg,
        root_id: selectedRoot 
      }
    })
  };
}

export function checkIfCrqIsCheckedOut(crqId, typeGroup, typeId) {
  return {
    types: [CR_GENERIC_REQUEST, CR_GENERIC_SUCCESS, CR_GENERIC_FAIL],
    promise: (client) => client.get('/callreviews/check_if_crq_is_checked_out', {
      params: {
        crq_id: crqId,
        type_group: typeGroup,
        type_id: typeId
      }
    })
  };
}

export function getCallReviewGradingRubricData(sopUserId) {
  return {
    types: [CR_GENERIC_REQUEST, CR_GENERIC_SUCCESS, CR_GENERIC_FAIL],
    promise: (client) => client.get('/callreviews/get_call_review_grading_rubric_data', {
      params: {
        sop_user_id: sopUserId
      }
    })
  };
}

export function getCallReviewNotes(crId, type = null){
  return {
    types: [CR_GENERIC_REQUEST, CR_GENERIC_SUCCESS, CR_GENERIC_FAIL],
    promise: (client) => client.get('/callreviews/get_call_review_notes', {
      params: {
        cr_id: crId,
        note_type: type
      }
    })
  };
}

export function getCallReviewData(crId){
  return {
    types: [CR_GENERIC_REQUEST, CR_GENERIC_SUCCESS, CR_GENERIC_FAIL],
    promise: (client) => client.get('/callreviews/get_call_review_data', {
      params: {
        cr_id: crId
      }
    })
  };
}

export function getCallTxListByManualReviewQueueId(manualReviewQueueId){
  return {
    types: [CR_GENERIC_REQUEST, CR_GENERIC_SUCCESS, CR_GENERIC_FAIL],
    promise: (client) => client.get('/callreviews/get_call_tx_list', {
      params: {
        manual_review_queue_id: manualReviewQueueId
      }
    })
  };
}

export function getRubricInfoByManualReviewQueueId(manualReviewQueueId, org){
  return {
    types: [CR_GENERIC_REQUEST, CR_GENERIC_SUCCESS, CR_GENERIC_FAIL],
    promise: (client) => client.get('/callreviews/get_rubric_info', {
      params: {
        manual_review_queue_id: manualReviewQueueId,
        org: org,
      }
    })
  };
}

export function getUserCallReviewSnapshot(data){
  return {
    types: [CR_GENERIC_REQUEST, CR_GENERIC_SUCCESS, CR_GENERIC_FAIL],
    promise: (client) => client.get('/callreviews/get_user_call_review_snapshot', {
      params: data
    })
  };
}

// Post
export function checkOutCallReview(crqId, checkInOrOut, typeGroup, typeId, org_id) {
  return {
    types: [CR_GENERIC_REQUEST, CR_GENERIC_SUCCESS, CR_GENERIC_FAIL],
    promise: (client) => client.post('/setcallreviewdata/checkout_call_review', {
      data: {
        crq_id: crqId,
        check_in_or_out: checkInOrOut,
        checked_out_by_type_group: typeGroup,
        checked_out_by_type_id: typeId,
        org: org_id,
      }
    })
  };
}

export function updateCallReviewItemStatus(data) {
  return {
    types: [CR_GENERIC_REQUEST, CR_GENERIC_SUCCESS, CR_GENERIC_FAIL],
    promise: (client) => client.post('/setcallreviewdata/update_call_review_queue_item_status', {
      data: data
    })
  };
}

export function manuallyAddCallToReviewQueue(node_type, typeGroup, typeId, userRespectiveNodeId, callTxId, feedbackLink = "", orgId = null) {
  return {
    types: [CR_GENERIC_REQUEST, CR_GENERIC_SUCCESS, CR_GENERIC_FAIL],
    promise: (client) => client.post('/setcallreviewdata/manually_add_call_to_review_queue', {
      data: {
        type: node_type,
        cr_user_type_group: typeGroup,
        cr_user_type_id: typeId,
        user_respective_node_id: userRespectiveNodeId,
        call_tx_id: callTxId,
        feedback_link: feedbackLink,
        org_id: orgId,
      }
    })
  };
}

