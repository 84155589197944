// @flow
export const GENERIC_REQUEST = 'OUTREACH/PROSPECT/GENERIC';

const GENERIC = [GENERIC_REQUEST, GENERIC_REQUEST, GENERIC_REQUEST];

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getReferralProspectsList(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/getoutreach/referral_prospects', {
        params: data
      }),
  };
}

export function processProspectReferralForm(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/setoutreach/process_referral_survey_responses', {
      data: data
    })
  };
}


export function rejectProspectReferral(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/setoutreach/reject_prospect_referral', {
      data: data
    })
  };
}

