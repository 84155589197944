import React from 'react';

export default function Loader({ view, mode }) {

  if (view === 'generic_with_nav') {
    return <div className="flex flex-wrap">
      <div className="flex justify-center items-center grid h-screen place-items-center full-width">
        <div className="lds-facebook"><div></div><div></div><div></div></div>
      </div>
    </div>;
  }

  if (view === 'es_dashboard') {
    return <div className="flex flex-wrap">
      <div className="scrollable-columns m-6">
        <div className="inner-wrp">
          <div className="two-columns mr-3">
            <div className="gray-bd-box bg-white is-border-radius16 p-6" style={{ height: 'calc(100vh - 124px)' }}>
              <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
              {[0, 1, 2, 3].map((item, key) => (
                <div
                  key={key}
                  className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4"
                  style={{ width: '90%' }}
                >
                  Loading...
                </div>
              ))}
            </div>
          </div>
          <div className="each-column">
            <div className="gray-bd-box bg-white is-border-radius16 p-6" style={{ height: 'calc(100vh - 124px)' }}>
              <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
              {[0, 1, 2, 3].map((item, key) => (
                <div
                  key={key}
                  className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4"
                  style={{ width: '90%' }}
                >
                  Loading...
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  if (view === 'es_mentee_list') {
    return <div className="flex flex-wrap">
      <div className="scrollable-columns full-width">
        <div className="three-columns mb-3 full-width">
          <div className="gray-bd-box bg-white is-border-radius16 p-6" style={{ height: '180px' }}>
            <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
            {[0, 1].map((item, key) => (
              <div
                key={key}
                className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4"
                style={{ width: '90%' }}
              >
                Loading...
              </div>
            ))}
          </div>
        </div>
        <div className="three-columns full-width">
          <div className="gray-bd-box bg-white is-border-radius16 p-6" style={{ height: '600px' }}>
            <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
            {[0, 1, 2, 3].map((item, key) => (
              <div
                key={key}
                className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4"
                style={{ width: '90%' }}
              >
                Loading...
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>;
  }

  if (view === 'es_mentee_profile') {
    return <div className="flex flex-wrap">
      <div className={'copilot-cont'} style={{ width: '100%' }}>
        <div className="cop-sidebar-wrp bg-white p-6" style={{ width: '254px' }}>
          <div className="text-2xl text-charcoal-600 font-medium mb-1 loading-gradient">Loading...</div>
          {[0, 1, 2, 3].map((item, key) => (
            <div
              key={key}
              className="flex items-center justyify-between  cursor loading-gradient mb-2.5"
              style={{ width: '90%' }}
            >
              Loading...
            </div>
          ))}
        </div>
        <div
          className={'cop-content-wrp'}
          id="cop-ct-columns-overflow-wrp"
          style={{ width: 'calc(100% - 308px)', paddingLeft: 0, marginLeft: '24px', overflow: 'visible' }}
        >
          <div className='col-wrp is-level-two '>
            {mode && ['conversation', 'issue'].includes(mode) ? (
              <div className="es-profile-statusbar -ml-6 p-2.5 mb-6 bg-white">
                <div className="text-2xl text-charcoal-600 font-medium !mb-0 loading-gradient">Loading...</div>
              </div>
            ) : <div className='mb-6' />}
            <div className={'cop-ct-columns !p-0'}>
              <div className="cols-wrp">
                <div className="ob-wrp">
                  <div className="inner-col overflow-hidden" style={{
                    height: `calc(100vh - 60px - 51px ${mode && ['conversation', 'issue'].includes(mode) ? '- 52px' : ''})`,
                    width: ['goals', 'note', 'material', 'call-schedule'].includes(mode) ? 463 : 312
                  }}>
                    <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
                    {[0, 1, 2, 3].map((item, key) => (
                      <div
                        key={key}
                        className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4 overflow-hidden"
                        style={{ width: '90%' }}
                      >
                        Loading...
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="copilot-right-sidebar-wrp"
              style={{
                width: '54px',
                position: 'fixed',
                top: '51px',
                right: '0',
                height: 'calc(100vh - 51px)',
                backgroundColor: '#fff'
              }}
            >
              <div className="inner-col pt-4 pb-4 pl-3 pr-3 overflow-hidden">
                <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
                {[0, 1, 2, 3].map((item, key) => (
                  <div
                    key={key}
                    className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4 overflow-hidden"
                    style={{ width: '90%' }}
                  >
                    Loading...
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  if (view === 'copilot') {
    return <div className="flex flex-wrap">
      <div className={`copilot-cont`} style={{ width: '100%', position: 'absolute', left: 0, top: 0, height: '100%', zIndex: 11 }}>
        <div
          className={'cop-content-wrp'}
          id="cop-ct-columns-overflow-wrp"
          style={{ width: 'calc(100% - 254)', paddingLeft: 24, overflowX: 'hidden', overflowY: 'hidden', backgroundColor: '#e9eff5' }}
        >
          <div>
            {mode && ['conversation', 'issue', 'overview'].includes(mode) ? (
              <div className="es-profile-statusbar -ml-6 p-2.5 mb-6 bg-white">
                <div className="text-2xl text-charcoal-600 font-medium !mb-0 loading-gradient">Loading...</div>
              </div>
            ) : <div className='mb-6' />}
            {mode === 'overview' ? <>
              <div className={'cop-ct-columns !p-0'}>
                <div className="cols-wrp">
                  <div className="ob-wrp">
                    {
                      [1, 2, 3].map((colIndex) => <div className="col-wrp" key={colIndex}>
                        {[1, 2, 3].map((item) => <div className="inner-col mb-3 overflow-hidden" style={{ height: '25vh' }} key={item}>
                          <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
                          {[0, 1, 2].map((item, key) => (
                            <div
                              key={key}
                              className="flex items-center justyify-between cursor mt-2.5 loading-gradient"
                              style={{ width: '70%' }}
                            >
                              Loading...
                            </div>
                          ))}
                        </div>)}
                      </div>)
                    }
                  </div>
                </div>
              </div>
            </> : <>
              <div className={'cop-ct-columns !p-0'}>
                <div className="cols-wrp">
                  <div className="ob-wrp">
                    <div className="inner-col overflow-hidden" style={{
                      height: `calc(100vh - 60px - 51px ${mode && ['conversation', 'issue'].includes(mode) ? '- 52px' : ''})`,
                      width: ['goals', 'note', 'material', 'call-schedule'].includes(mode) ? 463 : 312
                    }}>
                      <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
                      {[0, 1, 2, 3].map((item, key) => (
                        <div
                          key={key}
                          className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4"
                          style={{ width: '90%' }}
                        >
                          Loading...
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>}

          </div>
        </div>
      </div>
    </div>;
  }

  if (view === 'copilot_with_sidebar') {
    return <div className="flex flex-wrap">
      <div className={`copilot-cont`} style={{ width: '100%'}}>
        <div className="cop-sidebar-wrp bg-white p-6" style={{ width: '254px' }}>
          <div className="text-2xl text-charcoal-600 font-medium mb-1 loading-gradient">Loading...</div>
          {[0, 1, 2, 3].map((item, key) => (
            <div
              key={key}
              className="flex items-center justyify-between  cursor loading-gradient mb-2.5"
              style={{ width: '90%' }}
            >
              Loading...
            </div>
          ))}
        </div>
        <div
          className={'cop-content-wrp'}
          id="cop-ct-columns-overflow-wrp"
          style={{ width: 'calc(100% - 308px)', paddingLeft: 0, marginLeft: '24px', marginTop: '0px', overflow: 'visible' }}
        >
          <div>
            {mode && ['conversation', 'issue'].includes(mode) ? (
              <div className="es-profile-statusbar -ml-6 p-2.5 mb-6 bg-white">
                <div className="text-2xl text-charcoal-600 font-medium !mb-0 loading-gradient">Loading...</div>
              </div>
            ) : <div className='mb-6' />}
            <>
              <div className={'cop-ct-columns !p-0'}>
                <div className="cols-wrp">
                  <div className="ob-wrp">
                    <div className="inner-col overflow-hidden" style={{
                      height: `calc(100vh - 60px - 51px ${mode && ['conversation', 'issue'].includes(mode) ? '- 52px' : ''})`,
                      width: ['goals', 'note', 'material', 'call-schedule'].includes(mode) ? 463 : 312
                    }}>
                      <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
                      {[0, 1, 2, 3].map((item, key) => (
                        <div
                          key={key}
                          className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4"
                          style={{ width: '90%' }}
                        >
                          Loading...
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>;
  }

  if (view === 'inbound_call_queue') {
    return <div className={'inbound-calls-wrp is-in-mentor-dashboard'}>
        <div className="scrollable-columns">
          <div className="inner-wrp">
            <div className="each-column">
              <div className="inner-column" style={{ height: 'calc(100vh - 100px)' }}>
                <div className="por">
                  <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
                  {[0, 1, 2, 3].map((item, key) => (
                    <div
                      key={key}
                      className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4"
                      style={{ width: '90%' }}
                    >
                      Loading...
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>;
  }

  if (view === 'es_mentee_profile_overview') {
    return <div className={'copilot-cont'} style={{ width: '100%'}}>
      <div className="cop-sidebar-wrp bg-white p-6" style={{ width: '254px' }}>
        <div className="text-2xl text-charcoal-600 font-medium mb-1 loading-gradient">Loading...</div>
        {[0, 1, 2, 3].map((item, key) => (
          <div
            key={key}
            className="flex items-center justyify-between  cursor loading-gradient mb-2.5"
            style={{ width: '90%' }}
          >
            Loading...
          </div>
        ))}
      </div>
      <div
        className={'cop-content-wrp'}
        id="cop-ct-columns-overflow-wrp"
        style={{ width: 'calc(100% - 308px)', paddingLeft: 0, marginLeft: '24px', marginTop: 0, overflow: 'visible' }}
      >
        <div>
          <div className="es-profile-statusbar -ml-6 p-3 mb-6 bg-white">
            <div className="text-2xl text-charcoal-600 font-medium !mb-0 loading-gradient">Loading...</div>
          </div>
          <div className={'cop-ct-columns !p-0'}>
            <div className="cols-wrp">
              <div className="ob-wrp">
                {
                  [1, 2, 3].map((colIndex) => <div className="col-wrp" key={colIndex}>
                    {[1, 2, 3].map((item) => <div className="inner-col mb-3 overflow-hidden" style={{ height: '25vh' }} key={item}>
                      <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
                      {[0, 1, 2].map((item, key) => (
                        <div
                          key={key}
                          className="flex items-center justyify-between cursor mt-2.5 loading-gradient"
                          style={{ width: '70%' }}
                        >
                          Loading...
                        </div>
                      ))}
                    </div>)}
                  </div>)
                }
              </div>
            </div>
          </div>
          <div
            className="copilot-right-sidebar-wrp"
            style={{
              width: '54px',
              position: 'fixed',
              top: '51px',
              right: '0',
              height: 'calc(100vh - 51px)',
              backgroundColor: '#fff'
            }}
          >
            <div className="inner-col pt-4 pb-4 pl-3 pr-3 overflow-hidden">
              <div className="text-2xl text-charcoal-600 font-medium mb-5 loading-gradient">Loading...</div>
              {[0, 1, 2, 3].map((item, key) => (
                <div
                  key={key}
                  className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4"
                  style={{ width: '90%' }}
                >
                  Loading...
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  if (view === 'outreach_mentee_profile') {
    return <div>
      <div
        className="flex items-center justyify-between  cursor mt-2.5 loading-gradient mb-4"
        style={{ height: '60vh' }}
      >
        Loading...
      </div>
    </div>
  }

  if (view === 'audio_player') {
    return <div className="gray-bd-box is-border-radius16 is-padding12-16">
      <div className="flex items-center justify-between mb-1">
        <div className="loading-gradient text-charcoal-800 font-medium text-base mr-3">Loading...</div>
        <div className="loading-gradient text-charcoal-600 text-sm">Loading...</div>
      </div>
      <div className="loading-gradient">...</div>
      <div className="loading-gradient">...</div>
    </div>;
  }

  if (view === 'issue_search') {
    return <div style={{width: '434px'}}>
      <div className="each-issue border p-1 m-2"><div className="fz-16 flex text-charcoal-800 mb-1 loading-gradient"><div>Eating: Managing Sweets Intake</div></div><div className="fz-16 text-charcoal-600 ff-reg !mb-05 loading-gradient"><div>Share: <span>Healthy</span> Substitutions for Desserts Loading</div></div><div className="flex justify-end text-base text-blue ff-reg cursor loading-gradient">View Issue</div><div className="fz-14 text-charcoal-600 ff-reg !mb-05"></div></div>
      <div className="each-issue border p-1 m-2"><div className="fz-16 flex text-charcoal-800 mb-1 loading-gradient"><div>Eating: Managing Sweets Intake</div></div><div className="fz-16 text-charcoal-600 ff-reg !mb-05 loading-gradient"><div>Share: <span>Healthy</span> Substitutions for Desserts Loading</div></div><div className="flex justify-end text-base text-blue ff-reg cursor loading-gradient">View Issue</div><div className="fz-14 text-charcoal-600 ff-reg !mb-05"></div></div>
      <div className="each-issue border p-1 m-2"><div className="fz-16 flex text-charcoal-800 mb-1 loading-gradient"><div>Eating: Managing Sweets Intake</div></div><div className="fz-16 text-charcoal-600 ff-reg !mb-05 loading-gradient"><div>Share: <span>Healthy</span> Substitutions for Desserts Loading</div></div><div className="flex justify-end text-base text-blue ff-reg cursor loading-gradient">View Issue</div><div className="fz-14 text-charcoal-600 ff-reg !mb-05"></div></div>
    </div>
  }
  
  return (
     <div className="flex flex-wrap">
       <div className="flex justify-center items-center grid h-screen place-items-center full-width">
        <div className="lds-facebook"><div></div><div></div><div></div></div>
      </div>
     </div>
  );
}

