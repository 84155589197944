const EP_GENERIC_REQUESTED = 'inquisit-app/engagedParty/EP_GENERIC_REQUESTED';
const EP_LIST_LOAD_REQUESTED = 'inquisit-app/engagedParty/EP_LIST_LOAD_REQUESTED';

const EP_GENERIC_SUCCESS = 'inquisit-app/engagedParty/EP_GENERIC_SUCCESS';
const EP_LIST_LOAD_SUCCESS = 'inquisit-app/engagedParty/EP_LIST_LOAD_SUCCESS';
const EP_REST_UPDATE_FLAGS_SUCCESS = 'inquisit-app/engagedParty/EP_REST_UPDATE_FLAGS_SUCCESS';
const EP_STATUS_LIST_SUCCESS = 'inquisit-app/engagedParty/EP_STATUS_LIST_SUCCESS';
const EP_PROGRAM_LIST_SUCCESS = 'inquisit-app/engagedParty/EP_PROGRAM_LIST_SUCCESS';
const EP_ARCHIVE_STATUS_LIST_SUCCESS = 'inquisit-app/engagedParty/EP_ARCHIVE_STATUS_LIST_SUCCESS';
const ARCHIVED_EP_LIST_LOAD_SUCCESS = 'inquisit-app/engagedParty/ARCHIVED_EP_LIST_LOAD_SUCCESS';
const OUTREACH_EP_SCRIPT_SUCCESS = 'inquisit-app/outreach/OUTREACH_EP_SCRIPT_SUCCESS';

const EP_RESET_LIST_SUCCESS = 'inquisit-app/engagedParty/EP_RESET_LIST_SUCCESS';
const EP_GENERIC_FAIL = 'inquisit-app/engagedParty/EP_GENERIC_FAIL';
const EP_LIST_LOAD_FAIL = 'inquisit-app/engagedParty/EP_LIST_LOAD_FAIL';

const initialState = {
  loading: false,
  engagedPartyList: [],
  epProgramList: [],
  archivedEngagedPartyList: [],
  followUpCallScheduled: false,
  followUpCallCancelled: false,
  epStatusList: [],
  epArchiveStatusList: [],
  talkingPoints: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EP_LIST_LOAD_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case EP_GENERIC_REQUESTED:
      return {
        ...state,
      };
    case EP_GENERIC_SUCCESS:
      return {
        ...state,
      };
    case EP_GENERIC_FAIL:
      return {
        ...state,
        error: action.error
      };
    case EP_LIST_LOAD_FAIL:
      return {
        loading: false,
        ...state,
        error: action.error
      };
    case EP_LIST_LOAD_SUCCESS: 
      return {
        ...state,
        loading: false,
        engagedPartyList: action.result.engaged_party_list,
        baseHeaders: action.result.base_headers

      };
    case EP_REST_UPDATE_FLAGS_SUCCESS:
      return {
        ...state,
        outreachRemoved: false,
        followUpCallCancelled: false,
        followUpCallScheduled: false
      };
    case EP_STATUS_LIST_SUCCESS:
      return {
        ...state,
        epStatusList: action.result
      }
    case EP_ARCHIVE_STATUS_LIST_SUCCESS: 
      return {
        ...state,
        epArchiveStatusList: action.result
      }
    case ARCHIVED_EP_LIST_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        archivedEngagedPartyList: action.result.engaged_party_list,
        baseHeaders: action.result.base_headers
      }
    case EP_RESET_LIST_SUCCESS: 
      return {
        ...state,
        engagedPartyList: []
      }
    case EP_PROGRAM_LIST_SUCCESS: 
      return {
        ...state,
        epProgramList: action.result
      }
    case OUTREACH_EP_SCRIPT_SUCCESS:
     return {
        ...state,
        talkingPoints: action.result.script
      };
    default:
      return state;
  }
}

export function resetUpdateFlags() {
  return {
    type: EP_REST_UPDATE_FLAGS_SUCCESS,
    result: true
  };
}

export function getEngagedPartyList(data) {
  return {
    types: [EP_LIST_LOAD_REQUESTED, EP_LIST_LOAD_SUCCESS, EP_LIST_LOAD_FAIL],
    promise: (client) => client.get(':9000/getoutreach/engaged_party_list', {
      params: {
        _st: "Inquisit14",
        org_id: data.org_id,
        page: data.page || 1
      }
    })
  };
}

export function getEnrolledEngagedPartyList() {
  return {
    types: [EP_LIST_LOAD_REQUESTED, EP_LIST_LOAD_SUCCESS, EP_LIST_LOAD_FAIL],
    promise: (client) => client.get(':9000/getoutreach/enrolled_engaged_party_list', {
      params: {
        _st: "Inquisit14"
      }
    })
  };
}

export function getArchivedEngagedPartyList(data) {
  return {
    types: [EP_LIST_LOAD_REQUESTED, ARCHIVED_EP_LIST_LOAD_SUCCESS, EP_LIST_LOAD_FAIL],
    promise: (client) => client.get(':9000/getoutreach/archived_engaged_party_list', {
      params: {
        _st: "Inquisit14",
        org_id: data.org_id
      }
    })
  };
}

export function getEngagedPartyStatuses() {
  return {
    types: [EP_GENERIC_REQUESTED, EP_STATUS_LIST_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/get_engaged_party_status_list')
  };
}

export function getEngagedPartyArchiveStatuses() {
  return {
    types: [EP_GENERIC_REQUESTED, EP_ARCHIVE_STATUS_LIST_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/get_engaged_party_archived_status_list')
  };
}

export function resetEngagedPartyList() {
  return {
    type: EP_RESET_LIST_SUCCESS,
    result: false
  };
}

export function getEngagedPartyProgramList() {
  return {
    types: [EP_GENERIC_REQUESTED, EP_PROGRAM_LIST_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/get_engaged_party_program_list')
  };
}

export function getOutreachEPScript(data) {
  return {
    types: [EP_GENERIC_REQUESTED, OUTREACH_EP_SCRIPT_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/ep_outreach_script', {
      params: {
        outreach_target_id: data.target_id,
        org_id: data.orgId,
        oc_name: data.oc_name
      }
    })
  };
}

// Posts
export function markAsEngaged(outreachTargetId, engagedPartyProgramId) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.post('/setengagedpartydata/mark_as_engaged', {
      data: {
        outreach_target_id: outreachTargetId,
        engaged_party_program_id: engagedPartyProgramId
      }
    })
  };
}

export function updateEngagedPartyStatus(engagedPartyId, engagedPartyProgramId, status) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.post('/setengagedpartydata/update_engaged_party_status', {
      data: {
        engaged_party_id: engagedPartyId,
        engaged_program_id: engagedPartyProgramId,
        status: status
      }
    })
  };
}

export function scheduleCallTwoForMentee(outreachTargetId) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.post('/setengagedpartydata/schedule_call_two_for_mentee', {
      data: {
        outreach_target_id: outreachTargetId
      }
    })
  };
}

export function markEngagedGroupConf(outreachTargetId, emailAddress, note) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.post('/setengagedpartydata/mark_engaged_group_conf', {
      data: {
        outreach_target_id: outreachTargetId,
        email_address: emailAddress,
        note: note
      }
    })
  };
}

export function markEngagedPeerMentorApp(outreachTargetId, emailAddress, mobilePhone, note) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.post('/setengagedpartydata/mark_engaged_peer_mentor_app', {
      data: {
        outreach_target_id: outreachTargetId,
        email_address: emailAddress,
        mobile_phone: mobilePhone,
        note: note
      }
    })
  };
}

export function enrollProspectIntoEngagedSupport(engagedPartyId) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.post('/setengagedpartydata/mark_engaged_engaged_support', {
      data: {
        engaged_party_id: engagedPartyId
      }
    })
  };
}

export function archiveEngagedParty(data) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.post('/setengagedpartydata/archive_engaged_party', {
      data: data
    })
  };
}

export function getEngagedSupportEnrolledList(data) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/get_engaged_support_enrolled_list', {
      params: data
    })
  };
}

export function getEngagedSupportNewlyEnrolledList(data) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/get_engaged_support_newly_enrolled_list', {
      params: data
    })
  };
}

export function getEngagedSupportPendingIntroCallList(data) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/get_pending_es_intro_call_list', {
      params: data
    })
  };
}

export function getEngagedSupportNoCallScheduledList(data) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/get_es_no_call_scheduled_list', {
      params: data
    })
  };
}

export function getEngagedSupportNotSpokenThisMonthList(data) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/get_es_not_spoken_this_month_list', {
      params: data
    })
  };
}

export function getEngagedSupportNotSpokenSinceLastMonthList(data) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/get_es_not_spoken_since_last_month_list', {
      params: data
    })
  };
}

export function getEngagedSupportLostToFollwupList(data) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('/getengagedparty/get_es_lost_to_followup_list', {
      params: data
    })
  };
}

export function getEngagedPartyIdFromOutreachTargetId(outreachTargetId) {
  return {
    types: [EP_GENERIC_REQUESTED, EP_GENERIC_SUCCESS, EP_GENERIC_FAIL],
    promise: (client) => client.get('getengagedparty/get_engaged_party_id_from_outreach_target_id', {
      params: {
        outreach_target_id: outreachTargetId
      }
    })
  };
}
