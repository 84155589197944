const environment = {
  development: {
    isProduction: false
  },
  production: {
    isProduction: true
  }
}[(process.env.NODE_ENV || 'development')];

const orgInformation = {
  // organizations
  getOrgLogoAndColors: (orgId) => {
    const orgLogos = {
      1: {
        logo_filename: 'inquisit-logo.png',
        primary_color: '#213F8E',
        secondary_color: '#92C057'
      },
      4: {
        logo_filename: '1199-logo.png', 
        primary_color: '#4F2684', 
        secondary_color: '#FFD31C'
      },
      5: {
        logo_filename: 'inquisit-logo.png',
        primary_color: '#213F8E',
        secondary_color: '#92C057'
      },
      6: {
        logo_filename: '1199-logo.png',
        primary_color: '#4F2684',
        secondary_color: '#FFD31C'
      },
      7: {
        logo_filename: 'inquisit-logo.png',
        primary_color: '#213F8E',
        secondary_color: '#92C057'
      },
      8: {
        logo_filename: 'inquisit-logo.png',
        primary_color: '#213F8E',
        secondary_color: '#92C057'
      },
      12: {
        logo_filename: 'cmo_logo.png',
        primary_color: '#223C88',
        secondary_color: '#6E5Aff'
      },
      13: {
        logo_filename: 'inquisit-logo.png',
        primary_color: '#213F8E',
        secondary_color: '#92C057'
      },
      14: {
        logo_filename: '1199-logo.png',
        primary_color: '#4F2684',
        secondary_color: '#FFD31C'
      },
      15: {
        logo_filename: 'inquisit-logo.png',
        primary_color: '#213F8E',
        secondary_color: '#92C057'
      },
      16: {
        logo_filename: '1199-logo.png',
        primary_color: '#4F2684',
        secondary_color: '#FFD31C'
      },
      17: {
        logo_filename: 'bsmg-logo.png',
        primary_color: '#0A619E',
        secondary_color: '#102C53'
      },
      18: {
        logo_filename: 'tht-logo.png',
        primary_color: '#166BA5',
        secondary_color: '#C66B32'
      },
      22: {
        logo_filename: 'handh-logo.png',
        primary_color: '#F68D31',
        secondary_color: '#213F8E'
      },
      27: {
        logo_filename: 'hf-logo.png',
        primary_color: '#80C037',
        secondary_color: '#8a8a8a'
      },
      28: {
        logo_filename: 'metroplus-logo@2x.png',
        primary_color: '#278498',
        secondary_color: '#FD3F34'
      }
    }
    if (orgLogos.hasOwnProperty(orgId)) {
      return orgLogos[orgId]; 
    }
    return {
      logo_filename: 'inquisit-logo.png', 
      primary_color: '#F68D31', 
      secondary_color: '#213F8E'
    }
  }
}

const issuer_url = "https://signin.mentor1to1.org";
const client_id = process.env.REACT_APP_ZITADEL_CLIENT_ID; //"220282025628729354@peers";
const user_info_endpoint = "https://signin.mentor1to1.org/oidc/v1/userinfo";

const authConfig = {
  authority: issuer_url, //Replace with your issuer URL
  client_id: client_id, //Replace with your client id
  redirect_uri: (process.env.REACT_APP_HOST || 'http://localhost:3000') + "/#/login_callback",
  response_type: 'code',
  scope: 'openid profile email',
  post_logout_redirect_uri: (process.env.REACT_APP_HOST || 'http://localhost:3000') + '/#/',
  userinfo_endpoint: user_info_endpoint, //Replace with your user-info endpoint
  response_mode: 'query',
  code_challenge_method: 'S256',
  automaticSilentRenew: false,
};

const nomiAuthConfig = {
  authority: "https://nomihealth.okta.com",
  client_id: process.env.REACT_APP_NOMI_CLIENT_ID,
  redirect_uri: "https://" + (process.env.REACT_APP_APIHOST || 'localhost:8000') + "/nomi_callback",
  response_type: 'code',
  scope: 'openid profile email',
  post_logout_redirect_uri: (process.env.REACT_APP_HOST || 'http://localhost:3000') + '/#',
  userinfo_endpoint: "https://nomihealth.okta.com/oauth2/v1/userinfo",
  response_mode: 'query',
  code_challenge_method: 'S256',
};


module.exports = Object.assign({
  host: process.env.HOST || 'localhost',
  port: process.env.PORT,
  apiHost: process.env.REACT_APP_APIHOST || 'platformapi.inq-staging.com',
  ppqHost: process.env.REACT_APP_PPQHOST || 'http://localhost:3333',
  // apiHost: process.env.REACT_APP_APIHOST || 'platformapi.1to1mentor.org',
  // apiHost: process.env.REACT_APP_APIHOST || 'adminapi.mentor1to1.org',
  apiPort: process.env.APIPORT || 80,
  app: {
    title: '1-to-1 Mentor',
    description: '1-to-1 Mentor',
    meta: {
      charSet: 'utf-8',
      property: {
        'og:site_name': 'InquisitHealth',
        'og:image': 'http://inquisithealth.com/img/logo.png',
        'og:locale': 'en_US',
        'og:title': 'InquisitHealth',
        'og:description': 'InquisitHealth'
      }
    }
  },
  FIREBASE: {
    URL: process.env.REACT_APP_FIREBASE || 'https://1to1-mentor-staging.firebaseio.com',
    KEY: process.env.REACT_APP_FIREBASE_KEY || 'AIzaSyDZIQ9DS-rTS4TzEs-LGaEAHx0GCErde2U',
    DOMAIN: process.env.REACT_APP_FIREBASE_DOMAIN || '1to1-mentor-staging.firebaseapp.com',
    BUCKET: process.env.REACT_APP_FIREBASE_BUCKET || 'to1-mentor-staging.appspot.com'
  },
  APPBASE: {
    URL: process.env.REACT_APP_APPBASE || 'https://scalr.api.appbase.io',
    APP: process.env.REACT_APP_APPBASE_APP || 'mentor1to1-admin-staging',
    CREDENTIALS: process.env.REACT_APP_APPBASE_CREDENTIALS || 'rzv1cDUXA:4b1246e5-163a-4abf-8b14-22c5a7bb3faf'
  },
  SECRET: 'AF5eDO8Mwv17LOMXUN3N0Ghfv',
  AUTHCONFIG: authConfig,
  NOMIAUTHCONFIG: nomiAuthConfig,
}, environment, orgInformation);
 
