// @flow
const GENERIC_LOAD = 'GENERIC_MENTOR_SIGNUP';
const GENERIC = [GENERIC_LOAD, GENERIC_LOAD,GENERIC_LOAD];
const initialState = {
};

export default function reducer(state = initialState, action = {}) {
 
  switch (action.type) {
    default:
      return state;
  }
}

export function getAccountSetupData(data){
  return {
    types: GENERIC,
    promise: client => client.get('getmentoraccount/account_setup',  {
        params: data,
      }),
  };
}

export function getMentorContractUrl(data){
  return {
    types: GENERIC,
    promise: client => client.get('getmentoraccount/hellosign_document_url',  {
        params: data,
      }),
  };
}



export function updateMentorProfileProgress(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('setmentoraccount/update_mentor_simple', {
      data: data
    })
  };
}

export function saveAccountInfo(data) {
  data.et_ind = 1;
  return {
    types: GENERIC,
    promise: (client) => client.post('setmentoraccount/set_account_setup', {
      data: data
    })
  };
}

export function saveMentorBackgroundCheckInfo(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('setmentoraccount/set_background_check_info', {
      data: data
    })
  };  
}

export function getMentorBackgroundCheckInfo(mentorId){
  return {
    types: GENERIC,
    promise: client => client.get('getmentoraccount/background_check_info',  {
      params: {
        mentor_id: mentorId
      }
    }),
  };
}
