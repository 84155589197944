const LI_GENERIC_REQUEST = 'inquisit-app/logisticalIssues/LI_GENERIC_REQUEST';
const LI_GENERIC_SUCCESS = 'inquisit-app/logisticalIssues/LI_GENERIC_SUCCESS';
const LI_GENERIC_FAIL = 'inquisit-app/logisticalIssues/LI_GENERIC_FAIL';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getLogisticalIssuesList(data) {
  return {
    types: [LI_GENERIC_REQUEST, LI_GENERIC_SUCCESS, LI_GENERIC_FAIL],
    promise: client =>
      client.get('/issues/logistical_issues_list', {
        params: data
      }),
  };
}

export function submitLogisticalIssue(data) {
  return {
    types: [LI_GENERIC_REQUEST, LI_GENERIC_SUCCESS, LI_GENERIC_FAIL],
    promise: (client) => client.post('/issues/submit_logistical_issue', {
      data: data
    })
  };
}


export function markLogisticalIssueAsResolved(data){
  return {
    types: [LI_GENERIC_REQUEST, LI_GENERIC_SUCCESS, LI_GENERIC_FAIL],
    promise: (client) => client.post('/issues/mark_logistical_issue_as_resolved', {
      data: data
    })
  };
}

export function markLogisticalIssueAsCanceled(data){
  return {
    types: [LI_GENERIC_REQUEST, LI_GENERIC_SUCCESS, LI_GENERIC_FAIL],
    promise: (client) => client.post('/issues/mark_logistical_issue_as_cancelled', {
      data: data
    })
  };
}