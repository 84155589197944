// @flow
const GENERIC_LOAD = 'GET_ES_GENERIC_GOAL_REQUEST';
const GENERIC_SUCCESS = 'GET_ES_GENERIC_GOAL_SUCCESS';
const GENERIC_FAIL = 'GET_ES_GENERIC_GOAL_FAIL';
const GENERIC = [GENERIC_LOAD, GENERIC_SUCCESS,GENERIC_FAIL];
const initialState = {

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getEsMenteeGoalsByType(data){
  return {
      types: GENERIC,
      promise: client =>
        client.get('/es/get_es_mentee_goals_by_type', {
          params: data,
        }),
    };
}

export function getEsMenteeGoalsTemplate(data){
  return {
      types: GENERIC,
      promise: client =>
        client.get('/es/get_es_mentee_goal_template', {
          params: data,
        }),
    };
}

export function getAssociatedTracks(data){
  return {
      types: GENERIC,
      promise: client =>
        client.get('/es/get_issue_associated_tracks', {
          params: data,
        }),
    };
}

export function createEsMenteeHabitGoal(data){
  return {
    types: GENERIC,
    promise: client =>
      client.post('/es/create_es_mentee_habit_goal', {
        data: data,
      }),
  };
}

export function updateEsMenteeTargetGoal(data){
  return {
    types: GENERIC,
    promise: client =>
      client.post('/es/set_es_mentee_update_target_goal', {
        data: data,
      }),
  };
}

export function updateEsMenteeHabitGoal(data){
  return {
    types: GENERIC,
    promise: client =>
      client.post('/es/set_es_mentee_update_habit_goal', {
        data: data,
      }),
  };
}

export function setHabitGoalStreak(data){
  return {
    types: GENERIC,
    promise: client =>
      client.post('/es/set_habit_goal_streak', {
        data: data,
      }),
  };
}

export function deleteGoal(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/es/set_es_mentee_delete_goal', {
        data: data,
      }),
  };
}

export function markGoalComplete(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/es/set_es_mentee_goal_complete', {
        data: data,
      }),
  };
}

export function updateEsMenteeGoalStatus(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/es/update_es_mentee_goal_status', {
        data: data,
      }),
  };
}
