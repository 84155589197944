const CALL_COMPLETE_MODAL_REQUEST = 'inquisit-app/sop/CALL_COMPLETE_MODAL_REQUEST';

const SOP_LOAD_REQUESTED = 'inquisit-app/sop/SOP_LOAD_REQUESTED';
const OPEN_SOP_LOAD_REQUESTED = 'inquisit-app/sop/OPEN_SOP_LOAD_REQUESTED';
const SOP_FAIL = 'inquisit-app/sop/SOP_FAIL';
const MENTOR_OPEN_CALL_SOP_SUCCESS = 'inquisit-app/sop/MENTOR_OPEN_CALL_SOP_SUCCESS';
const MENTEE_OPEN_CALL_SOP_SUCCESS = 'inquisit-app/sop/MENTEE_OPEN_CALL_SOP_SUCCESS';

const MENTOR_GROUPED_SOP_COUNT_SUCCESS = 'inquisit-app/sop/MENTOR_GROUPED_SOP_COUNT_SUCCESS';
const MENTEE_GROUPED_SOP_COUNT_SUCCESS = 'inquisit-app/sop/MENTEE_GROUPED_SOP_COUNT_SUCCESS';
const MENTOR_SOP_THREAD_SUCCESS = 'inquisit-app/sop/MENTOR_SOP_THREAD_SUCCESS';
const MENTEE_SOP_THREAD_SUCCESS = 'inquisit-app/sop/MENTEE_SOP_THREAD_SUCCESS';

const FETCH_CALL_TX_SOP_FLAG = 'inquisit-app/sop/FETCH_CALL_TX_SOP_FLAG';
const RESET_SOP_REQUEST = 'inquisit-app/sop/RESET_SOP_REQUEST'; //This event also triggers a response in externalEntityTx
const CHALLENGES_TO_REVIEW_MODAL_REQUEST = 'inquisit-app/sop/CHALLENGES_TO_REVIEW_MODAL_REQUEST';
const SEND_FEEDBACK_TO_MENTOR_MODAL_REQUEST = 'inquisit-app/sop/SEND_FEEDBACK_TO_MENTOR_MODAL_REQUEST';

const TX_HISTORY = 'inquisit-app/sop/TX_HISTORY';
const FULL_INTERVENTION_HISTORY = 'inquisit-app/sop/FULL_INTERVENTION_HISTORY';

const GENERIC_REQUEST = 'inquisit-app/sop/GENERIC_REQUEST';
const SOP_GENERIC_SUCCESS = 'inquisit-app/sop/SOP_GENERIC_SUCCESS';

const initialState = {
  loading: false,
  callCompleteModal: {
    showInd: false,
    updatingSopsWithoutCall: false,
    userType:'',
    userTypeId:null,
  },
  openCallSopsLoading: false,
  openCallSops: {},
  groupedSopCount: [],
  groupedMenteeSopCount: [],
  tx: [],
  fetchTx: false,
  sopThread: [],
  callTxHistory: [],
  openChallengesListToReviewModal: false,
  openSendFeedbackToMentorModal: false,
  fullInterventionHistory: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_SOP_LOAD_REQUESTED:
      return {
        ...state,
        openCallSopsLoading: true,
      };
    case CALL_COMPLETE_MODAL_REQUEST:
      return {
        ...state,
        callCompleteModal: action.result
      };
    case MENTOR_OPEN_CALL_SOP_SUCCESS:
      return {
        ...state,
        openCallSopsLoading: false,
        openCallSops: (action.result.open_sops) ? action.result.open_sops : {},
        tx: (action.result.tx) ? action.result.tx : []
      };
    case MENTEE_OPEN_CALL_SOP_SUCCESS:
      return {
        ...state,
        openCallSopsLoading: false,
        openCallSops: (action.result.open_sops) ? action.result.open_sops : {},
        tx: (action.result.tx) ? action.result.tx : []
      };
    case FETCH_CALL_TX_SOP_FLAG:
      return {
        ...state,
        fetchTx: action.result
      };
    case MENTOR_GROUPED_SOP_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        groupedSopCount: action.result
      };
    case MENTEE_GROUPED_SOP_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        groupedMenteeSopCount: action.result
      };
    case MENTOR_SOP_THREAD_SUCCESS:
      return {
        ...state,
        loading: false,
        sopThread: action.result
      };
    case RESET_SOP_REQUEST://This event also triggers a response in externalEntityTx
      return {
        ...state,
        loading: false,
        fetchTx: false,
        tx: []
      };
    case CHALLENGES_TO_REVIEW_MODAL_REQUEST:
      return {
        ...state,
        openChallengesListToReviewModal: action.result
      }
    case SEND_FEEDBACK_TO_MENTOR_MODAL_REQUEST:
      return {
        ...state,
        openSendFeedbackToMentorModal: action.result
      }
    case TX_HISTORY: 
      return {
        ...state,
        callTxHistory: action.result
      };
    case FULL_INTERVENTION_HISTORY:
      return {
        ...state,
        fullInterventionHistory: action.result
      };
    default:
      return state;
  }
}

export function toggleCallCompleteModal(showInd, updatingSopsWithoutCall, userType = '', userTypeId=null) {
  return {
    type: CALL_COMPLETE_MODAL_REQUEST,
    result: {
      showInd: showInd,
      updatingSopsWithoutCall: updatingSopsWithoutCall,
      userType:userType,
      userTypeId: userTypeId,
    }
  };
}

export function toggleChallengesToReviewModal(openCloseInd) {
  return {
    type: CHALLENGES_TO_REVIEW_MODAL_REQUEST,
    result: openCloseInd
  };
}

export function toggleSendFeedbackToMentorModal(openCloseInd) {
  return {
    type: SEND_FEEDBACK_TO_MENTOR_MODAL_REQUEST,
    result: openCloseInd
  };
}

export function toggleFetchCallTxFlag(value) {
  return {
    type: FETCH_CALL_TX_SOP_FLAG,
    result: value
  };
}

export function resetSopToDefaults() {
  return {
    type: RESET_SOP_REQUEST, //This event also triggers a response in externalEntityTx
    result: {}
  };
}

export function getMentorOpenCallSops(mentorId, getTxFlag) {
  return {
    types: [OPEN_SOP_LOAD_REQUESTED, MENTOR_OPEN_CALL_SOP_SUCCESS, SOP_FAIL],
    promise: (client) => client.get('/admin/sop/get_mentor_open_call_sops', {
      params: {
        mentor_id: mentorId,
        tx_flag: (getTxFlag) ? 1 : 0
      }
    })
  };
}

export function getMenteeOpenCallSops(menteeId, getTxFlag) {
  return {
    types: [OPEN_SOP_LOAD_REQUESTED, MENTEE_OPEN_CALL_SOP_SUCCESS, SOP_FAIL],
    promise: (client) => client.get('/admin/sop/get_mentee_open_call_sops', {
      params: {
        mentee_id: menteeId,
        tx_flag: (getTxFlag) ? 1 : 0
      }
    })
  };
}

export function getGroupedSopCount(data) {
  return {
    types: [SOP_LOAD_REQUESTED, MENTOR_GROUPED_SOP_COUNT_SUCCESS, SOP_FAIL],
    promise: (client) => client.get('/admin/sop/get_grouped_sops_by_mentor', {
      params: {
        mentor_id: data.mentor_id
      }
    })
  };
}

export function getGroupedSopCountForMentee(data) {
  return {
    types: [SOP_LOAD_REQUESTED, MENTEE_GROUPED_SOP_COUNT_SUCCESS, SOP_FAIL],
    promise: (client) => client.get('/admin/sop/get_grouped_sop_count_for_mentee', {
      params: {
        mentee_id: data.mentee_id
      }
    })
  };
}

export function getSopThread(data) {
  return {
    types: [SOP_LOAD_REQUESTED, MENTOR_SOP_THREAD_SUCCESS, SOP_FAIL],
    promise: (client) => client.get('/admin/sop/get_mentor_sop_threads_by_sop_id', {
      params: {
        mentor_id: data.mentor_id,
        sop_id: data.sop_id
      }
    })
  };
}

export function getMenteeSopThread(data) {
  return {
    types: [SOP_LOAD_REQUESTED, MENTEE_SOP_THREAD_SUCCESS, SOP_FAIL],
    promise: (client) => client.get('/admin/sop/get_mentee_sop_threads_by_sop_id', {
      params: {
        mentee_id: data.mentee_id,
        sop_id: data.sop_id
      }
    })
  };
}

export function setManualBulkExecution(data) {
  return {
    types: [SOP_LOAD_REQUESTED, SOP_GENERIC_SUCCESS, SOP_FAIL],
    promise: (client) => client.post('/admin/sop/set_manual_bulk_execution', {
      data: data
    })
  };
}

export function suspendActiveSop(data) {
  return {
    types: [SOP_LOAD_REQUESTED, SOP_GENERIC_SUCCESS, SOP_FAIL],
    promise: (client) => client.post('/admin/sop/suspend_active_sop', {
      data: data
    })
  };
}

export function resumeSuspendedSop(data) {
  return {
    types: [SOP_LOAD_REQUESTED, SOP_GENERIC_SUCCESS, SOP_FAIL],
    promise: (client) => client.post('/admin/sop/resume_suspended_sop', {
      data: data
    })
  };
}

export function manuallyResolveSop(data) {
  return {
    types: [SOP_LOAD_REQUESTED, SOP_GENERIC_SUCCESS, SOP_FAIL],
    promise: (client) => client.post('/admin/sop/manually_resolve_sop', {
      data: data
    })
  };
}

export function resolveSopByGroupAndTypeId(data) {
  return {
    types: [GENERIC_REQUEST, SOP_GENERIC_SUCCESS, SOP_FAIL],
    promise: (client) => client.post('/admin/sop/resolve_sop', {
      data: data
    })
  };
}

export function getTxHistory(type, typeId) {
  return {
    types: [GENERIC_REQUEST, TX_HISTORY, SOP_FAIL],
    promise: (client) => client.get('/admin/sop/get_tx_history', {
      params: {
        type: type,
        type_id: typeId
      }
    })
  };
}

export function getConnectedCallSopDetails(type, typeId, sopUserId) {
  return {
    types: [GENERIC_REQUEST, SOP_GENERIC_SUCCESS, SOP_FAIL],
    promise: (client) => client.get('/admin/sop/get_connected_call_sop_details', {
      params: {
        type: type,
        type_id: typeId,
        sop_user_id: sopUserId
      }
    })
  };
}

export function getFullSopInterventionHistory(type, typeId) {
  return {
    types: [GENERIC_REQUEST, FULL_INTERVENTION_HISTORY, SOP_FAIL],
    promise: (client) => client.get('/admin/sop/get_full_sop_intervention_history', {
      params: {
        type: type,
        type_id: typeId
      }
    })
  };
}

export function getActiveHtrSopTimeline(data) {
  return {
    types: [GENERIC_REQUEST, SOP_GENERIC_SUCCESS, SOP_FAIL],
    promise: (client) => client.get('/admin/sop/get_active_htr_sop_timeline', {
      params: data
    })
  };
}

