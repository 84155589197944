const LOAD_GENERIC = 'inquisit-app/flexTools/LOAD_GENERIC';
const SUCCESS_GENERIC = 'inquisit-app/flexTools/SUCCESS_GENERIC';
const FAIL_GENERIC = 'inquisit-app/flexTools/FAIL_GENERIC';

const GENERIC = [LOAD_GENERIC, SUCCESS_GENERIC, FAIL_GENERIC];

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getFlexToolTypes() {
  return {
    types: GENERIC,
    promise: (client) => client.get('/flextool/get_flex_tool_types')
  };
}

export function getFlexToolsInstances(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/flextool/get_flex_tools_instances', {
      params:data
    })
  };
}

export function getFlexToolsInstanceData(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/flextool/get_flex_tools_instance_data', {
      params:data
    })
  };
}

export function getFlexToolsCGPInstanceData(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/flextool/get_flex_tools_cgp_instance_data', {
      params:data
    })
  };
}

export function getFlexToolsTaskData(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/flextool/get_flex_tools_task_data', {
      params:data
    })
  };
}

export function getAllFlexToolsInstances() {
  return {
    types: GENERIC,
    promise: (client) => client.get('/flextool/get_all_flex_tools_instances')
  };
}

export function getConversationGuidePointsTree(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/flextool/get_conversation_guide_points_tree', {
      params:data
    })
  };
}

export function getCGPAssociations(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/flextool/get_conversation_guide_points_associations', {
      params:data
    })
  };
}

export function getFlexToolsConversationGuideTalkingPoints(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/flextool/get_flex_tools_conversation_guide_talking_points', {
      params:data
    })
  };
}


export function saveFlexToolNote(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/flextool/save_flex_tool_note',{
      data: data
    })
  };
}

export function saveFlexToolsInstance(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/flextool/save_flex_tools_instance',{
      data: data
    })
  };
}

export function upsertCGPConfig(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/flextool/upsert_cgp_config',{
      data: data
    })
  };
}

export function updateFlexToolsInstance(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/flextool/update_flex_tools_instance',{
      data: data
    })
  };
}

export function processAndStoreConversationGuidePointsTree(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/flextool/process_and_store_conversation_guide_points_tree',{
      data: data
    })
  };
}

export function updateFlexToolsConversationGuiderPoint(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/flextool/update_flex_tools_conversation_guide_point',{
      data: data
    })
  };
}

export function cloneFlexToolsMenteeInstance(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/flextool/clone_mentee_instance',{
      data: data
    })
  };
}

export function createFlexToolsTask(params){
  return {
    types: GENERIC,
    promise: (client) => client.post('/flextool/create_flex_tools_task',{
      data: params
    })
  };
}

export function deleteTodoTask(id){
  return {
    types: GENERIC,
    promise: client =>
      client.post('/flextool/delete_flex_tools_task',  {
        data:{
          et_ind: 1,
          id:id,
        }
      }),
  };
}