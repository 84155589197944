const CMS_REQUEST_GENERIC = 'inquisit-app/CMS/CMS_REQUEST_GENERIC';
const CMS_SUCCESS_GENERIC = 'inquisit-app/CMS/CMS_SUCCESS_GENERIC';
const CMS_FAIL_GENERIC = 'inquisit-app/CMS/CMS_FAIL_GENERIC';

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getIssues(data = {}) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_issues', {
      params: {
        conditions: data.conditionId,
      }
    })
  };
}

export function getIssueById(issueId) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_issue_by_id', {
      params: {
        issue_id: issueId
      }
    })
  };
}

export function getTracks(rootId) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_tracks', {
      params: {
        root_id: rootId
      }
    })
  };
}

export function getTrackById(trackId, majorVersion=-1, minorVersion=-1) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_track_by_id', {
      params: {
        track_id: trackId,
        major_version: majorVersion,
        minor_version: minorVersion
      }
    })
  };
}

export function getAllOrgs() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_all_orgs')
  };
}

export function getStepletTypes() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_steplet_types')
  };
}

export function getTalkingPointSets() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_talking_point_sets')
  };
}

export function getTalkingPointSetById(setId) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_talking_point_set_by_id', {
      params: {
        set_id: setId
      }
    })
  };
}

export function getTalkingPointTopics() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_talking_point_topics')
  };
}

export function getTalkingPointTopicById(topicId) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_talking_point_topic_by_id', {
      params: {
        topic_id: topicId
      }
    })
  };
}

export function getStepletsForStep(stepId) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_steplet_for_step', {
      params: {
        step_id: stepId
      }
    })
  };
}

export function getMinimodulesForSteplet(root_id) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_minimodules_options_for_steplet', {
      params: {
        root_id
      }
    })
  };
}


export function getIssueTypes() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_issue_types')
  };
}

export function getIssueCategories() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_issue_categories')
  };
}

export function getTrackTypes() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_track_types')
  };
}

export function getStepTypes() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_step_types')
  };
}

export function getSurveySetOptions(params) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_survey_set_options', {
      params: params
    })
  };
}

export function getAllConditions() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_all_conditions')
  };
}

export function getMenteeStatusList() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_mentee_status_mt')
  };
}

export function getConditionsByOrg(orgIds) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_conditions_by_org_id', {
      params: {
        org_ids: orgIds
      }
    })
  };
}

export function getExternalResources() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_external_contents_for_steplet', {
      params: {}
    })
  };
}

export function getAllExternalResources(data){
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/external_resources/all_external_resources', {
      params: data
    })
  };
}

export function getAllGoalTemplate(data){
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/goal_template_management/all_goal_templates', {
      params: data
    })
  };
}

export function createIssue(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/create_issue', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function createTrack(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/create_track', {
      data: {
        et_ind: 1,
        track: data.track,
        description: data.description,
        template: data.template,
        track_condition_ids: data.condition_id,
        org_id_arr: data.org_id,
        sub_org_id_arr: data.sub_org_id,
        iss_issue_id_arr: data.issue_id,
        is_global: data.is_global,
        steps: data.steps,
        steps_pathway: data.steps_pathway,
        issue_x_track_id: data.issue_x_track_id,
        type: data.type,
        active: data.active,
        states: data.state,
        counties: data.counties,
        zipcodes: data.zipcodes,
        cities: data.cities,
        pn_only_ind: data.pn_only_ind,
        save_draft: data.save_draft,
        operator: data.operator,
        age:data.age
      }
    })
  };
}

export function createTalkingPointSet(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/create_talking_point_set', {
      data: {
        talking_point_set: data.talking_point_set,
        description: data.description,
        root_id: data.root_id,
        sequence: data.sequence,
        is_active: data.is_active
      }
    })
  };
}

export function createTalkingPointTopic(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/create_talking_point_topic', {
      data: {
        talking_point_topic: data.talking_point_topic,
        description: data.description,
        iss_track_id: data.iss_track_id,
        talking_points: data.talking_points,
        set_id: data.set_id,
        sequence: data.sequence,
        et_ind: 1,
      }
    })
  };
}

export function createUpdateExternalResource(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/external_resources/create_new_resource', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function createGoalTemplate(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/goal_template_management/create_new_goal_template', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function updateGoalTemplate(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/goal_template_management/update_goal_template', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function deleteGoalTemplate(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/goal_template_management/delete_goal_template', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function addSteplet(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/add_steplet', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function updateIssue(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_issue', {
      data: { ...data, et_ind: 1 }
    })
  };
}



export function updateIssueColumn(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_issue_column', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function updateTrack(data, majorVersion, minorVersion, saveAsDraft) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_track', {
      data: {
        et_ind: 1,
        id: data.id,
        track: data.track,
        description: data.description,
        track_conditions: data.condition_id,
        org_id_arr: data.org_id,
        sub_org_id_arr: data.sub_org_id,
        issue_id_arr: data.issue_id,
        is_global: data.is_global,
        is_draft: data.is_draft,
        template: data.template,
        steps: data.steps,
        steps_pathway: data.steps_pathway,
        issue_x_track_id: data.issue_x_track_id,
        type: data.type,
        active: data.active,
        major_version: majorVersion,
        minor_version: minorVersion,
        save_as_draft: saveAsDraft,
        states: data.state,
        counties: data.counties,
        zipcodes: data.zipcodes,
        cities: data.cities,
        disable_issue_id_arr: data.disable_issue_id,
        pn_only_ind: data.pn_only_ind,
        operator: data.operator,
        age:data.age
      }
    })
  };
}

export function publishTrackDraft(id, majorVersion, minorVersion) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/publish_track_draft', {
      data: {
        id: id,
        major_version: majorVersion,
        minor_version: minorVersion,
        et_ind: 1,
      }
    })
  };
}

export function deleteTrackDraft(id, majorVersion, minorVersion) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/delete_track_draft', {
      data: {
        id: id,
        major_version: majorVersion,
        minor_version: minorVersion,
        et_ind: 1,
      }
    })
  };
}

export function updateTrackStep(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_track_step', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function updateTalkingPointSet(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_talking_point_set', {
      data: {
        id: data.id,
        talking_point_set: data.talking_point_set,
        description: data.description,
        root_id: data.root_id,
        sequence: data.sequence,
        is_active: data.is_active,
        et_ind: 1,
      }
    })
  };
}

export function updateTalkingPointTopic(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_talking_point_topic', {
      data: {
        id: data.id,
        talking_point_topic: data.talking_point_topic,
        description: data.description,
        iss_track_id: data.iss_track_id,
        talking_points: data.talking_points,
        set_id: data.set_id,
        sequence: data.sequence,
        et_ind: 1,
      }
    })
  };
}

export function deleteSteplet(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('/setcmsdata/delete_track_steplet', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function updateSteplet(id, fields) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('/setcmsdata/update_steplet', {
      data: {
        id,
        fields,
        et_ind: 1
      }
    })
  };
}

export function addUpdateIssueCategory(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('/setcmsdata/insert_update_issue_category', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function addUpdateTrackType(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('/setcmsdata/insert_update_track_type', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function addUpdateStepType(data) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('/setcmsdata/insert_update_step_type', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function getUserListByType(userType) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('/getcmsdata/get_user_list_by_type', {
      params: {
        user_type: userType,
      }
    })
  };
}




export function getRootList() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('/getcmsdata/root_list', {
    })
  }; 
}

export function getfunctionalAreasOptions() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('/getcmsdata/functional_areas', {
    })
  }; 
}

export function getUserQueuesOptions() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('/getcmsdata/get_user_queues_options', {
    })
  }; 
}

export function getAllLanguages() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('/getcmsdata/get_all_languages',{
    })
  };
}

export function getOrganizationsOptions(){
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('/getcmsdata/get_orgs_list',{
    })
  }
}

export function getPartnerTypeList(){
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('/getcmsdata/get_partner_type_list',{
    })
  }
}


export function addUser(userType, userData) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/create_user', {
      data: {
        et_ind: 1,
        user_type: userType, 
        user_data: userData,
        update_type: 'create',
        changed_fields: ['all'],
      }
    })
  };
}

export function addPartner(userType, userData) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/create_partner', {
      data: {
        et_ind: 1,
        user_type: userType, 
        user_data: userData,
        update_type: 'create',
        changed_fields: ['all'],
      }
    })
  };
}

export function updateUser(userType, userData, changed = []) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_user', {
      data: {
        user_type: userType, 
        user_data: userData,
        changed_fields: changed,
        update_type: 'update',
        et_ind: 1,
      }
    })
  };
}

export function updatePartner(userType, userData, changed = []) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_partner', {
      data: {
        user_type: userType, 
        user_data: userData,
        changed_fields: changed,
        update_type: 'update',
        et_ind: 1,
      }
    })
  };
}

export function getAllOrgsByUserType(userType, userTypeId) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_all_orgs_with_user_org_info', {
      params: {
        user_type: userType,
        user_role_type_id: userTypeId
      }
    })
  };
}

export function saveOrgAssociationByType(userType, userId, selectedOrgs, signature, primaryInd) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/set_org_association_by_type', {
      data: {
        user_type: userType, 
        user_type_role_id: userId, 
        selected_orgs: selectedOrgs, 
        signature: signature, 
        org_primary_ind: primaryInd,
        et_ind: 1
      }
    })
  };
}

export function getAllStates() {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_all_states')
  };
}

export function getStateCounties(params) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_state_counties', {
      params: params
    })
  };
}

export function getCityZipcodes(params) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_city_zipcodes', {
      params: params
    })
  };
}

export function getSubentities(params) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_subentities', {
      params: params
    })
  };
}

export function getOrgListTypes(params) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_org_list_types', {
      params: params
    })
  };
}

export function getCountyCities(params) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_county_cities', {
      params: params
    })
  };
}

export function getStateZipcodes(params) {
  return {
    types: [CMS_REQUEST_GENERIC, CMS_SUCCESS_GENERIC, CMS_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_state_zipcodes', {
      params: params
    })
  };
}