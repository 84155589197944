const MODAL_TOGGLE_REQUEST = 'inquisit-app/modal/MODAL_TOGGLE_REQUEST';
const STEPLET_PREVIEW_MODAL_TOGGLE_REQUEST = 'inquisit-app/modal/STEPLET_PREVIEW_MODAL_TOGGLE_REQUEST';

const initialState = {
  isIrrelevantModalOpen: false,
  data: false,
  isStepletPreviewModalOpen: false,
  stepletPreviewModalData: false
}

export default function reducer(state = initialState, action = {}) {
  switch(action.type) {
    case MODAL_TOGGLE_REQUEST:
      return {
        ...state,
        isIrrelevantModalOpen: action.data.flag,
        data: action.data.data ? action.data.data : false
      };
    case STEPLET_PREVIEW_MODAL_TOGGLE_REQUEST:
      return {
        ...state,
        isStepletPreviewModalOpen: action.data.flag,
        stepletPreviewModalData: action.data.data ? action.data.data : false
      };
    default:
      return state;
  }
}

export function toggleIrrelevantModal(flag, data) {
  return {
    type: MODAL_TOGGLE_REQUEST,
    data: {
      flag: flag,
      data: data
    }
  };
}

export function toggleStepletPreviewModal(flag, data) {
  return {
    type: STEPLET_PREVIEW_MODAL_TOGGLE_REQUEST,
    data: {
      flag: flag,
      data: data
    }
  };
}
