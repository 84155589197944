const GENERIC_REQUEST = 'inquisit-app/PEERBOTCOMMANDS/GENERIC_REQUEST';
const GENERIC_FAIL = 'inquisit-app/PEERBOTCOMMANDS/GENERIC_FAIL';
const GENERIC_SUCCESS = 'inquisit-app/PEERBOTCOMMANDS/GENERIC_SUCCESS';

const GENERIC = [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL];

const SELECT_COMMAND = 'inquisit-app/PEERBOTCOMMANDS/SELECT_COMMAND';
const SET_CURRENT_COMMAND_DATA = 'inquisit-app/PEERBOTCOMMANDS/SET_CURRENT_COMMAND_DATA';

const initialState = {
    commands: [{id:"emergency_ind", value:"emergency", is_indicator: true} ,{id: 'remind', value: 'Remind me', user_type_group: [4]}],
    currentCommandData: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SELECT_COMMAND:
        return {
          ...state,
          currentCommandData: {...state.currentCommandData, ...action.data.command},
        }
    case SET_CURRENT_COMMAND_DATA:
        return {
            ...state,
            currentCommandData: {...action.data.currentCommandData},
        }
    default:
      return state;
  }
}

export function onCommandSelect(command) {
    return {
        type: SELECT_COMMAND,
        data: {
            command: command,
        },
    };
}

export function setCurrentSelectedCommand(currentCommandData) {
  return {
    type: SET_CURRENT_COMMAND_DATA,
    data: {
        currentCommandData: currentCommandData,
    }
  };
}

export function clearCurrentSelectedCommand() {
    return {
        type: SET_CURRENT_COMMAND_DATA,
        data: {
            currentCommandData: {},
        }
    };
}


//API

export function executePeerbotCommands(commandData) {
    return {
        types: GENERIC,
        promise: (client) => client.post('/peerbot/execute_commands', {
            data: commandData
        }),
    };
}

