// @flow
const GENERIC_LOAD = 'inquisit-app/mentorSurvey/GENERIC';
const GENERIC_SUCCESS = 'inquisit-app/mentorSurvey/GENERIC';
const GENERIC_FAIL = 'inquisit-app/mentorSurvey/GENERIC';

const GENERIC = [GENERIC_LOAD, GENERIC_SUCCESS,GENERIC_FAIL];

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
 
  switch (action.type) {
    default:
      return state;
  }
}

/*
 * GET
 */
export function getMentorSurveyQuestions(params) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('admin/getsurveydata/get_mentor_survey_questions',  {
        params: params
      }),
  };
}

/*
 * POST
 */
export function setMentorSurveyQuestionResponse(mentorMasterId, mentor_survey_instance_id, question, cr_id = null) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/admin/setsurveydata/store_mentor_survey_question_response', {
      data: {
        mentor_master_id: mentorMasterId,
        mentor_survey_instance_id: mentor_survey_instance_id,
        question: JSON.stringify(question),
        cr_id: cr_id,
        et_ind: 1,
      }
    })
  };
}

export function bulkStoreMentorSurveyResponse(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/admin/setsurveydata/bulk_store_mentor_survey_question_responses', {
      data: data
    })
  };
}