const MENTOR_LOAD_REQUESTED = 'inquisit-app/mentor/MENTOR_LOAD_REQUESTED';
const MENTOR_GENERIC_REQUESTED = 'inquisit-app/mentor/MENTOR_GENERIC_REQUESTED';
const MENTOR_GENERIC_SUCCESS = 'inquisit-app/mentor/MENTOR_GENERIC_SUCCESS';

const MENTOR_ATTRIB_SUCCESS = 'inquisit-app/mentor/MENTOR_ATTRIB_SUCCESS';
const MENTOR_FAIL = 'inquisit-app/mentor/MENTOR_FAIL';

const MENTOR_PROFILE_SUCCESS = 'inquisit-app/mentor/MENTOR_PROFILE_SUCCESS';
const MENTOR_MENTEE_LIST_SUCCESS = 'inquisit-app/mentor/MENTOR_MENTEE_LIST_SUCCESS';
const MENTOR_AVAILABILITY_SUCCESS = 'inquisit-app/mentor/MENTOR_AVAILABILITY_SUCCESS';

const MENTOR_SET_AVAILABILITY_SUCCESS = 'inquisit-app/mentor/MENTOR_SET_AVAILABILITY_SUCCESS';
const MENTOR_UPDATE_SUCCESS = 'inquisit-app/mentor/MENTOR_UPDATE_SUCCESS';

const SIP_CALL_SUCCESS = 'inquisit-app/mentor/SIP_CALL_SUCCESS';
const GENERIC_SUCCESS = 'inquisit-app/mentor/GENERIC_SUCCESS';

const MENTOR_LIST_SUCCESS = 'inquisit-app/mentor/MENTOR_LIST_SUCCESS';

const SUMMARY_CALL_ACTIVITY_SUCCESS = 'inquisit-app/mentor/SUMMARY_CALL_ACTIVITY_SUCCESS';
const SUMMARY_HISTORY_SUCCESS = 'inquisit-app/mentor/SUMMARY_HISTORY_SUCCESS';
const SUMMARY_PAY_YTD_SUCCESS = 'inquisit-app/mentor/SUMMARY_PAY_YTD_SUCCESS';
const SUMMARY_PAY_MONTH_SUCCESS = 'inquisit-app/mentor/SUMMARY_PAY_MONTH_SUCCESS';
const SUMMARY_TRAINING_SUCCESS = 'inquisit-app/mentor/SUMMARY_TRAINING_SUCCESS';
const SUMMARY_GUIDANCE_MONTH_SUCCESS = 'inquisit-app/mentor/SUMMARY_GUIDANCE_MONTH_SUCCESS';
const SUMMARY_GROUP_CONF_CALL_MONTH_SUCCESS = 'inquisit-app/mentor/SUMMARY_GROUP_CONF_CALL_MONTH_SUCCESS';

const ADDL_TRAINING_SUCCESS = 'inquisit-app/mentor/ADDL_TRAINING_SUCCESS';
const SAVE_ADDL_TRAINING_SUCCESS = 'inquisit-app/mentor/SAVE_ADDL_TRAINING_SUCCESS';

const PROGRAM_DURATION_SUCCESS = 'inquisit-app/mentor/PROGRAM_DURATION_SUCCESS';

const MENTOR_CALL_TX_DATA_SUCCESS = 'inquisit-app/mentor/MENTOR_CALL_TX_DATA_SUCCESS';
const MENTOR_ACCOUNT_STATUSES_SUCCESS = 'inquisit-app/mentor/MENTOR_ACCOUNT_STATUSES_SUCCESS';
const MENTOR_STATUS_AND_PROBATION_LEVEL_LIST_SUCCESS = 'inquisit-app/mentor/MENTOR_STATUS_AND_PROBATION_LEVEL_LIST_SUCCESS';

const MENTOR_QUALITY_SCORES_SUCCESS = 'inquisit-app/mentor/MENTOR_QUALITY_SCORES_SUCCESS';
const MENTOR_SMS_DATA_SUCCESS = 'inquisit-app/mentor/MENTOR_SMS_DATA_SUCCESS';
const MENTOR_LIST_BY_ROOT_SUCCESS = 'inquisit-app/mentor/MENTOR_LIST_BY_ROOT_SUCCESS';
const MENTOR_LIST_OPTIONS_SUCCESS = 'inquisit-app/mentor/MENTOR_LIST_OPTIONS_SUCCESS';

const initialState = {
  loading: false,
  mentorAttribs: [],
  mentorProfile: {},
  menteeList: [], // List of mentees being mentored
  availability: [],
  list: {}, // List of all the mentors,
  searchList: [],
  callTx: {
    data: [],
    total: 0
  },
  menteeProgress: [],
  summaryData: {},
  summaryCallActivity: {},
  summaryHistory: {},
  summaryPayYtd: {},
  summaryPayMonth: {},
  summaryTraining: {},
  summaryGuidanceMonth: [],
  summaryGroupConfCall: {},
  addlTraining: [],
  accountStatusList: [],
  mentorInvited: false,
  qualityScores: [],
  mentorStatusAndProbationLevelList: [],
  mentorListByRoot: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MENTOR_LOAD_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case MENTOR_ATTRIB_SUCCESS:
      return {
        ...state,
        loading: false,
        mentorAttribs: action.result
      };
    case MENTOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.result
      };
    case MENTOR_PROFILE_SUCCESS:
      if (!action.result.mentor_profile) {
        return {
          ...state,
          loading: false,
          mentorProfile: {}
        };
      }
      return {
        ...state,
        loading: false,
        mentorProfile: action.result.mentor_profile
      };
    case MENTOR_MENTEE_LIST_SUCCESS:
      if (!action.result) {
        return {
          ...state,
          loading: false,
          menteeList: []
        };
      }
      return {
        ...state,
        loading: false,
        menteeList: action.result
      };
    case MENTOR_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: action.result
      };
    case MENTOR_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    /*
    case SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        summaryData: action.result
      };
    */
    case SUMMARY_CALL_ACTIVITY_SUCCESS:
      return {
        ...state,
        summaryCallActivity: action.result
      };
    case SUMMARY_HISTORY_SUCCESS:
      return {
        ...state,
        summaryHistory: action.result
      };
    case SUMMARY_PAY_YTD_SUCCESS:
      return {
        ...state,
        summaryPayYtd: action.result
      };
    case SUMMARY_PAY_MONTH_SUCCESS:
      return {
        ...state,
        summaryPayMonth: action.result
      };
    case SUMMARY_TRAINING_SUCCESS:
      return {
        ...state,
        summaryTraining: action.result
      };
    case SUMMARY_GUIDANCE_MONTH_SUCCESS:
      return {
        ...state,
        summaryGuidanceMonth: action.result
      };
    case SUMMARY_GROUP_CONF_CALL_MONTH_SUCCESS:
      return {
        ...state,
        summaryGroupConfCall: action.result
      };
    case ADDL_TRAINING_SUCCESS:
      return {
        ...state,
        addlTraining: action.result
      };
    case PROGRAM_DURATION_SUCCESS:
      return {
        ...state,
        menteeProgress: action.result
      };
    case MENTOR_CALL_TX_DATA_SUCCESS:
      return {
        ...state,
        callTx: action.result
      };
    case MENTOR_ACCOUNT_STATUSES_SUCCESS:
      return {
        ...state,
        accountStatusList: action.result        
      }
    case MENTOR_QUALITY_SCORES_SUCCESS:
      return {
        ...state,
        qualityScores: (action.result) ? action.result : []
      }
    case MENTOR_STATUS_AND_PROBATION_LEVEL_LIST_SUCCESS:
      return {
        ...state,
        mentorStatusAndProbationLevelList: (action.result) ? action.result: []
      }
    case MENTOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case MENTOR_LIST_BY_ROOT_SUCCESS:
      return {
        ...state,
        mentorListByRoot: action.result.mentor_list ? action.result.mentor_list : []
      }
    case MENTOR_LIST_OPTIONS_SUCCESS:
      return {
        ...state,
        mentorStatusAndProbationLevelList: (action.result && action.result.options && action.result.options.mentor_statuses ) ? action.result.options.mentor_statuses: []
      }
    default:
      return state;
  }
}

export function getMentorList(data) {
  return {
    types: [MENTOR_LOAD_REQUESTED, MENTOR_LIST_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_list', {
      params: {
        admin_id: data.admin_id,
        root_id: data.root_id,
        org_id: data.org_id
      }
    })
  };
}


export function getMentorProfile(data) {
  return {
    types: [MENTOR_LOAD_REQUESTED, MENTOR_PROFILE_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_profile', {
      params: {
        mentor_id: data.mentor_id,
      }
    })
  };
}

export function getMentorMenteeList(data) {
  return {
    types: [MENTOR_LOAD_REQUESTED, MENTOR_MENTEE_LIST_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_mentee_list', {
      params: {
        mentor_id: data.mentor_id,
      }
    })
  };
}

export function getAvailability(data) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_AVAILABILITY_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_availability', {
      params: {
        mentor_id: data.mentor_id
      }
    })
  };
}

export function getSeniorMentorAvailability(data) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/senior_mentor_availability', {
      params: {
        mentor_id: data.mentor_id
      }
    })
  };
}

export function getMentorOutOfOffice(data) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_out_of_office', {
      params: data
    })
  };
}

export function updateAvailability(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_SET_AVAILABILITY_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentordata/set_availability', {
      data: {et_ind: 1, ...object}
    })
  };
}

export function updateSeniorMentorAvailability(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_SET_AVAILABILITY_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentordata/set_senior_mentor_availability', {
      data: {et_ind: 1, ...object}
    })
  };
}

export function removeSeniorMentorAvailability(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_SET_AVAILABILITY_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentordata/remove_senior_mentor_availability', {
      data: {et_ind: 1, ...object}
    })
  };
}

export function addMentorOutOfOfficeDateRange(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_SET_AVAILABILITY_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentordata/add_mentor_out_of_office_date_range', {
      data: {et_ind: 1, ...object}
    })
  };
}


export function removeMentorOutOfOfficeDateRangeById(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_SET_AVAILABILITY_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentordata/remove_mentor_out_of_office_date_range_by_id', {
      data: {et_ind: 1, ...object}
    })
  };
}

export function updateProfileField(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_UPDATE_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setadministrationdata/update_profile_field', {
      data: object
    })
  };
}

export function getSummaryData(mentorId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_summary_data', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getAnalyticsData(mentorId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_analytics', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getSummaryCallActivity(mentorId, timeInterval) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_summary_call_activity', {
      params: {
        mentor_id: mentorId,
        time_interval: timeInterval
      }
    })
  };
}

export function getSummaryHistory(mentorId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SUMMARY_HISTORY_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_summary_history', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getSummaryPayYTD(mentorId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SUMMARY_PAY_YTD_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_summary_pay_ytd', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getSummaryPayByMonth(mentorId, orgId, month) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SUMMARY_PAY_MONTH_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_summary_pay_details_by_month', {
      params: {
        mentor_id: mentorId,
        org_id: orgId,
        month: month
      }
    })
  };
}

export function getSummaryTraining(mentorId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SUMMARY_TRAINING_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_summary_training', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getSummaryGuidanceByMonth(mentorId, month) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SUMMARY_GUIDANCE_MONTH_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_summary_guidance_by_month', {
      params: {
        mentor_id: mentorId,
        month: month
      }
    })
  };
}

export function getSummaryGroupConfCallsByMonth(mentorId, month) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SUMMARY_GROUP_CONF_CALL_MONTH_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_summary_group_conf_calls_by_month', {
      params: {
        mentor_id: mentorId,
        month: month
      }
    })
  };
}

export function getAddlComp(data) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, ADDL_TRAINING_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_addl_comp', {
      params: data
    })
  };
}

export function getMenteesProgramDuration(data) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, PROGRAM_DURATION_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/getmentoringprogramdata/get_mentees_program_duration', {
      params: data
    })
  };
}


export function getMentorCallTx(data) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_CALL_TX_DATA_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_call_tx', {
      params: data
    })
  };
}

export function getMentoringActivity(data) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/getmentoraccount/get_mentoring_activity', {
      params: {
        mentor_id: data.mentorId,
        org_id: data.orgId,
        start_date: (data.startDate) ? data.startDate : null
      }
    })
  };
}

export function getAllCallTransactionsByMonth(data) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/getmentoraccount/get_all_call_transactions_by_month', {
      params: {
        mentor_id: data.mentorId,
        start_date: data.startDate
      }
    })
  };
}

// export function getClinicalFields(orgUri) {
//   return {
//     types: [MENTOR_LOAD_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
//     promise: (client) => client.get('/getmentoraccount/clinical_fields', {
//       params: {
//         org_uri: orgUri,
//       }
//     })
//   };
// }

// export function getDemoFields(orgUri) {
//   return {
//     types: [MENTOR_LOAD_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
//     promise: (client) => client.get('/getmentoraccount/demo_fields', {
//       params: {
//         org_uri: orgUri,
//       }
//     })
//   };
// }

export function getDemoClinicalFieldsMentor(mentorId, fieldType) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/getmentoraccount/core_demographic_clinical_fields_mentor', {
      params: {
        mentor_id: mentorId,
        field_type: fieldType
      }
    })
  };
}

export function getClinicalAndDemographicDataForMentor(params) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/getmentoraccount/get_clinical_and_demographic_data_for_mentor', {
      params: params
    })
  };
}

export function getClinicalDemoData(mentorId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/getmentoraccount/clinical_demo_data', {
      params: {
        mentor_id: mentorId,
      }
    })
  };
}

export function getDemoDependencyField(data) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/getmentoraccount/demo_dependency_fields', {
      params: {
        dependency_base_id: data.dependencyBaseId,
        base_id: data.baseId,
        selected_values: JSON.stringify(data.selectedValues)
      }
    })
  };
}

// export function getGenderBase() {
//   return {
//     types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
//     promise: (client) => client.get('/getmentoraccount/gender_base', {
//       params: {}
//     })
//   };
// }

export function getMenteesProgramPhases(mentorId, selectedRootId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/getmentoringprogramdata/get_mentees_program_phases', {
      params: {
        mentor_id: mentorId,
        selected_root_id: selectedRootId
      }
    })
  };
}

export function getMentorStatuses(mentorId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_ACCOUNT_STATUSES_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('admin/getmentorsdata/get_mentor_statuses', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getMentorStatusAndProbationLevelsList() {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_STATUS_AND_PROBATION_LEVEL_LIST_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('admin/getmentorsdata/get_mentor_status_and_probation_levels_list', {
      params: {}
    })
  };
}

export function getMentorListOfPossibleChallengesToReview(mentorId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('admin/getmentorsdata/get_mentor_list_of_possible_challenges_to_review', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getMentorQualityScores(mentorId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_QUALITY_SCORES_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/get_mentor_quality_scores', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getEsMentorQualityScores(mentorId){
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/get_es_mentor_quality_scores', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getMentorSmsData(mentorId) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_SMS_DATA_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/get_mentor_sms_data', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getESMentorConfirmedAvailabilitySlots(mentorId, startDate) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/es_mentor_confirmed_availability', {
      params: {
        mentor_id: mentorId,
        start_date: startDate,
      }
    })
  };
}

export function getCapacityBrackets() {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/mentor_capacity_brackets', {
      params: {}
    })
  };
}

export function getMentorLevels() {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/get_mentor_levels', {
      params: {}
    })
  };
}

export function getMentorBadges() {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/get_mentor_badges', {
      params: {}
    })
  };
}

export function getMentorListByRoot(root_id) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_LIST_BY_ROOT_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/get_mentors_by_root', {
      params: {
        root_id: root_id,
      }
    })
  };
}

export function getESCallReminderProps(mentor_id) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/getmentoraccount/es_call_reminder_props', {
      params: {
        mentor_id: mentor_id
      }
    })
  };
}

export function getMentorListOptions(mentor_id) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_LIST_OPTIONS_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/get_mentor_list_filter_options', {
      params: {
        mentor_id: mentor_id
      }
    })
  };
}

export function getMentorCallQueues(params) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.get('/admin/getmentorsdata/get_mentor_call_queues', {
      params: params
    })
  };
}


//  Post
export function setMentorClinicalDemoInfo(data) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/setmentoraccount/set_clinical_demo_info', {
      data: {
        et_ind: 1,
        mentor_id: data.mentorId,
        attributes_arr: data.clinicalDemoInfo
      }
    })
  };
}

export function updateMentorCallTx(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/update_mentor_call_tx', {
      data: {
        call_sid: object.callSid,
        note: object.note
      }
    })
  };
}

export function upsertMentorCallQueues(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/upsert_mentor_call_queues', {
      data: object
    })
  };
}

export function makeCall(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SIP_CALL_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/siptelephony/initiate_call', {
      data: object
    })
  };
}

export function addAddlComp(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SAVE_ADDL_TRAINING_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/set_addl_comp', {
      data: object
    })
  };
}

export function bulkAddAddlComp(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SAVE_ADDL_TRAINING_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/bulk_set_addl_comp', {
      data: object
    })
  };
}

export function updateAddlComp(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SAVE_ADDL_TRAINING_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/update_addl_comp', {
      data: object
    })
  };
}

export function deleteAddlComp(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SAVE_ADDL_TRAINING_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/delete_addl_comp', {
      data: object
    })
  };
}

export function approveAddlComp(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SAVE_ADDL_TRAINING_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/approve_addl_comp', {
      data: object
    })
  };
}

export function rejectAddlComp(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, SAVE_ADDL_TRAINING_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/reject_addl_comp', {
      data: object
    })
  };
}

export function inviteNewMentor(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/setadministrationdata/invite_mentor', {
      data: object
    })
  };
}
export function resendNewMentorInvite(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/setadministrationdata/resend_mentor_invitation', {
      data: object
    })
  };
}

export function resendMentorInvite(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/resend_mentor_invite', {
      data: object
    })
  };
}

export function createHellosignMentorContract(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/setadministrationdata/create_hellosign_contract', {
      data: object
    })
  };
}

export function updateMentorStatus(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/update_mentor_status', {
      data: {
        et_ind: 1,
        ...object
      }
    })
  };
}

export function insertIntoMentorCallReviewQueue(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/insert_mentor_call_review_queue', {
      data: object
    })
  };
}

export function manuallyTriggerCallReview(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('admin/setmentorsdata/manually_trigger_call_review', {
      data: {
        et_ind: 1,
        ...object
      }
    })
  };
}

export function confirmESMentorAvailability(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('admin/setmentordata/confirm_es_mentor_availability', {
      data: {
        et_ind: 1,
        ...object
      }
    })
  };
}

export function updateMentorConditionAssociation(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('admin/setmentorsdata/associate_mentor_condition', {
      data: {et_ind: 1, ...object}
    })
  };
}

export function updateMentorOrganizationAssociation(object){
  return {
    types: [MENTOR_GENERIC_REQUESTED,GENERIC_SUCCESS,MENTOR_FAIL],
    promise: (client) => client.post('admin/setmentorsdata/associate_mentor_organizations', {
      data: {et_ind: 1, ...object}
    })
  };
}

export function markTutorialSectionComplete(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('setmentoringprogramdata/mark_tutorial_section_complete', {
      data: object
    })
  };
}

export function sendPhoneTextVerification(object){
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('setmentoraccount/set_phone_num', {
      data: {et_ind: 1, ...object}
    })
  };
}

export function updateESCallReminderProp(object) {
  return {
    types: [MENTOR_GENERIC_REQUESTED, MENTOR_GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/setmentoraccount/update_es_call_reminder_prop', {
      data: {et_ind: 1, ...object}
    })
  };
}

export function verifyEnteredPin(object){
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('setmentoraccount/verify_pin', {
      data: {et_ind: 1, ...object}
    })
  };
}

export function submitMentorBackgroundCheckCheckrRequest(object){
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('setmentoraccount/submit_mentor_background_check_checkr_request', {
      data: object
    })
  };
}

export function bulkUpdateMentors(object){
  return {
    types: [MENTOR_GENERIC_REQUESTED, GENERIC_SUCCESS, MENTOR_FAIL],
    promise: (client) => client.post('/admin/setmentorsdata/bulk_update_mentors', {
      data: object
    })
  };
}

