const PM_COMMUNITY_REQUEST_GENERIC = 'inquisit-app/PM_COMMUNITY/PM_COMMUNITY_REQUEST_GENERIC';
const PM_COMMUNITY_SUCCESS_GENERIC = 'inquisit-app/PM_COMMUNITY/PM_COMMUNITY_SUCCESS_GENERIC';
const PM_COMMUNITY_FAIL_GENERIC = 'inquisit-app/PM_COMMUNITY/PM_COMMUNITY_FAIL_GENERIC';

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getQaList(rootId) {
  return {
    types: [PM_COMMUNITY_REQUEST_GENERIC, PM_COMMUNITY_SUCCESS_GENERIC, PM_COMMUNITY_FAIL_GENERIC],
    promise: (client) => client.get(':9000/qa/question', {
      params: {
        _st: "Inquisit14",
        tab: "feed",
        role: "mentee",
        root_id: rootId,
        id: 0
      }
    })
  };
}


export function getQaQuestionResponses(masterQuestionId) {
  return {
    types: [PM_COMMUNITY_REQUEST_GENERIC, PM_COMMUNITY_SUCCESS_GENERIC, PM_COMMUNITY_FAIL_GENERIC],
    promise: (client) => client.get(':9000/qa/question/'+masterQuestionId+'/answer', {
      params: {
        _st: "Inquisit14",
        id: 0
      }
    })
  };
}
