const LOAD_REQUESTED = 'inquisit-app/issue/LOAD_REQUESTED';
const GENERIC_SUCCESS = 'inquisit-app/issue/GENERIC_SUCCESS';
const REQUEST_FAIL = 'inquisit-app/issue/REQUEST_FAIL';

const LOAD_ISSUES = 'inquisit-app/issue/LOAD_ISSUES';
const SUCCESS_ISSUES = 'inquisit-app/issue/SUCCESS_ISSUES';
const FAIL_ISSUES = 'inquisit-app/issue/FAIL_ISSUES';

const SUCCESS_SORT_ISSUES = 'inquisit-app/issue/SUCCESS_SORT_ISSUES';

const UPDATE_ISSUE_BY_TAB = 'inquisit-app/issue/UPDATE_ISSUE_BY_TAB';
const UPDATE_TRACK_STEP_FOR_GOAL_PANEL = 'inquisit-app/issue/UPDATE_TRACK_STEP_FOR_GOAL_PANEL';
const initialState = {
  loading: true,
  activeIssues: [],
  upNextIssues: [],
  escalatedIssues: [],
  addressedIssues: [],
  alertSections: {},
  openTrackInd: false
};

const GENERIC = [LOAD_REQUESTED, GENERIC_SUCCESS, REQUEST_FAIL];

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ISSUES:
      return {
        ...state,
        loading: true,
        activeIssues: []
      };
    case SUCCESS_ISSUES:
      return {
        ...state,
        loading: false,
        activeIssues: (action.result && action.result.success && action.result.active) ? action.result.active : [],
        upNextIssues: (action.result && action.result.success && action.result.up_next) ? action.result.up_next : [],
        escalatedIssues: (action.result && action.result.success && action.result.escalated) ? action.result.escalated : [],
        addressedIssues: (action.result && action.result.success && action.result.addressed) ? action.result.addressed : [],
        alertSections: (action.result && action.result.success && action.result.alert_sections) ? action.result.alert_sections : {}
      };
    case SUCCESS_SORT_ISSUES:
      return {
        ...state,
        activeIssues: action.result.active_issues_list
      };
    case FAIL_ISSUES:
      return {
        ...state,
        loading: false,
        activeIssues: []
      };
    case UPDATE_ISSUE_BY_TAB:
      return {
        ...state,
        ...{[action.result.tab]: action.result.data}
      };
    case UPDATE_TRACK_STEP_FOR_GOAL_PANEL:
      action.result.ind
      return {
        ...state,
        openTrackInd: action.result.ind
      };
    default:
      return state;
  }
}

export function getMenteeIssues(menteeId, mentorId, loading = true) {
  return {
    types: [loading ? LOAD_ISSUES : LOAD_REQUESTED, SUCCESS_ISSUES, FAIL_ISSUES],
    promise: (client) => client.get('/issue/mentee_issues_by_tab', {
      params: {
        mentee_id: menteeId,
        mentor_id: mentorId
      }
    })
  };
}

export function getIssuesToAddList(menteeId, epbId, profile = '') {
  return {
    types: GENERIC,
    promise: (client) => client.get('/issue/add_issues_list', {
      params: {
        mentee_id: menteeId,
        epb_id: epbId,
        profile
      }
    })
  };
}

export function getIssueActiveTracksAndSteps(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/issues/mentee_issue_tracks_and_steps_details', {
        params: data,
      }),
  };
}

export function getTrackSteps(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/issues/get_track_steps', {
        params: data,
      }),
  };
}

export function getStepDetails(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/issues/get_step_details', {
        params: data,
      }),
  };
}

export function getMenteeIssueStats(menteeId) {
  return {
    types: [LOAD_ISSUES, SUCCESS_ISSUES, FAIL_ISSUES],
    promise: (client) => client.get('/issue/get_mentee_issue_stats', {
      params: {
        mentee_id: menteeId
      }
    })
  };
}

export function getITSSearch(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/issues/search', {
      params: data
    })
  };
}


export function getITSWeaviateSearch(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get(':9000/api/v2/search/issues', {
      params: data
    })
  };
}

export function getAITSSearchSuggestions(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/issues/get_AITS_search_suggestions', {
      params: data
    })
  };
}

export function getMenteeIssueGuider(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/issue/get_issue_guider', {
      params: data
    })
  };
}

export function getPNMenteeIssueGuider(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/issue/get_pn_issue_guider', {
      params: data
    })
  };
}

export function getMenteeTrackSteps(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/issues/get_mentee_track_with_steps', {
      params: data
    })
  };
}

export function getNodeNotes(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/issues/get_issue_guider_node_notes', {
        params: data,
      }),
  };
}

export function getRecipeNodeNotes(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/issues/get_recipe_guider_node_notes', {
        params: data,
      }),
  };
}

export function getAreaNodeNotes(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/issues/get_area_guider_node_notes', {
        params: data,
      }),
  };
}

export function getGlobalTalkingPointNotes(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/issues/get_global_talking_point_notes', {
        params: data,
      }),
  };
}

export function getPmIssueActions(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/issues/get_pm_issue_actions', {
        params: data,
      }),
  };
}

export function setSortedActiveIssues(activeIssuesList) {
  return {
    type: SUCCESS_SORT_ISSUES,
    result: {
      active_issues_list: activeIssuesList
    }
  };
}

export function toggleDesignateImportantIssue(data) {
  return {types: GENERIC,
  promise: client => 
    client.post('/issues/toggle_designate_important_issue', {
      data: data,
    })
  }
}

export function addIssues(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/add_issues', {
        data: data,
      }),
  };
}

export function issueIssueActionRequiredContext(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/issues/add_issue_action_required_context', {
      data: data
    })
  };
}

export function markIssueNotApplicable(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/issues/mark_issue_notApplicable', {
      data: data
    })
  };
}


export function reinforceIssueRecommendation(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/issues/reinforce_issue_recommendation', {
      data: data
    })
  };
}


export function updateIssueByTab(tab, data) {
  return {
    type: UPDATE_ISSUE_BY_TAB,
    result: {
      tab,
      data
    }
  };
}

export function openTrackIndicatorForGoalsPanel(ind) {
  return {
    type: UPDATE_TRACK_STEP_FOR_GOAL_PANEL,
    result: {
      ind      
    }
  };
}

export function updateIssuesSequence(data) {
  return {types: GENERIC,
  promise: client => 
    client.post('/issues/update_issues_sequence', {
      data: data,
    })
  }
}

export function addSearchResult(data) {
  return {types: GENERIC,
  promise: client => 
    client.post('/issues/add_search_result_content_by_type', {
      data: data,
    })
  }
}

export function addSearchResultIssueTrack(data) {
  return {types: GENERIC,
  promise: client => 
    client.post('/issues/add_search_result_issue_track', {
      data: data,
    })
  }
}

export function addTrackFeedback(data) {
  return {types: GENERIC,
  promise: client => 
    client.post('/issues/add_track_feedback', {
      data: data,
    })
  }
}

export function addTracks(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/add_tracks', {
        data,
      }),
  };
}

export function addTracksAndIssue(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/add_tracks_and_issue', {
        data,
      }),
  };
}

export function updateTrackStatus(data) {
  return {types: GENERIC,
  promise: client => 
    client.post('/issues/update_track_status', {
      data: data,
    })
  }
}

export function addMenteeIssueGuiderVersion(data){
  return {
    types: GENERIC,
    promise: client =>
    client.post('/issues/add_mentee_issue_guider_version', {
      data: data,
     })
  }
}

export function addPNMenteeIssueGuiderVersion(data){
  return {
    types: GENERIC,
    promise: client =>
    client.post('/issues/add_pn_mentee_issue_guider_version', {
      data: data,
     })
  }
}

export function clearResponsesByNodeId(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/clear_responses_by_node_id', {
        data: data,
      }),
  };
}

export function acknowledgePrecallQuestion(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/acknowledge_precall_question', {
        data: data,
      }),
  };
}

export function updatePmIssueAction(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/update_pm_issue_action', {
        data: data,
      }),
  };
}

export function insertNodeNote(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/insert_issue_guider_node_note', {
        data: data,
      }),
  };
}

export function updateNodeNote(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/update_issue_guider_node_note', {
        data: data,
      }),
  };
}

export function insertRecipeNodeNote(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/insert_recipe_guider_node_note', {
        data: data,
      }),
  };
}

export function updateRecipeNodeNote(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/update_recipe_guider_node_note', {
        data: data,
      }),
  };
}

export function deleteRecipeNodeNote(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/delete_recipe_guider_node_note', {
        data: data,
      }),
  };
}

export function deleteIssueNodeNote(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/issues/delete_issue_guider_node_note', {
        data: data,
      }),
  };
}