const GENERIC_REQUEST = 'inquisit-app/VOICEMAIL_INBOX/GENERIC_REQUEST';
const GENERIC_SUCCESS = 'inquisit-app/VOICEMAIL_INBOX/GENERIC_SUCCESS';
const GENERIC_FAIL = 'inquisit-app/VOICEMAIL_INBOX/GENERIC_FAIL';

const SET_MODAL_AUDIO_PLAY_INFO = 'inquisit-app/VOICEMAIL_INBOX/SET_MODAL_AUDIO_PLAY_INFO';

const initialState = {
  currentModalAudioInfo: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MODAL_AUDIO_PLAY_INFO:
      return {currentModalAudioInfo: action.data};
    default:
      return state;
  }
}

export function getVoicemails(params) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/voicemail/get_voicemails', {
      params: params
    })
  };
}

export function getVoicemailUsersByGroup(params) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/voicemail/get_users_by_group', {
      params: params
    })
  };
}

export function addVoicemailNotes(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/voicemail/add_voicemail_notes', {
      data: data
    })
  };
}

export function reassignVoicemail(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/voicemail/reassign_voicemail', {
      data: data
    })
  };
}


export function markVoicemailRead(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/voicemail/mark_voicemail_read', {
      data: data
    })
  };
}

export function onVoicemailDismissed(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/voicemail/on_voicemail_dismissed', {
      data: data
    })
  };
}

export function setCurrentModalAudioInfo(value) {
  return {
    type: SET_MODAL_AUDIO_PLAY_INFO,
    data: value
  };
}