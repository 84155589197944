const CHALLENGE_LIST_SUCCESS = 'inquisit-app/mentoringProgram/CHALLENGE_LIST_SUCCESS';

const NODE_REQUESTED = 'inquisit-app/mentoringProgram/NODE_REQUESTED';
const NODE_SUCCESS = 'inquisit-app/mentoringProgram/NODE_SUCCESS';
const NODE_FAIL = 'inquisit-app/mentoringProgram/NODE_FAIL';

const TIMELINE_SUCCESS = 'inquisit-app/mentoringProgram/TIMELINE_SUCCESS';
const TIMELINE_FAIL = 'inquisit-app/mentoringProgram/TIMELINE_FAIL';
const PROGRAM_KEY_BEHAVIORS_SUCCESS = 'inquisit-app/mentoringProgram/PROGRAM_KEY_BEHAVIORS_SUCCESS';
const PROGRAM_UPDATE_STAGE_CHANGE_SUCCESS = 'inquisit-app/mentoringProgram/PROGRAM_UPDATE_STAGE_CHANGE_SUCCESS';
const PROGRAM_KEYBEHAVIOR_ORDER_SUCCESS = 'inquisit-app/mentoringProgram/PROGRAM_KEYBEHAVIOR_ORDER_SUCCESS';

const BASE_PATHWAY_UPSERT_SUCCESS = 'inquisit-app/mentoringProgram/BASE_PATHWAY_UPSERT_SUCCESS';
const GENERIC_PROGRAM_REQUESTED = 'inquisit-app/mentoringProgram/GENERIC_PROGRAM_REQUESTED';
const GENERIC_PROGRAM_SUCCESS = 'inquisit-app/mentoringProgram/GENERIC_PROGRAM_SUCCESS';
const GENERIC_PROGRAM_FAIL = 'inquisit-app/mentoringProgram/GENERIC_PROGRAM_FAIL';

const initialState = {
  nodes: [],
  timeline: {
    dates_to_show: [],
    timeline_data: []
  },
  keyBehaviors: [],
  challenges: [],
  foundation_challenges: [],
  challenges_properties: [],
  kb_notes: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NODE_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case NODE_SUCCESS:
      return {
        ...state,
        loading: false,
        nodes: action.result
      };
    case CHALLENGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        challenges: (action.result.challenges) ? action.result.challenges : [],
        foundation_challenges: (action.result.challenges) ? action.result.foundation_challenges : [],
        challenges_properties: (action.result.properties) ? action.result.properties : [],
        kb_notes: (action.result.kb_notes) ? action.result.kb_notes : null
      };
    case NODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case TIMELINE_SUCCESS:
      if (action.result) {
        return {
          ...state,
          loading: false,
          timeline: action.result
        };
      }
      return {
        ...state,
        loading: false
      };
    case TIMELINE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case PROGRAM_KEY_BEHAVIORS_SUCCESS:
      return {
        ...state,
        keyBehaviors: action.result,
        loading: false,
      };
    default:
      return state;
  }
}

export function getChallengesByRoot(data) {
  return {
    types: [NODE_REQUESTED, CHALLENGE_LIST_SUCCESS, NODE_FAIL],
    promise: (client) => client.get('/getmentoringprogramdata/challenges_by_root', {
      params: data
    })
  };
}

export function getWorkbookNodes(data) {
  return {
    types: [NODE_REQUESTED, NODE_SUCCESS, NODE_FAIL],
    promise: (client) => client.get('/getmentoringprogramdata/get_call_nodes_list', {
      params: {
        mentoring_program_base_id: data.mentoring_program_base_id,
      }
    })
  };
}


export function getMoreTimelineData(data) {
  return {
    types: [NODE_REQUESTED, TIMELINE_SUCCESS, TIMELINE_FAIL],
    promise: (client) => client.get('/getmentoringprogramdata/pull_more_timeline_data', {
      params: {
        mentoring_program_base_id: data.mentoring_program_base_id,
        admin_ind: 1,
        batch_num: data.batch_num,
        needs_refresh: data.needs_refresh,
        starting_point: data.starting_point,
        total_counts: data.total_counts
      }
    })
  };
}




export function getKeyBehaviors(data) {
  return {
    types: [NODE_REQUESTED, PROGRAM_KEY_BEHAVIORS_SUCCESS, NODE_FAIL],
    promise: (client) => client.get('/getmentoringprogramdata/key_behaviors', {
      params: {
        mentoring_program_node_id: data.mentoringProgramNodeId,
        mentoring_program_base_id: data.mentoringProgramBaseId,
        call_num: data.callNum,
      }
    })
  };
}

export function updateStageOfChange(data) {
  return {
    types: [NODE_REQUESTED, PROGRAM_UPDATE_STAGE_CHANGE_SUCCESS, NODE_FAIL],
    promise: (client) => client.post('/setmentoringprogramdata/update_stage_of_change', {
      data: {
        base_x_kb_xref_id: data.base_x_kb_xref_id,
        stages_of_change_id: data.stagesOfChangeId
      }
    })
  };
}

export function updateKeyBehaviorsOrder(data) {
  return {
    types: [NODE_REQUESTED, PROGRAM_KEYBEHAVIOR_ORDER_SUCCESS, NODE_FAIL],
    promise: (client) => client.post('/setmentoringprogramdata/update_key_behaviors_order', {
      data: {
        new_order_kb_xref: data,
      }
    })
  };
}

export function upsertBasePathwayFramework(data) {
  return {
    types: [NODE_REQUESTED, BASE_PATHWAY_UPSERT_SUCCESS, NODE_FAIL],
    promise: (client) => client.post('/setmentoringprogramdata/upsert_base_pathway', {
      data: data
    })
  };
}

export function createBoosterAndSetBoosterChallenges(data) {
  return {
    types: [NODE_REQUESTED, BASE_PATHWAY_UPSERT_SUCCESS, NODE_FAIL],
    promise: (client) => client.post('/setmentoringprogramdata/create_booster_and_set_booster_challenges', {
      data: data
    })
  };
}

export function removeBoosterMode(mpbId, boosterActivatedInd, type, typeId) {
  return {
    types: [NODE_REQUESTED, NODE_SUCCESS, NODE_FAIL],
    promise: (client) => client.post('/setmentoringprogramdata/remove_booster_mode', {
      data: {
        mentoring_program_base_id: mpbId,
        booster_activated_ind: boosterActivatedInd,
        user_type: type, 
        user_type_id: typeId
      }
    })
  };
}

export function moveMenteeToMentoringProgram(menteeId,transferReason) {
  return {
    types: [GENERIC_PROGRAM_REQUESTED, GENERIC_PROGRAM_SUCCESS, GENERIC_PROGRAM_FAIL],
    promise: (client) => client.post('/setmentoringprogramdata/move_mentee_to_mentoring_program', {
      data: {
        mentee_id: menteeId,
        transfer_reason: transferReason
      }
    })
  };
}

// Mentoring Program Summary
export function getMenteeWorkbookSummary(data) {
  return {
    types: [NODE_REQUESTED, BASE_PATHWAY_UPSERT_SUCCESS, NODE_FAIL],
    promise: (client) => client.get('/admin/getmentoringprogramsummary/mentee_workbook_summary', {
      data: data
    })
  };
}


