const ALARM_REQUEST_GENERIC = 'inquisit-app/ALARM/ALARM_REQUEST_GENERIC';
const ALARM_SUCCESS_GENERIC = 'inquisit-app/ALARM/ALARM_SUCCESS_GENERIC';
const ALARM_FAIL_GENERIC = 'inquisit-app/ALARM/ALARM_FAIL_GENERIC';

const ALARM_REQUEST_STATUS = 'inquisit-app/ALARM/ALARM_REQUEST_STATUS';
const ALARM_SUCCESS_STATUS = 'inquisit-app/ALARM/ALARM_SUCCESS_STATUS';
const ALARM_FAIL_STATUS = 'inquisit-app/ALARM/ALARM_FAIL_STATUS';

const ALARM_REQUEST_ORGS = 'inquisit-app/ALARM/ALARM_REQUEST_ORGS';
const ALARM_SUCCESS_ORGS = 'inquisit-app/ALARM/ALARM_SUCCESS_ORGS';
const ALARM_FAIL_ORGS = 'inquisit-app/ALARM/ALARM_FAIL_ORGS';

const ALARM_REQUEST_ACTION = 'inquisit-app/ALARM/ALARM_REQUEST_ACTION';
const ALARM_SUCCESS_ACTION = 'inquisit-app/ALARM/ALARM_SUCCESS_ACTION';
const ALARM_FAIL_ACTION = 'inquisit-app/ALARM/ALARM_FAIL_ACTION';

const ALARM_REQUEST_EMAIL = 'inquisit-app/ALARM/ALARM_REQUEST_EMAIL';
const ALARM_SUCCESS_EMAIL = 'inquisit-app/ALARM/ALARM_SUCCESS_EMAIL';
const ALARM_FAIL_EMAIL = 'inquisit-app/ALARM/ALARM_FAIL_EMAIL';

const ALARM_REQUEST_TICKET_TYPE = 'inquisit-app/ALARM/ALARM_REQUEST_TICKET_TYPE';
const ALARM_SUCCESS_TICKET_TYPE = 'inquisit-app/ALARM/ALARM_SUCCESS_TICKET_TYPE';
const ALARM_FAIL_TICKET_TYPE = 'inquisit-app/ALARM/ALARM_FAIL_TICKET_TYPE';

const ALARM_REQUEST_TRIGGER_OPTIONS = 'inquisit-app/ALARM/ALARM_REQUEST_TRIGGER_OPTIONS';
const ALARM_SUCCESS_TRIGGER_OPTIONS = 'inquisit-app/ALARM/ALARM_SUCCESS_TRIGGER_OPTIONS';
const ALARM_FAIL_TRIGGER_OPTIONS = 'inquisit-app/ALARM/ALARM_FAIL_TRIGGER_OPTIONS';

const GENERIC =  [ALARM_REQUEST_GENERIC, ALARM_SUCCESS_GENERIC, ALARM_FAIL_GENERIC];

const initialState = {
  alarmStatusOptions: [],
  alarmOrgOptions: [],
  alarmActionOptions: [],
  alarmEmailList: [],
  alarmTicketTypeList: [],
  actionTriggerOptions: [],
//   triggerComparisonOptions: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
  	case ALARM_SUCCESS_STATUS:
      return {
        ...state,
        alarmStatusOptions: action.result ? action.result : []
      };
    case ALARM_SUCCESS_ORGS:
      return {
        ...state,
        alarmOrgOptions: action.result ? action.result : []
      };
    case ALARM_SUCCESS_ACTION:
    	return {
    		...state,
    		alarmActionOptions: action.result ? action.result : []
    	}
    case ALARM_SUCCESS_EMAIL:
    	return {
    		...state,
    		alarmEmailList: action.result ? action.result : []
    	}
    case ALARM_SUCCESS_TICKET_TYPE:
    	return {
    		...state,
    		alarmTicketTypeList: action.result && action.result.success ? action.result.types : []
    	}
    case ALARM_SUCCESS_TRIGGER_OPTIONS:
    	return {
    		...state,
    		actionTriggerOptions: action.result && action.result.action_trigger_options ? action.result.action_trigger_options : [],
    		// triggerComparisonOptions: action.result && action.result.trigger_comparison_options ? action.result.trigger_comparison_options : []
    	}
    default:
      return state;
  }
}

  // ****************************
  // ******* GET Requests *******
  // ****************************
export function getAllAlarmsList(data) {
	return {
		types: GENERIC,
		promise: (client) => client.get('alarm/get_all_alarms_list', {
			params: data
		})
	};
}

export function getAlarmStatusOptions(){
	return {
		types:[ALARM_REQUEST_STATUS, ALARM_SUCCESS_STATUS, ALARM_FAIL_STATUS],
		promise: (client) => client.get('alarm/get_alarm_status_list')
	};
}

export function getAlarmActionOptions(){
	return {
		types:[ALARM_REQUEST_ACTION, ALARM_SUCCESS_ACTION, ALARM_FAIL_ACTION],
		promise: (client) => client.get('alarm/get_alarm_action_list')
	};
}

export function getOrgsByRoots(params) {
  return {
		types: [ALARM_REQUEST_ORGS, ALARM_SUCCESS_ORGS, ALARM_FAIL_ORGS],
		promise: (client) => client.get('alarm/get_alarm_org_list', {
			params: params,
		})
	};
}

export function getAlarmEmailList(params) {
  return {
		types: [ALARM_REQUEST_EMAIL, ALARM_SUCCESS_EMAIL, ALARM_FAIL_EMAIL],
		promise: (client) => client.get('alarm/get_alarm_email_list', {
			params: params,
		})
	};
}

export function getAlarmTicketTypeList(params) {
  return {
		types: [ALARM_REQUEST_TICKET_TYPE, ALARM_SUCCESS_TICKET_TYPE, ALARM_FAIL_TICKET_TYPE],
		promise: (client) => client.get('alarm/get_eligible_cc_ticket_types', {
			params: params,
		})
	};
}

export function getFullTriggerOptions() {
  return {
		types: [ALARM_REQUEST_TRIGGER_OPTIONS, ALARM_SUCCESS_TRIGGER_OPTIONS, ALARM_FAIL_TRIGGER_OPTIONS],
		promise: (client) => client.get('alarm/full_trigger_options', {})
	};
}


  // ***************************
  // ****** POST Requests ******
  // ***************************

export function createAlarm(data){
	return {
		types: GENERIC,
		promise: (client) => client.post('alarm/create_alarm',{
			data: { ...data, et_ind: 1 }
		})
	};
}

export function updateAlarm(data){
	return {
		types: GENERIC,
		promise: (client) => client.post('alarm/update_alarm',{
			data: {...data, et_ind: 1}
		})
	};
}

export function updateAlarmStatus(data){
	return {
		types: GENERIC,
		promise: (client) => client.post('alarm/update_alarm_status',{
			data: { ...data, et_ind: 1 }
		})
	};
}