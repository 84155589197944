const UPDATE_CALL_SETTINGS_VISIBILITY = "nav/UPDATE_CALL_SETTINGS_VISIBILITY"; 

const initialState = {
  showCallSettingsModal: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_CALL_SETTINGS_VISIBILITY:
      return {
        ...state,
        showCallSettingsModal: action.data
      };
    default:
      return state;
  }
}

// Get
export function toggleCallSettingsModal(data) {
  return {
    type: UPDATE_CALL_SETTINGS_VISIBILITY,
    data: data
  };
}
