const GENERIC_REQUEST = 'inquisit-app/PN_CALL_SCHEDULING/GENERIC_REQUEST';
const GENERIC_FAIL = 'inquisit-app/PN_CALL_SCHEDULING/GENERIC_FAIL';
const GENERIC_SUCCESS = 'inquisit-app/PN_CALL_SCHEDULING/GENERIC_SUCCESS';
const SCHEDULED_CALL_LOAD = 'inquisit-app/PN_CALL_SCHEDULING/SCHEDULED_CALL_LOAD';
const SCHEDULED_CALL_SUCCESS = 'inquisit-app/PN_CALL_SCHEDULING/SCHEDULED_CALL_SUCCESS';
const SCHEDULED_CALL_FAIL = 'inquisit-app/PN_CALL_SCHEDULING/SCHEDULED_CALL_FAIL';

const GENERIC = [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL];

const initialState = {
  loading: true,
  scheduledCall: {}
};

export default function reducer(state = initialState, action = {}) {
  switch(action.type) {
    case SCHEDULED_CALL_SUCCESS: {
      let sc = {};
      sc[action.result.id] = action.result;
      return {
        ...state,
        scheduledCall: sc,
        loading: false
      };
    }
    case SCHEDULED_CALL_LOAD:
    case SCHEDULED_CALL_FAIL:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}

export function getPNScheduledCallInfo(params) {
  return {
    types: [SCHEDULED_CALL_LOAD, SCHEDULED_CALL_SUCCESS, SCHEDULED_CALL_FAIL],
    promise: (client) => client.get('/peer_navigator/get_pn_scheduled_call_info', {
      params: params
    }),
  };
}

export function getPNMenteeSchedulingAvailability(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_pn_mentee_scheduling_availability', {
      params: params
    }),
  };
}

export function getPNMenteeGeneralAvailability(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_pn_mentee_general_availability', {
      params: params
    }),
  };
}

export function getPeerNavigatorAvailableTimesByDate(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/peer_navigator/get_pn_available_times_by_date', {
      params: data
    })
  };
}

export function getPeerNavigatorSuggestedAvailabilityTimesByDate(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/peer_navigator/get_pn_suggested_availability_times_by_date', {
      params: data
    })
  };
}


export function schedulePNMenteeCall(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/peer_navigator/schedule_pn_mentee_call', {
      data: data
    }),
  };
}

export function cancelPNMenteeCall(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/peer_navigator/cancel_pn_mentee_call', {
      data: data
    }),
  };
}

export function getPinnedCallEnableInd(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_pinned_call_enable_ind', {
      params: data
    }),
  };
}