const GENERIC_REQUEST = 'inquisit-app/common/GENERIC_REQUEST';
const GENERIC_SUCCESS = 'inquisit-app/common/GENERIC_SUCCESS';
const GENERIC_FAIL = 'inquisit-app/common/GENERIC_FAIL';
const SET_DYNAMIC_LIST_DATA = 'inquisit-app/common/SET_DYNAMIC_LIST_DATA';


const TOGGLE_ALERTS_LIST_HEIGHT = 'TOGGLE_ALERTS_LIST_HEIGHT'; 


const initialState = {
  apiEndpointFn: null,
  params: null,
  title: "",
  type: "",
  filters: [],
  isTodoListExpanded: false
}

export default function reducer(state = initialState, action = {}) {
  switch(action.type) {
    case TOGGLE_ALERTS_LIST_HEIGHT: 
      return {
        ...state,
        isTodoListExpanded: action.data
      };
    case SET_DYNAMIC_LIST_DATA:
      return {
        ...state,
        ...action.data
      }
    default:
      return state;
  }
}

export function getCallSettings(userTypeGroup, userTypeId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/getcommondata/call_settings', {
      params: {
        user_type_group: userTypeGroup, 
        user_type_id: userTypeId
      }
    })
  };
}

export function getTwilioCallLog(source, phoneNum) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/admin/general/get_twilio_call_log', {
      params: {
        source,
        phone_num: phoneNum
      }
    })
  };
}

export function getOrgSubEntities(params) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/getcommondata/get_org_subentities', {
      params: params
    })
  };
}

export function setDynamicListData(apiEndpointFn, params, title, type, filters=[]) {
  return {
    type: SET_DYNAMIC_LIST_DATA,
    data: {
      apiEndpointFn: apiEndpointFn,
      params: params,
      title: title,
      type: type,
      filters: filters
    }
  };
}

export function updateCallSettings(fieldName, fieldValue) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/setcommondata/update_call_settings', {
      data: {
        field_name: fieldName,
        field_value: fieldValue,
        et_ind: 1
      }
    })
  };
}

export function toggleAlertsTodoList(data) {
  return {
    type: TOGGLE_ALERTS_LIST_HEIGHT, 
    data: data
  }  
}