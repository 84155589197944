const OUTREACH_LOAD_REQUESTED = 'inquisit-app/menteeOutreach/OUTREACH_LOAD_REQUESTED';
const OUTREACH_SCRIPT_LOAD_REQUESTED = 'inquisit-app/menteeOutreach/OUTREACH_SCRIPT_LOAD_REQUESTED';
const OUTREACH_FAIL = 'inquisit-app/menteeOutreach/OUTREACH_FAIL';
const OUTREACH_REQUEST_GENERIC = 'inquisit-app/menteeOutreach/OUTREACH_REQUEST_GENERIC';
const OUTREACH_SUCCESS_GENERIC = 'inquisit-app/menteeOutreach/OUTREACH_SUCCESS_GENERIC';
const OUTREACH_FAIL_GENERIC = 'inquisit-app/menteeOutreach/OUTREACH_FAIL_GENERIC';
const OUTREACH_LIST_LOAD_SUCCESS = 'inquisit-app/outreach/OUTREACH_LIST_LOAD_SUCCESS';
const OUTREACH_RESET_MENTEES_SUCCESS = 'inquisit-app/outreach/OUTREACH_RESET_MENTEES_SUCCESS';
const OUTREACH_TARGET_DETAILS_SUCCESS = 'inquisit-app/menteeOutreach/OUTREACH_TARGET_DETAILS_SUCCESS';
const OUTREACH_SCRIPT_SUCCESS = 'inquisit-app/menteeOutreach/OUTREACH_SCRIPT_SUCCESS';
const OUTREACH_TARGET_CALL_TX_STATUS_LIST_SUCCESS = 'inquisit-app/menteeOutreach/OUTREACH_TARGET_CALL_TX_STATUS_LIST_SUCCESS';
const OUTREACH_TARGET_CALL_TX_SUBSTATUS_LIST_SUCCESS = 'inquisit-app/menteeOutreach/OUTREACH_TARGET_CALL_TX_SUBSTATUS_LIST_SUCCESS';
const OUTREACH_TARGET_RECENT_ATTEMPTS_SUCCESS = 'inquisit-app/menteeOutreach/OUTREACH_TARGET_RECENT_ATTEMPTS_SUCCESS';
const OUTREACH_FOLLOW_UP_REMINDER_SUCCESS = 'inquisit-app/menteeOutreach/OUTREACH_FOLLOW_UP_REMINDER_SUCCESS';
const OUTREACH_LANGUAGE_LIST_SUCCESS = 'inquisit-app/menteeOutreach/OUTREACH_LANGUAGE_LIST_SUCCESS';
const OUTREACH_PROGRAM_SELECTION_SUCCESS = 'inquisit-app/menteeOutreach/OUTREACH_PROGRAM_SELECTION_SUCCESS';
const OUTREACH_TARGET_COMMUNICATION_UPDATE_SUCCESS = 'inquisit-app/menteeOutreach/OUTREACH_TARGET_COMMUNICATION_UPDATE_SUCCESS';
const ADD_PROFILE_TAB = 'inquisit-app/outreach/ADD_PROFILE_TAB';
const REMOVE_PROFILE_TAB = 'inquisit-app/outreach/REMOVE_PROFILE_TAB';
const OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_REQUESTED = 'inquisit-app/outreach/OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_REQUESTED';
const OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_SUCCESS = 'inquisit-app/outreach/OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_SUCCESS';
const OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_FAILED = 'inquisit-app/outreach/OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_FAILED';
const OUTREACH_PRIORITIZED_LIST_LOAD_REQUESTED = 'inquisit-app/outreach/OUTREACH_PRIORITIZED_LIST_LOAD_REQUESTED';
const OUTREACH_PRIORITIZED_MENTEES_LIST_LOAD_SUCCESS = 'inquisit-app/outreach/OUTREACH_PRIORITIZED_MENTEES_LIST_LOAD_SUCCESS';
const OUTREACH_PRIORITIZED_FAIL = 'inquisit-app/outreach/OUTREACH_PRIORITIZED_FAIL';
const OUTREACH_PEERSCHEDULED_LIST_LOAD_REQUESTED = 'inquisit-app/outreach/OUTREACH_PEERSCHEDULED_LIST_LOAD_REQUESTED';
const OUTREACH_PEERSCHEDULED_MENTEES_LIST_LOAD_SUCCESS = 'inquisit-app/outreach/OUTREACH_PEERSCHEDULED_MENTEES_LIST_LOAD_SUCCESS';
const OUTREACH_PEERSCHEDULED_FAIL = 'inquisit-app/outreach/OUTREACH_PEERSCHEDULED_FAIL';
const OUTREACH_LIST_STATS_LOAD_REQUESTED = 'inquisit-app/outreach/OUTREACH_LIST_STATS_LOAD_REQUESTED';
const OUTREACH_LIST_STATS_LOAD_SUCCESS = 'inquisit-app/outreach/OUTREACH_LIST_STATS_LOAD_SUCCESS';
const OUTREACH_LIST_STATS_LOAD_FAILED = 'inquisit-app/outreach/OUTREACH_LIST_STATS_LOAD_FAILED';
const OUTREACH_LIST_LOAD_REQUESTED = 'inquisit-app/outreach/OUTREACH_LIST_LOAD_REQUESTED';




const initialState = {
  loading: false,
  targetProfiles: {},
  targetDetail: {
    additional_details: [],
    core_details: {}
  },
  //Below states are new added from outreach redux//
  mentees: [],
  archivedMentees: [],
  prioritizedMentees: [],
  peerscheduledMentees: [],
  archivedLoading: false,
  archivedBaseHeaders: null,
  loadingPrioritized: false,
  loadingPeerscheduled: false,
  baseHeaders: null,
  //Above are new states
  outreachTargetCallTxStatusList: [],
  outreachTargetCallTxStatusListRaw: [],
  scheduleFollowup: null,
  languageList: [],
  talkingPointsLoading: true,
  openProfileTabs: {},
  outreachListStatsLoading: false,
  outreachListStats: {
    fullList: 0,
    archiveList: 0,
    peerSchedulerList: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OUTREACH_LOAD_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case OUTREACH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case OUTREACH_TARGET_DETAILS_SUCCESS:
      state.targetProfiles[action.result.core_details.id] = action.result;
      return {
        ...state,
        targetDetail: action.result,
        loading: false,
      };
    case OUTREACH_TARGET_CALL_TX_STATUS_LIST_SUCCESS:
      return {
        ...state,
        outreachTargetCallTxStatusList: (action.result.outreach_target_call_tx_statuses) ? action.result.outreach_target_call_tx_statuses : [],
        outreachTargetCallTxStatusListRaw: (action.result.outreach_target_call_tx_statuses_raw) ? action.result.outreach_target_call_tx_statuses_raw : [],
      }
    case OUTREACH_TARGET_CALL_TX_SUBSTATUS_LIST_SUCCESS:
      return {
        ...state,
        outreachTargetCallTxSubstatusList: action.result
      }
    case OUTREACH_SCRIPT_LOAD_REQUESTED:
      return {
        ...state,
        talkingPointsLoading: true
      };
    case OUTREACH_SCRIPT_SUCCESS:
      return {
        ...state,
        talkingPoints: action.result.script,
        talkingPointsLoading: false
      };
    case OUTREACH_FOLLOW_UP_REMINDER_SUCCESS:
      return {
        ...state,
        scheduleFollowup: (action.result ? action.result : null)
      };
    case OUTREACH_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        languageList: (action.result) ? action.result : []
      }
    case OUTREACH_PROGRAM_SELECTION_SUCCESS:
      return {
        ...state,
        targetDetail: { ...state.targetDetail, core_details: { ...state.targetDetail.core_details, engaged_program_id: action.result } }
      }
    case OUTREACH_TARGET_COMMUNICATION_UPDATE_SUCCESS:
      return {
        ...state,
        targetDetail: { ...state.targetDetail, core_details: { ...state.targetDetail.core_details, new_alerts: state.targetDetail.core_details.new_alerts - 1 } }
      }
    case OUTREACH_SUCCESS_GENERIC:
      return state;

    case ADD_PROFILE_TAB:
      if (!state.openProfileTabs) state.openProfileTabs = {};
      state.openProfileTabs["profile_" + action.payload.id + '_' + action.payload.org_id] = action.payload;
      return {
        ...state,
      };
    case REMOVE_PROFILE_TAB:
      delete state.openProfileTabs["profile_" + action.payload];
      return {
        ...state,
      };
    //Below are the new cases added from outreach redux//
    case OUTREACH_RESET_MENTEES_SUCCESS:
      return {
        ...state,
        mentees: [],
        archivedMentees: [],
        prioritizedMentees: [],
      };
    case OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_REQUESTED:
      return {
        ...state,
        archivedLoading: true,
        archivedMentees: [],
      };
    case OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_SUCCESS:
      return {
        ...state,
        archivedLoading: false,
        archivedMentees: action.result.outreach_target_list ? action.result.outreach_target_list : [],
        archivedBaseHeaders: action.result.base_headers,
      };
    case OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_FAILED:
      return {
        ...state,
        archivedLoading: false,
        error: action.error,
        archivedMentees: []
      };
    case OUTREACH_PRIORITIZED_LIST_LOAD_REQUESTED:
      return {
        ...state,
        loadingPrioritized: true,
        prioritizedMentees: [],
      };
    case OUTREACH_PRIORITIZED_MENTEES_LIST_LOAD_SUCCESS:
      return {
        ...state,
        loadingPrioritized: false,
        prioritizedMentees: action.result.outreach_target_list ? action.result.outreach_target_list : [],
        baseHeaders: action.result.base_headers,
      };
    case OUTREACH_PRIORITIZED_FAIL:
      return {
        ...state,
        loadingPrioritized: false,
        error: action.error,
        prioritizedMentees: []
      };

    case OUTREACH_PEERSCHEDULED_LIST_LOAD_REQUESTED:
      return {
        ...state,
        loadingPeerscheduled: true,
        peerscheduledMentees: [],
      };
    case OUTREACH_PEERSCHEDULED_MENTEES_LIST_LOAD_SUCCESS:
      return {
        ...state,
        loadingPeerscheduled: false,
        peerscheduledMentees: action.result.outreach_target_list ? action.result.outreach_target_list : [],
        baseHeaders: action.result.base_headers,
      };
    case OUTREACH_PEERSCHEDULED_FAIL:
      return {
        ...state,
        loadingPeerscheduled: false,
        error: action.error,
        peerscheduledMentees: []
      };

    case OUTREACH_LIST_LOAD_REQUESTED:
      return {
        ...state,
        loading: true,
        mentees: [],
      };
    case OUTREACH_LIST_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        mentees: action.result.outreach_target_list ? action.result.outreach_target_list : [],
        baseHeaders: action.result.base_headers,
      };
    case OUTREACH_LIST_STATS_LOAD_REQUESTED:
      return {
        ...state,
        outreachListStatsLoading: true,
        outreachListStats: {
          fullList: 0,
          archiveList: 0,
          peerSchedulerList: 0,
        },    
      };
    case OUTREACH_LIST_STATS_LOAD_SUCCESS:
      return {
        ...state,
        outreachListStatsLoading: false,
        outreachListStats: {
          fullList: action.result.outreach_count,
          archiveList: action.result.archived_outreach_count,
          peerSchedulerList: action.result.peer_scheduler_count,
        },    
      };
    case OUTREACH_LIST_STATS_LOAD_FAILED:
      return {
        ...state,
        outreachListStatsLoading: false,
      };

    default:
      return state;
  }
}

/*
 * Get
 */
export function getOutreachTargetDetails(data) {
  const CUSTOM_TYPE = data.disableLoading ? OUTREACH_REQUEST_GENERIC : OUTREACH_LOAD_REQUESTED;
  return {
    types: [CUSTOM_TYPE, OUTREACH_TARGET_DETAILS_SUCCESS, OUTREACH_FAIL],
    promise: (client) => client.get('/getoutreach/outreach_target_details', {
      params: {
        outreach_target_id: data.target_id,
        org_id: data.org_id
      }
    })
  };
}

export function getOutreachScript(data) {
  return {
    types: [OUTREACH_SCRIPT_LOAD_REQUESTED, OUTREACH_SCRIPT_SUCCESS, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/outreach_script', {
      params: {
        outreach_target_id: data.target_id,
        org_id: data.orgId,
        oc_name: data.oc_name
      }
    })
  };
}

export function getOutreachTargetCallTxStatuses(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_TARGET_CALL_TX_STATUS_LIST_SUCCESS, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/get_outreach_target_call_tx_statuses', {
      params: {
        outreach_type: data.outreach_type,
        org_id: data.org_id
      }
    })
  };
}

export function getOutreachTargetCallTxSubstatuses(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_TARGET_CALL_TX_SUBSTATUS_LIST_SUCCESS, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/get_outreach_target_call_tx_substatuses', {
      params: {
        root_id: data.root_id,
        outreach_type: data.outreach_type,
        outreach_target_id: data.outreach_target_id,
        org_id: data.org_id
      }
    })
  };
}

export function getOutreachTargetTags(outreachTargetId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/get_outreach_target_tags', {
      params: {
        outreach_target_id: outreachTargetId
      }
    })
  };
}

export function getOutreachFollowUpReminder(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_FOLLOW_UP_REMINDER_SUCCESS, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/followup_call_reminder_by_target_id', {
      params: {
        outreach_target_id: data.target_id
      }
    })
  };
}

export function getOutreachTargetRecentAttempts(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/outreach_target_recent_attempts', {
      params: {
        outreach_target_id: data.target_id
      }
    })
  };
}

export function getOutreachProspectHistoryTimeline(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: client =>
      client.get('/getoutreach/get_outreach_prospect_history_timeline', {
        params: data
      }),
  };
}

export function getOutreachTargetCommunicationData(outreachTargetId, type) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_communication_data', {
        params: {
          outreach_target_id: outreachTargetId,
          type: type
        },
      }),
  };
}

export function getMenteeTargetAccountLockStatus(outreachTargetId, orgId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/outreach_target_account_lock_status', {
      params: {
        outreach_target_id: outreachTargetId,
        org_id: orgId,
      }
    })
  };
}

export function checkMenteeAlreadyEnrolled(menteeId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/outreach_target_enrolled_status', {
      params: {
        mentee_id: menteeId,
      }
    })
  };
}

export function getVoicemailsFromOutreachTarget(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/get_voicemails_from_outreach_target', {
      params: {
        outreach_target_id: data.target_id,
      }
    })
  };
}

export function getCallAttemptsChartBreakdownData(outreachTargetId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/get_outreach_target_attempts_breakdown_chart_data', {
      params: {
        outreach_target_id: outreachTargetId,
      }
    })
  };
}

export function getNotionOutreachScript(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getoutreach/outreach_notion_script', {
      params: data
    })
  };
}

export function getLanguageFieldValues() {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_LANGUAGE_LIST_SUCCESS, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.get('/getenrolldata/get_language_field_values')
  }
}

/*
 * Set
 */

export function upsertOutreachTargetProfileVisitHistory(outreachTargetId, org = null) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/upsert_outreach_target_profile_visit_history', {
      data: {
        outreach_target_id: outreachTargetId,
        org: org,
      }
    })
  };
}

export function addUpdateProspectProgram(outreachTargetId, programId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/add_update_prospect_program', {
      data: {
        outreach_target_id: outreachTargetId,
        program_id: programId
      }
    })
  };
}

export function addTargetTag(targetId, tag) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/add_target_x_tag', {
      data: {
        outreach_target_id: targetId,
        tag: tag
      }
    })
  }
}

export function deleteTargetTag(id) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/delete_target_x_tag', {
      data: {
        id: id
      }
    })
  }
}

export function updateGeneralAvailability(outreachTargetId, appointmentId, datetime, endtime, days) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/update_general_availability', {
      data: {
        outreach_target_id: outreachTargetId,
        appointment_id: appointmentId,
        datetime: datetime,
        endtime: endtime,
        days: days
      }
    })
  }
}

export function setOutreachMenteesPhoneNumbers(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/add_edit_phone_numbers', {
      data: data
    })
  };
}

export function archiveOutreachTarget(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/archive_outreach_target', {
      data: data
    })
  };
}

export function updateOutreachTransaction(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/update_outreach_tx_by_id', {
      data: data
    })
  };
}

export function updateMenteeOutreachTarget(outreachTargetId, fields, orgId = null) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/update_outreach_target', {
      data: {
        outreach_target_id: outreachTargetId,
        fields: fields,
        org_id: orgId,
      }
    })
  };
}

export function updateOutreachTargetVoicemailStatus(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/update_outreach_target_voicemail_status', {
      data: data
    })
  };
}

export function updateOutreachTargetCommunicationDataStatus(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_TARGET_COMMUNICATION_UPDATE_SUCCESS, OUTREACH_FAIL_GENERIC],
    promise: client =>
      client.post('/setoutreach/update_outreach_communication_data_status', {
        data: data,
      }),
  };
}

export function postSlackEmergencyChannelMessage(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL_GENERIC],
    promise: (client) => client.post('/setoutreach/post_slack_emergency_message', {
      data: data
    })
  };
}


/*
 * Local redux functions
 */

export function resetRecentAttempts() {
  return {
    type: OUTREACH_TARGET_RECENT_ATTEMPTS_SUCCESS,
    result: []
  };
}

export function updateSelectedProgram(programId) {
  return {
    type: OUTREACH_PROGRAM_SELECTION_SUCCESS,
    result: programId
  };
}

export function addProfileTab(data) {
  return {
    type: ADD_PROFILE_TAB,
    payload: data
  }
}

export function removeProfileTab(data) {
  return {
    type: REMOVE_PROFILE_TAB,
    payload: data

  }
}

//Below are the functions moved from outreach//

export function saveMenteeOutreachProspect(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/add_mentee_outreach_prospect', {
        data: data,
      }),
  };
}

export function removeMenteeEnrollmentNote(outreachTargetId) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_target_enrollment_note', {
        data: {
          outreach_target_id: outreachTargetId,
        },
      }),
  };
}

export function setAddressVerified(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: (client) => client.post('/setoutreach/set_address_verified', {
      params: data
    })
  };
}

export function resetMenteeOutreachList() {
  return {
    type: OUTREACH_RESET_MENTEES_SUCCESS,
    result: false,
  };
}

export function getArchivedOutreachMenteeList(data) {
  return {
    types: [
      OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_REQUESTED,
      OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_SUCCESS,
      OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_FAILED,
    ],
    promise: client =>
      client.get(':9000/getoutreach/archived_list', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
          outreach_list_type_id: data.outreach_list_type_id
        },
      }),
  };
}

export function getOutreachMenteePrioritizedCallList(data) {
  return {
    types: [
      OUTREACH_PRIORITIZED_LIST_LOAD_REQUESTED,
      OUTREACH_PRIORITIZED_MENTEES_LIST_LOAD_SUCCESS,
      OUTREACH_PRIORITIZED_FAIL,
    ],
    promise: client =>
      client.get(':9000/getoutreach/list', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
          root_id: data.root_id,
          prioritized_ind: 1,
          type_group: data.type_group,
          type_id: data.type_id,
          page: data.page || 1,
          owner_id: data.owner_id,
          languages: data.languages,
          outreach_list_type_id: data.outreach_list_type_id
        },
      }),
  };
}

export function getOutreachMenteePeerScheduledCallList(data) {
  return {
    types: [
      OUTREACH_PEERSCHEDULED_LIST_LOAD_REQUESTED,
      OUTREACH_PEERSCHEDULED_MENTEES_LIST_LOAD_SUCCESS,
      OUTREACH_PEERSCHEDULED_FAIL,
    ],
    promise: client =>
      client.get(':9000/getoutreach/list', {
        params: {
          _st: 'Inquisit14',
          associated_orgs: data.associated_orgs,
          prioritized_ind: 2,
          page: data.page || 1,
          owner_id: data.owner_id,
          outreach_list_type_id: data.outreach_list_type_id
        },
      }),
  };
}

//Below function has been moved
export function getOutreachMenteeListStats(data) {
  return {
    types: [OUTREACH_LIST_STATS_LOAD_REQUESTED, OUTREACH_LIST_STATS_LOAD_SUCCESS, OUTREACH_LIST_STATS_LOAD_FAILED],
    promise: client =>
      client.get(':9000/getoutreach/list_stats', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
          root_id: data.root_id,
          type_group: data.type_group,
          type_id: data.type_id,
          associated_orgs: data.associated_orgs,
          outreach_list_type_id: data.outreach_list_type_id
        },
      }),
  };
}

export function getOutreachMenteeList(data) {
  return {
    types: [
      OUTREACH_LIST_LOAD_REQUESTED,
      OUTREACH_LIST_LOAD_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get(':9000/getoutreach/list', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
          root_id: data.root_id,
          type_group: data.type_group,
          type_id: data.type_id,
          prioritized_ind: 0,
          page: data.page || 1,
          outreach_list_type_id: data.outreach_list_type_id
        },
      }),
  };
}

export function getOutreachSearchResult(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get(':9000/getoutreach/search', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
          search_term: data.searchTerm,
        },
      }),
  };
}
export function getEnrollmentProgress(menteeMasterId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get('/enroll/get_enrollment_progress', {
        params: {
          mentee_master_id: menteeMasterId
        },
      }),
  };
}

export function deleteOutreachTargetNote(id) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.post('/outreach/notepad/delete_outreach_target_note', {
        params: {
          id: id
        },
      }),
  };
}
export function submitOutreachTargetNotes(targetId, note, org = null) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.post('/outreach/notepad/submit_outreach_target_notes', {
        data: {
          outreach_target_id: targetId,
          note: note,
          org: org
        },
      }),
  };
}
export function updateOutreachTargetNote(id, updatedNote) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.post('/outreach/notepad/update_outreach_target_note', {
        params: {
          id: id,
          note: updatedNote
        },
      }),
  };
}
export function getOutreachTargetNotes(targetId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get('/outreach/notepad/get_outreach_target_notes', {
        params: {
          outreach_target_id: targetId,
        },
      }),
  };
}

