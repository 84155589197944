const GENERIC = 'inquisit-app/auth/GENERIC';
const LOAD = 'inquisit-app/auth/LOAD';
const LOAD_SUCCESS = 'inquisit-app/auth/LOAD_SUCCESS';
const LOAD_FAIL = 'inquisit-app/auth/LOAD_FAIL';

const LOGIN = 'inquisit-app/auth/LOGIN';
const LOGIN_SUCCESS = 'inquisit-app/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'inquisit-app/auth/LOGIN_FAIL';

const ROlE_SWITCH_SUCCESS = 'pyx-app/auth/ROLE_SWITCH_SUCCESS';

const LOGOUT = 'inquisit-app/auth/LOGOUT';
const LOGOUT_SUCCESS = 'inquisit-app/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'inquisit-app/auth/LOGOUT_FAIL';
const LOGIN_MESSAGE = 'inquisit-app/auth/LOGIN_MESSAGE';

const UPDATE_ROOT = 'inquisit-app/auth/UPDATE_ROOT';
const UPDATE_ORG = 'inquisit-app/auth/UPDATE_ORG';
const UPDATE_ORG_AND_ROOT = 'inquisit-app/auth/UPDATE_ORG_AND_ROOT';

const UPDATE_ORG_REDIRECT = 'inquisit-app/auth/UPDATE_ORG_REDIRECT';

const FLASH_NOTIFICATION = 'inquisit-app/auth/FLASH_NOTIFICATION';
const UPDATE_TOKEN_AND_LOGIN_HASH = 'inquisit-app/auth/UPDATE_TOKEN_AND_LOGIN_HASH';
const UPDATE_USER = 'inquisit-app/auth/UPDATE_USER';
const CHANGE_USER = 'inquisit-app/auth/CHANGE_USER';
const SET_TOKEN_AND_HASH= 'inquisit-app/auth/SET_TOKEN_AND_HASH';
const REVERT_ROOT= 'inquisit-app/auth/REVERT_ROOT';

const LOAD_MENU = 'inquisit-app/auth/LOAD_MENU';
const LOAD_MENU_SUCCESS = 'inquisit-app/auth/LOAD_MENU_SUCCESS';
const LOAD_MENU_FAIL = 'inquisit-app/auth/LOAD_MENU_FAIL';

const SET_TRACKER = 'inquisit-app/auth/SET_TRACKER';

const initialState = {
  loading: true,
  loaded: false,
  message: false,
  messageClickFunction: null,
  selectedRoot: null,
  previouslySelectedRoot: null,
  selectedOrg: null,
  selectedOrgObj: null,
  user: {},
  loginActive: 0,
  menuLoading: true,
  menu: [],
  tracker: null,
  trackerAuthToken: null
};


export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS: {
      
      if (action.result.error) {
        return {
          ...state,
          loading: false,
          loggingIn: false,
          loginError: action.result.error,
          errorMessage: action.result.message,
          user: null,
          selectedRoot: null,
          previouslySelectedRoot: null,
          selectedOrg: null,
          selectedType: null
        };
      }
      
      let selectedRoot = null;
      let selectedOrg = null;
      let selectedType = null;

      if(action.result.user_info.role !== 'senior_mentor') {
        if (state.selectedRoot) {
          selectedRoot = state.selectedRoot;
          if (!action.result.error) {
            if (action.result.user_info.associated_roots.length > 0) {
              for(let i = 0; i < action.result.user_info.associated_roots.length; i++) {
                if (selectedRoot !== action.result.user_info.associated_roots[i].id) {
                  selectedRoot = action.result.user_info.associated_roots[0].id;
                }
              }
            }
          }
        } else {
          selectedRoot = !action.result.error && action.result.user_info.associated_roots && action.result.user_info.associated_roots[0] ? action.result.user_info.associated_roots[0].id : null;
        }

        if (state.selectedOrg) {
          selectedOrg = state.selectedOrg;
        } else {
          selectedOrg = !action.result.error ? 0 : null;
        }

        if (state.selectedType) {
          selectedType = state.selectedType;
        } else {
          selectedType = !action.result.error ? 0 : null;
        }
      } 
      if(action.result.user_info.group === 26){
        if (state.selectedOrg) {
          selectedOrg = state.selectedOrg;
        } else {
          selectedOrg = !action.result.error ? action.result.user_info.associated_orgs[0]['id'] : null;
        }
      }

      return {
        ...state,
        loading: false,
        // loaded: true,
        user: !action.result.error ? action.result.user_info : null,
        trackerAuthToken: !action.result.error ? action.result.tracker_auth_token : null,
        selectedRoot: selectedRoot,
        selectedOrg: selectedOrg,
        selectedType: selectedType // TODO or use selectedOrg?
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        loggingIn: false,
        token:null,
        login_hash: null,
        error: action.error,
        errorMessage: 'We didn\'t recognize that e-mail and password combo. Please try again.'
      };
    case LOGIN:
      return {
        ...state,
        loading: false,
        loggingIn: true,
        loginError: false,
        token: null,
        user: null,
        loginActive: 0
      };
    case LOGIN_SUCCESS:
      if (action.result.login_active !== 1) {
        return {
          ...state,
          loginActive: action.result.login_active,
          loading: false,
          loggingIn: false,
          loginError: true,
          errorMessage: action.result.message,
          token: !action.result.error ? action.result.token : null,
          login_hash: !action.result.error ? action.result.login_hash : null,
        };
      }
      return {
        ...state,
        loading: false,
        loggingIn: false,
        loginActive: 1,
        loginError: action.result.error,
        errorMessage: action.result.message,
        token: !action.result.error ? action.result.token : null,
        login_hash: !action.result.error ? action.result.login_hash : null
      };
    case ROlE_SWITCH_SUCCESS:
      if (action.result.login_active !== 1) {
        return {
          ...state,
          loginActive: action.result.login_active,
          loading: false,
          loggingIn: false,
          loginError: true,
          errorMessage: action.result.message,
          token: !action.result.error ? action.result.token : null,
          login_hash: !action.result.error ? action.result.login_hash : null,
        };
      }
      return {
        ...state,
        loading: false,
        loggingIn: false,
        loginActive: 1,
        loginError: action.result.error,
        errorMessage: action.result.message,
        token: !action.result.error ? action.result.token : null,
        login_hash: !action.result.error ? action.result.login_hash : null,
        user: !action.result.error ? action.result.user_info : null,
      }; 
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        loggingIn: false,
        loginError: (action.error) ? true : false,
        token: null,
        login_hash: null,
        user: null,
        loginActive: 0
      };
    case LOGOUT:

      return {
        ...state,
        loading: false,
        loggingOut: true,
        loginActive: 0
      };
    case LOGOUT_SUCCESS:
      return {
        // ...state,
        loading: false,
        loggingOut: false,
        token: null,
        user: null,
        trackerAuthToken: null,
        login_hash: null,
        selectedRoot: null,
        previouslySelectedRoot: null,
        selectedOrg: null,
        loginActive: 0,
        logoutError: null,
        error: null,
        message: null
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        loggingOut: false,
        logoutError: action.result.error,
        error: action.result.error,
        message: action.result.error
      };
    case LOGIN_MESSAGE: 
      return {
        ...state,
        loading: false,
        loggingIn: false,
        loginError: (action.error) ? action.error : false,
        token: null,
        login_hash: null,
        user: null
      };
    case UPDATE_TOKEN_AND_LOGIN_HASH: 
      return {
        ...state,
        login_hash: action.result.login_hash,
        token: action.result.token
      };
    case UPDATE_ROOT:
      return {
        ...state,
        previouslySelectedRoot: state.selectedRoot,
        selectedRoot: action.result.selectedRoot,
        selectedOrg: 0
      };
    case REVERT_ROOT:
      return {
        ...state,
        selectedRoot: state.previouslySelectedRoot,
        previouslySelectedRoot: null,
        selectedOrg: 0
      };
    case UPDATE_ORG:
      return {
        ...state,
        selectedOrg: action.result.selectedOrg
      };
    case UPDATE_ORG_AND_ROOT:
      return {
        ...state,
        previouslySelectedRoot: state.selectedRoot,
        selectedRoot: action.result.selectedRoot,
        selectedOrg: action.result.selectedOrg
      };
    case FLASH_NOTIFICATION:
      return {
        ...state,
        message: action.result.message,
        messageClickFunction: action.result.onClick
      };
    case UPDATE_ORG_REDIRECT:
      return {
        ...state,
        orgRedirect: action.result.orgRedirect
      };
    case UPDATE_USER:
      return {
        ...state,
        user: {...state.user, ...action.result}
      };
    case CHANGE_USER:
      return {
        ...state,
        user: action.result
      };
    case LOAD_MENU:
      return {
        ...state,
        menuLoading: true,
        menu: []
      }
    case LOAD_MENU_SUCCESS:
      return {
        ...state,
        menuLoading: false,
        menu: action.result && action.result.routes ? action.result.routes : []
      }
    case LOAD_MENU_FAIL:
      return {
        ...state,
        menuLoading: false,
        menu: []
      }
    case SET_TOKEN_AND_HASH: 
      return {
        ...state, 
        token: action.result.token, 
        login_hash: action.result.hash
      }
    case SET_TRACKER:
      return {
        ...state,
        tracker: action.result.tracker
      }
    default:
      return state;
  }
}


export function getMenu() {
  return {
    types: [LOAD_MENU, LOAD_MENU_SUCCESS, LOAD_MENU_FAIL],
    promise: (client) => client.get('/access/get_menu',)
  };
}

export function loadUserInfo(loginHash, token) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.post('/authentication/validate_hash', {
      data: {login_hash: loginHash, token: token, system: 'admin', et_ind: 1}
      // data: {token: token}
    })
  };
}


export function login(email, password, zitadelTokens, acceptedOnTheFlyTermsAndConditions = false, accessCode = null) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) => client.post(':peerapi/authentication/login', {
      data: { email: email, password: password, zitadel_tokens: zitadelTokens, accepted_terms_and_conditions: acceptedOnTheFlyTermsAndConditions, access_code: accessCode, system: 'admin', et_ind: 1}
    })
  };
}

export function checkTokenStatus(data) {
  return {
    types: [GENERIC, GENERIC, GENERIC],
    promise: (client) => client.post('/authentication/check_ttl_for_token', {
      data: {
        et_ind: 1,
        ...data
      }
    })
  };
}

export function resendMFACode(data) {
  return {
    types: [GENERIC, GENERIC, GENERIC],
    promise: (client) => client.post('/authentication/resend_MFA_Code', {
      data: {
        et_ind: 1,
        ...data
      }
    })
  };
}

export function roleSwitchLogin(type_group, type_id, super_admin_secret = null){
  return {
    types: [GENERIC, ROlE_SWITCH_SUCCESS, GENERIC],
    promise: (client) => client.post('/authentication/role_switch_login', {
      data: { et_ind: 1, type_group: type_group, type_id: type_id, super_admin_secret: super_admin_secret, system: 'admin'}
    })
  };
}

export function resetPassword(email) {
  return {
    types: [GENERIC, GENERIC, GENERIC],
    promise: (client) => client.post('/authentication/reset_portal_password', {
      data: { email: email, et_ind: 1 }
    })
  };
}
//@preyash Remove bypass_accept_or_deny_terms
export function changePassword(email, oldPassword, password, confirmedPassword = null, confirmNewPasswordInd = 0, termsConditions = false, bypass_accept_or_deny_terms = false) {
  return {
    types: [GENERIC, GENERIC, GENERIC],
    promise: (client) => client.post('/helpers/login/change_password', {
      data: { email, oldPassword, password, confirmedPassword, confirmNewPasswordInd, termsConditions, bypass_accept_or_deny_terms, et_ind: 1 }
    })
  };
}

export function setPassword(email, password) {
  return {
    types: [GENERIC, GENERIC, GENERIC],
    promise: (client) => client.post('/authentication/set_password', {
      data: { email, password, et_ind: 1 }
    })
  };
}

export function refreshTwilioToken(data) {
  return {
    types: [GENERIC, GENERIC, GENERIC],
    promise: (client) => client.post('/authentication/refresh_twilio_token', {
      data: {
        et_ind: 1,
        ...data
      }
    })
  };
}

export function logout() {
  return {
    types: [GENERIC, LOGOUT_SUCCESS, LOGOUT_SUCCESS],
    promise: (client) => client.post('/authentication/logout', {
      data: {
        et_ind: 1,
      },
    })
  };
  // return {
  //   type: LOGOUT_SUCCESS,
  //   result: {}
  // };
}

export function fbRefreshToken() {
  return {
    types: [GENERIC, GENERIC, GENERIC],
    promise: (client) => client.post('/authentication/get_fb_refresh_token')
  };
}

export function logoutError(error) {
  return {
    type: LOGIN_MESSAGE,
    result: { error: error }
  };
}

export function loginSuccess(loginHash, token) {
  return {
    type: LOGIN_SUCCESS,
    result: {error: false, token: token, login_hash: loginHash}
  };
}

export function updateTokenAndLoginHash(token, loginHash) {
  return {
    type: UPDATE_TOKEN_AND_LOGIN_HASH,
    result: {login_hash: loginHash, token}
  };
}

export function setFlashNotification(message, onClick = null) {
  return {
    type: FLASH_NOTIFICATION,
    result: {error: false, message: message, onClick: onClick}
  };
}

export function updateRoot(rootID) {
  return {
    type: UPDATE_ROOT,
    result: {
      selectedRoot: parseInt(rootID, 10),
      selectedOrg: 0
    }
  };
}

export function revertRoot() {
  return {
    type: REVERT_ROOT,
    result: {
    }
  };
}

export function updateOrg(orgID) {
  return {
    type: UPDATE_ORG,
    result: {
      selectedOrg: parseInt(orgID, 10)
    }
  };
}

export function updateOrgRedirect(path) {
  return {
    type: UPDATE_ORG_REDIRECT,
    result: {
      orgRedirect: path
    }
  };
}


export function updateOrgAndRoot(data) {
  return {
    type: UPDATE_ORG_AND_ROOT,
    result: {
      selectedOrg: parseInt(data.orgID, 10),
      selectedRoot: parseInt(data.rootID, 10)
    }
  };
}

export function updateUser(data) {
  return {
    type: UPDATE_USER,
    result: data
  };
}

export function changeUser(data) {
  return {
    type: CHANGE_USER,
    result: data
  };
}

export function setTokenAndHash(data) {
  return {
    type: SET_TOKEN_AND_HASH, 
    result: data
  }
}

export function setTracker(data){
  return{
    type: SET_TRACKER,
    result: data
  }
}
