import MatomoTracker from '@datapunt/matomo-tracker-js'
import store from '../store';
import {setTracker} from 'redux/modules/auth'; 
const tracker_config = {
  urlBase: process.env.REACT_APP_MATOMO_URL,
  siteId: process.env.REACT_APP_MATOMO_SITE_ID
};

const getTracker = (user) => {
  let tracker = store.getState().auth.tracker;
  if(!tracker){
    tracker = new MatomoTracker(Object.assign(tracker_config, {userId: user.group+'_'+user.id}));
    setTracker({tracker: tracker});
  }

  return tracker;
}

export const MatomoHelper = {
  trackPageView(params) {
    const user = store.getState().auth.user;
    if(user && user.is_super_admin !== 1){
      const tracker = getTracker(user);
      tracker.trackPageView(params);
    }
  },
  trackEvent(params) {
    const user = store.getState().auth.user;
    if(user && user.is_super_admin !== 1){
      const tracker = getTracker(user);
      tracker.trackEvent(params);
    }
  },
  trackSiteSearch(params){
    const user = store.getState().auth.user;
    if(user && user.is_super_admin !== 1){
      const tracker = getTracker(user);
      tracker.trackSiteSearch(params);
    }
  },
  trackLogInOutEvent(user = false, action = false) {
    if(user && action && user.is_super_admin !== 1){
      let tracker = getTracker(user);
      tracker.trackEvent({
        category: 'logged',
        action: action,
      });

      if(action === 'out'){
        // User has just logged out, we reset the User ID
        tracker.pushInstruction('resetUserId');

        // we also force a new visit to be created for the pageviews after logout
        tracker.pushInstruction('appendToTrackingUrl', 'new_visit=1');
        tracker.pushInstruction('trackPageView');

        // we finally make sure to not again create a new visit afterwards (important for Single Page Applications)
        tracker.pushInstruction('appendToTrackingUrl', '');
      } else if( action === 'in') {
        // tracker.pushInstruction('setUserId', user.group+'_'+user.id);
      }
    }
  }

};

