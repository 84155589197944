const GENERIC_REQUEST = "inquisit-app/mentor/adminMentorProfile/GENERIC_REQUEST";
const GENERIC_FAIL = "inquisit-app/mentor/adminMentorProfile/GENERIC_FAIL";
const GENERIC_SUCCESS = "inquisit-app/mentor/adminMentorProfile/GENERIC_SUCCESS";

const ADMIN_PROFILE_REQUEST = "inquisit-app/mentor/adminMentorProfile/ADMIN_PROFILE_REQUEST";
const ADMIN_PROFILE_SUCCESS = "inquisit-app/mentor/adminMentorProfile/ADMIN_PROFILE_SUCCESS";

const LOAD_MENTOR_PROFILE = "inquisit-app/mentor/adminMentorProfile/LOAD_MENTOR_PROFILE";

const initialState = {
  mentorProfile: {},
  loading:true,

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADMIN_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case ADMIN_PROFILE_SUCCESS:
      if (!action.result.mentor_profile) {
        return {
          ...state,
          loading: false,
          mentorProfile: {}
        };
      }
      return {
        ...state,
        loading: false,
        mentorProfile: action.result.mentor_profile
      };
    case LOAD_MENTOR_PROFILE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export function getMentorProfile(data) {
  return {
    types: [!data.loading ? LOAD_MENTOR_PROFILE : ADMIN_PROFILE_REQUEST, ADMIN_PROFILE_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/mentor/mentorprofile/get_mentor_profile', {
      params: {
        mentor_id: data.mentor_id,
        mentor_master_id: data.mentor_master_id
      }
    })
  };
}


export function getMentorCapacity(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/mentor/mentorprofile/get_mentor_capacity', {
      params: data
    })
  };
}

export function getMentorProgramCompletionStats(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/mentor/mentorprofile/get_mentor_program_completion_stats', {
      params: data
    })
  };
}

export function getMentorDisenrollmentStats(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/mentor/mentorprofile/get_mentor_disenrollment_stats', {
      params: data
    })
  };
}

export function getMentorActivity(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/mentor/mentorprofile/get_mentor_activity', {
      params: data
    })
  };
}

export function getMentorLogistics(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/mentor/mentorprofile/get_mentor_logistics', {
      params: data
    })
  };
}

export function getMentorKeyStatistics(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/mentor/mentorprofile/get_mentor_key_stats', {
      params: data
    })
  };
}

export function getMenteeAndProgramStats(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('mentor/mentorprofile/get_mentee_and_program_stats', {
      params: data
    })
  };
}

export function getActiveMenteeAndCapacity(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('mentor/mentorprofile/get_active_mentee_and_capacity', {
      params: data
    })
  };
}

export function getActiveMenteeBreakdown(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('mentor/mentorprofile/get_active_mentee_breakdown', {
      params: data
    })
  };
}

export function getSequenceStats(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('mentor/mentorprofile/get_sequence_stats', {
      params: data
    })
  };
}

export function getMentorOutcomes(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('mentor/mentorprofile/get_mentor_outcomes', {
      params: data
    })
  };
}

export function getMentorCompensationRequests(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('mentor/mentorprofile/get_mentor_compensation_requests', {
      params: data
    })
  };
}

export function getCountOfMentorCompensationRequests(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('mentor/mentorprofile/get_count_of_mentor_compensation_requests', {
      params: data
    })
  };
}

export function getCompensationRequestDetails(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('mentor/get_compensation_request_details', {
      params: data
    })
  };
}

export function updateCompensationStatus(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/mentor/payment/update_compensation', {
      data: data
    })
  };
}

export function bulkUpdateCompensationStatus(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/mentor/payment/bulk_update_compensation', {
      data: data
    })
  };
}
