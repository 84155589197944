
const LOAD_GENERIC = 'inquisit-app/pn_copilot/LOAD_COPILOT_GENERIC';
const SUCCESS_GENERIC = 'inquisit-app/pn_copilot/SUCCESS_COPILOT_GENERIC';
const FAIL_GENERIC = 'inquisit-app/pn_copilot/FAIL_COPILOT_GENERIC';
const LOAD_PRE_CALL_LIST = 'inquisit-app/pn_copilot/LOAD_PRE_CALL_LIST';
const SUCCESS_PRE_CALL_LIST = 'inquisit-app/pn_copilot/SUCCESS_PRE_CALL_LIST'; 
const FAIL_PRE_CALL_LIST = 'inquisit-app/pn_copilot/FAIL_PRE_CALL_LIST';

const LOAD_CONVERSATION_GUIDE_LIST = 'inquisit-app/pn_copilot/LOAD_CONVERSATION_GUIDE_LIST';
const SUCCESS_CONVERSATION_GUIDE_LIST = 'inquisit-app/pn_copilot/SUCCESS_CONVERSATION_GUIDE_LIST'; 
const FAIL_CONVERSATION_GUIDE_LIST = 'inquisit-app/pn_copilot/FAIL_CONVERSATION_GUIDE_LIST';

const LOAD_POST_CALL_LIST = 'inquisit-app/pn_copilot/LOAD_POST_CALL_LIST';
const SUCCESS_POST_CALL_LIST = 'inquisit-app/pn_copilot/SUCCESS_POST_CALL_LIST'; 
const FAIL_POST_CALL_LIST = 'inquisit-app/pn_copilot/FAIL_POST_CALL_LIST';



const GENERIC = [LOAD_GENERIC, SUCCESS_GENERIC, FAIL_GENERIC];

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

// Get
export function getSupporterCopilotPreCallPlanning(data) {
  return {
    types: [!data.loadingInd ? LOAD_PRE_CALL_LIST : LOAD_GENERIC, SUCCESS_PRE_CALL_LIST, FAIL_PRE_CALL_LIST],
    promise: (client) => client.get('/supporter/copilot/get_supporter_pre_call_planning_list', {
      params: data
    })
  };
}

export function getSupporterCopilotPostCallChecklist(data) {
  return {
    types: [!data.loadingInd ? LOAD_POST_CALL_LIST : LOAD_GENERIC, SUCCESS_POST_CALL_LIST, FAIL_POST_CALL_LIST],
    promise: (client) => client.get('/supporter/copilot/get_supporter_post_call_checklist', {
      params: data
    })
  };
}

export function getSupporterCopilotConversationGuide(data) {
  return {
    types: [!data.loadingInd ? LOAD_CONVERSATION_GUIDE_LIST : LOAD_GENERIC, SUCCESS_CONVERSATION_GUIDE_LIST, FAIL_CONVERSATION_GUIDE_LIST],
    promise: (client) => client.get('/supporter/copilot/get_supporter_call_conversation_guide', {
      params: data
    })
  };
}

export function getSupporterWorkbookCompensation(esProgramSupporterNodeId) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/supporter/copilot/get_supporter_workbook_compensation', {
      params: {
        es_program_supporter_node_id: esProgramSupporterNodeId
      }
    })
  };
}

export function getSupporterCallsAttemptsByType(esProgramSupporterNodeId, type, manualReviewQueueId = null) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/supporter/copilot/get_supporter_call_attempts_by_type', {
      params: {
        manual_review_queue_id: manualReviewQueueId,
        es_program_supporter_node_id: esProgramSupporterNodeId,
        type: type
      }
    })
  };
}

// Set

export function closeSupporterWorkbook(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/supporter/copilot/close_supporter_workbook', {
      data: data
    })
  };
}

export function setSupportertPreCallTopicAcknowledged(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/supporter/copilot/set_supporter_pre_call_topic_acknowledged', {
      data: data
    })
  };
}

export function pinToSupporterConversationGuide(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/supporter/copilot/pin_to_supporter_conversation_guide', {
      data: data
    })
  };
}

export function deleteSupporterConversationGuideTopic(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/supporter/copilot/delete_supporter_conversation_guide_topic', {
      data: data
    })
  };
}

export function setPSPostCallTopicAcknowledged(data) {
  return {
    types: GENERIC,
    promise: client => client.post('supporter/copilot/set_ps_post_call_topic_acknowledged', {
      data: data
    })
  };
}

export function addSupporterManualCompensation(data) {
  return {
    types: GENERIC,
    promise: client => client.post('/supporter/copilot/add_supporter_manual_compensation', {
      data: data
    })
  };
}