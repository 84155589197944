const OUTBOUND_TELEPHONY_GENERIC_REQUEST = 'inquisit-app/outboundTelephony/OUTBOUND_TELEPHONY_GENERIC_REQUEST';
const OUTBOUND_TELEPHONY_GENERIC_SUCCESS = 'inquisit-app/outboundTelephony/OUTBOUND_TELEPHONY_GENERIC_SUCCESS';
const OUTBOUND_TELEPHONY_GENERIC_FAIL = 'inquisit-app/outboundTelephony/OUTBOUND_TELEPHONY_GENERIC_FAIL';
const GENERIC = [OUTBOUND_TELEPHONY_GENERIC_REQUEST,OUTBOUND_TELEPHONY_GENERIC_SUCCESS,OUTBOUND_TELEPHONY_GENERIC_FAIL];

const START_CONFERENCE_SUCCESS = 'inquisit-app/outboundTelephony/START_CONFERENCE_SUCCESS';
const START_EXTERNAL_ENTITY_CALL_SUCCESS = 'inquisit-app/outboundTelephony/START_EXTERNAL_ENTITY_CALL_SUCCESS';

const RESET_OUTBOUND_CALL_PROGRESS = 'inquisit-app/outboundTelephony/RESET_OUTBOUND_CALL_PROGRESS';
const RESET_OUTBOUND_CALL_USER_PROPERTIES = 'inquisit-app/outboundTelephony/RESET_OUTBOUND_CALL_USER_PROPERTIES';
const RESET_TELEPHONY_DATA = 'inquisit-app/outboundTelephony/RESET_TELEPHONY_DATA';
const SET_ACTIVE_CALL_CONNECTION = 'inquisit-app/outboundTelephony/SET_ACTIVE_CALL_CONNECTION';
const SET_ACTIVE_DEVICE_INSTANCE = 'inquisit-app/outboundTelephony/SET_ACTIVE_DEVICE_INSTANCE';
const SET_CALL_SID = 'inquisit-app/outboundTelephony/SET_CALL_SID';
const SET_CURRENT_CALL_START_TIME = 'inquisit-app/outboundTelephony/SET_CURRENT_CALL_START_TIME';
const SET_STATUS_UPDATED_FOR_CURRENT_CALL = 'inquisit-app/outboundTelephony/SET_STATUS_UPDATED_FOR_CURRENT_CALL';
const SET_OUTBOUND_CALL_PROGRESS = 'inquisit-app/outboundTelephony/SET_OUTBOUND_CALL_PROGRESS';
const SET_OUTBOUND_CALL_USER_PROPERTIES = 'inquisit-app/outboundTelephony/SET_OUTBOUND_CALL_USER_PROPERTIES';
const TOGGLE_CALL_COMPLETE_MODAL = 'inquisit-app/outboundTelephony/TOGGLE_CALL_COMPLETE_MODAL';
const SET_CALL_STATUS = 'inquisit-app/outboundTelephony/SET_CALL_STATUS';

const initialState = {
  activeDeviceInstance: {},
  activeCallConnection: {},
  callSid: '',
  conferenceId: null,
  conferencingOptions: [],
  externalEntityTxId: null,
  outboundCallInProgress: [],
  outboundCallUserProperties: [],
  showCallCompleteModal: false,
  currentCallStartTime: null,
  callStatusUpdatedForCurrentCall: true,
  callStatus: 'idle'
};

export default function reducer(state = initialState, action = {}) {
  // console.log('outboundTelephonyReducer');
  // console.log(action.type);
  // console.log(action.data);

  let { outboundCallInProgress, outboundCallUserProperties} = state;
  let index = -1;
  switch (action.type) {
    case SET_ACTIVE_CALL_CONNECTION:
      return {
        ...state,
        activeCallConnection: action.data,
        callSid: action.data && action.data.parameters && action.data.parameters.CallSid ? action.data.parameters.CallSid: state.callSid,
        // outboundCallInProgress: true
      };
    case SET_ACTIVE_DEVICE_INSTANCE:
      return {
        ...state,
        activeDeviceInstance: action.data
      };
    case RESET_OUTBOUND_CALL_PROGRESS:
      return {
        ...state,
        outboundCallInProgress: [],
      };
    case RESET_OUTBOUND_CALL_USER_PROPERTIES:
      return {
        ...state,
        outboundCallUserProperties: [],
      };
    case SET_CALL_SID:
      return {
        ...state,
        callSid: action.data
      };
    case SET_CURRENT_CALL_START_TIME:
      return {
        ...state,
        currentCallStartTime: action.data
      };
    case SET_STATUS_UPDATED_FOR_CURRENT_CALL:
      return {
        ...state,
        callStatusUpdatedForCurrentCall: action.data
      };
    case SET_OUTBOUND_CALL_PROGRESS:
      index = outboundCallInProgress.findIndex((obj) => obj.number===action.data.number);
      if(action.data.callProgressObject){
        if(index === -1){
          outboundCallInProgress.push(action.data);
        } else {
          outboundCallInProgress[index] = action.data;
        }
      } else {
        if(index !== -1) outboundCallInProgress.splice(index,1);
      }
      return {
        ...state,
        outboundCallInProgress: outboundCallInProgress
      };
    case SET_OUTBOUND_CALL_USER_PROPERTIES: {
      const userIndex = outboundCallUserProperties.findIndex((obj) => obj.number===action.data.number);
      if(action.data.callUserPropertiesObject){
        if(userIndex === -1){
          outboundCallUserProperties.push(action.data);
        } else {
          outboundCallUserProperties[userIndex] = action.data;
        }
      } else {
        if(userIndex !== -1) outboundCallUserProperties.splice(userIndex,1);
      }
      return {
        ...state,
        outboundCallUserProperties: outboundCallUserProperties
      }
    }
    case SET_CALL_STATUS:
      return {
        ...state,
        callStatus: action.data,
    };
    case START_EXTERNAL_ENTITY_CALL_SUCCESS:
      return {
        ...state,
        externalEntityTxId: action.result && action.result.success ? action.result.call_tx_id : null,
      };
    case START_CONFERENCE_SUCCESS:
      return {
        ...state,
        conferenceId: action.result && action.result.success ? action.result.conference_id : null,
        conferenceFirebasePath: action.result && action.result.success ? action.result.firebase_path : null,
        conferenceFirebasePathParent: action.result && action.result.success ? action.result.firebase_path_parent : null,
        conferenceType: action.result && action.result.success ? action.result.conferenceType : null,
        externalEntityTxId: action.result && action.result.success ? action.result.external_entity_tx_id : null,
      };
    case RESET_TELEPHONY_DATA:
      return {
        activeDeviceInstance: {},
        activeCallConnection: {},
        callSid: '',
        conferenceId: null,
        externalEntityTxId: null,
        outboundCallInProgress: [],
        outboundCallUserProperties: [],
        showCallCompleteModal: false,
        currentCallStartTime: null,
        callStatusUpdatedForCurrentCall: true
      };
    case TOGGLE_CALL_COMPLETE_MODAL:
      return {
        ...state,
        showCallCompleteModal: action.data,
      };
    default:
      return state;
  }
}

export function setActiveDeviceInstance(value) {
  return {
    type: SET_ACTIVE_DEVICE_INSTANCE,
    data: value
  };
}

export function toggleCallCompleteModal(value) {
  return {
    type: TOGGLE_CALL_COMPLETE_MODAL,
    data: value
  };
}

export function setActiveCallConnection(value) {
  return {
    type: SET_ACTIVE_CALL_CONNECTION,
    data: value
  };
}

export function resetOutboundCallProgress() {
  return {
    type: RESET_OUTBOUND_CALL_PROGRESS,
  };
}

export function resetOutboundCallUserProperties() {
  return {
    type: RESET_OUTBOUND_CALL_USER_PROPERTIES,
  };
}

export function setCallSid(data) {
  return {
    type: SET_CALL_SID,
    data: data,
  };
}

export function setCallStatus(status) {
  return {
    type: SET_CALL_STATUS,
    data: status,
  };
}

export function setOutboundCallProgress(number, callProgressObject) {
  return {
    type: SET_OUTBOUND_CALL_PROGRESS,
    data: {number: number, callProgressObject: callProgressObject}
  };
}

export function setOutboundCallUserProperties(number, callUserPropertiesObject) {
  return {
    type: SET_OUTBOUND_CALL_USER_PROPERTIES,
    data: {number: number, callUserPropertiesObject: callUserPropertiesObject}
  };
}

export function startExternalResourceCallWithTx(data) {//MOVE TO OUTBOUND
  return {
    types: [OUTBOUND_TELEPHONY_GENERIC_REQUEST,START_EXTERNAL_ENTITY_CALL_SUCCESS,OUTBOUND_TELEPHONY_GENERIC_FAIL],
    promise: (client) => client.post('/externalentitytelephony/start_external_entity_call_with_tx', {
      data: data
    })
  };
}

export function addCallToConference(data) {
  return {
    types: [OUTBOUND_TELEPHONY_GENERIC_REQUEST,OUTBOUND_TELEPHONY_GENERIC_SUCCESS,OUTBOUND_TELEPHONY_GENERIC_FAIL],
    promise: (client) => client.post('/outboundcalltelephony/add_call_to_conference', {
      data: data
    })
  };
}

export function transferCallToConference(data) {
  return {
    types: [OUTBOUND_TELEPHONY_GENERIC_REQUEST,START_CONFERENCE_SUCCESS,OUTBOUND_TELEPHONY_GENERIC_FAIL],
    promise: (client) => client.post('/outboundcalltelephony/transfer_call_to_conference_for_three_way', {
      data: data
    })
  };
}

export function resetOutboundTelephony() {
  return {
    type: RESET_TELEPHONY_DATA,
    data: false
  };
}

export function setCallStartTime(time) {
  return {
    type: SET_CURRENT_CALL_START_TIME,
    data: time
  };
}

export function callStatusUpdatedForCurrentCall(bool) {
  return {
    type: SET_STATUS_UPDATED_FOR_CURRENT_CALL,
    data: bool
  };
}

export function endCall(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/admintelephony/end_call', {
      data: data
    })
  };
}

export function getAdminCallTxStatusList(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/admin/getmenteesdata/get_mentee_call_tx_statues', {
      params: params
    })
  };
}

export function initiateAdminCallViaPhone(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/admintelephony/initiate_call', {
      data: data
    })
  };
}

export function dropVoicemail(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/voicemail/drop_voicemail', {
      data: data
    })
  };
}

export function getCallStatus(params) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/voicemail/get_tx_status_by_sid', {
        params: params,
      }),
  };
}

export function getCallDuration(params) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/siptelephony/get_twilio_call_info', {
        params: params,
      }),
  };
}