const GENERIC_REQUEST = 'inquisit-app/PEER_NAVIGATOR/GENERIC_REQUEST';
const GENERIC_FAIL = 'inquisit-app/PEER_NAVIGATOR/GENERIC_FAIL';
const GENERIC_SUCCESS = 'inquisit-app/PEER_NAVIGATOR/GENERIC_SUCCESS';

const PROFILE_REQUEST = "inquisit-app/peerNavigator/peerNavigatorProfile/PROFILE_REQUEST";
const PROFILE_SUCCESS = "inquisit-app/peerNavigator/peerNavigatorProfile/PROFILE_SUCCESS";

const GENERIC = [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL];

const initialState = {
  peerNavigatorProfile: {},
  loading:true,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        ...state,
        loading:true,
      }
    case PROFILE_SUCCESS:
      if (!action.result.profile) {
        return {
          ...state,
          loading: false,
          peerNavigatorProfile: {}
        };
      }
      return {
        ...state,
        loading: false,
        peerNavigatorProfile: action.result.profile
      };
    default:
      return state;
  }
}

export function getPeerNavigatorProfile(params) {
  return {
    types: [PROFILE_REQUEST, PROFILE_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/peer_navigator/get_peer_navigator_profile', {
      params: params
    }),
  };
}

export function updatePeerNavigatorInfo(params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/peer_navigator/update_peer_navigator_info', {
      params: params
    }),
  };
}

export function getMenteeList(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_peer_navigator_mentee_list', {
      params: params
    }),
  };
}

export function getPNMenteeList(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/pn_mentee_list', {
      params: params
    }),
  };
}

export function getUnassignedCallList(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_peer_navigator_unassigned_call_list', {
      params: params
    }),
  };
}

export function getPNCompetencyAreas() {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_pn_competency_areas_list'),
  };
}

export function getPeerNavigatorList(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_peer_navigator_list', {
      params: params
    }),
  };
}

export function getLastFiveNotesByMentee(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_last_five_notes_by_mentee', {
      params: params
    }),
  };
}

export function getPeerNavigatorListByMenteeId(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_peer_navigator_list_by_mentee_id', {
      params: params
    }),
  };
}


export function getPeerNavigatorLevelList(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_all_peer_navigator_levels', {
      params: params
    }),
  };
}

export function getPeerNavigatorCapabilityList(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_all_peer_navigator_capability', {
      params: params
    }),
  };
}

export function getPeerNavigatorFunctionalAreas(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_all_peer_navigator_functional_areas', {
      params: params
    }),
  };
}

export function getPeerNavigatorCallQueues(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_pn_call_queues', {
      params: params
    }),
  };
}

export function getPeerNavigatorQualityScores(params){
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_peer_navigator_quality_scores', {
      params: params
    }),
  };
}

export function getPeerNavigatorCalls(params){
  return {
    types: GENERIC,
    promise: (client) => client.get('/peer_navigator/get_peer_navigator_calls', {
      params: params
    }),
  };
}

export function updatePNCallStatus(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/peer_navigator/update_pn_tx_call_status', {
      data: data
    })
  };
}

export function assignMenteeToPeerNavigator(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/peer_navigator/set_mentees_peer_navigator', {
      data: data
    }),
  };
}

export function changePeernavigatorTimezone(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/peer_navigator/change_peer_navigator_timezone', {
      data: data
    })
  };
}
