const GENERIC_LOAD_REQUESTED = 'inquisit-app/dashboard/analytics/GENERIC_LOAD_REQUESTED';
const GENERIC_LOAD_FAIL = 'inquisit-app/dashboard/analytics/GENERIC_LOAD_FAIL';
const GENERIC_SUCCESS = 'inquisit-app/dashboard/analytics/GENERIC_SUCCESS';


const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getOrgConversionFunnelData(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/dashboard/analytics/get_conversion_funnel_data', {
      params: data
    })
  };
}
