const GENERIC_REQUEST = 'inquisit-app/CLIENT_PORTAL/GENERIC_REQUEST';
const GENERIC_FAIL = 'inquisit-app/CLIENT_PORTAL/GENERIC_FAIL';
const GENERIC_SUCCESS = 'inquisit-app/CLIENT_PORTAL/GENERIC_SUCCESS';
const ALL_AVAILABLE_REPORTS_REQUEST = 'inquisit-app/CLIENT_PORTAL/ALL_AVAILABLE_REPORTS_REQUEST';
const ALL_AVAILABLE_REPORTS_SUCCESS = 'inquisit-app/CLIENT_PORTAL/ALL_AVAILABLE_REPORTS_SUCCESS';
const ALL_AVAILABLE_REPORTS_FAIL = 'inquisit-app/CLIENT_PORTAL/ALL_AVAILABLE_REPORTS_FAIL';
const UPDATE_SELECTED_AVAILABLE_REPORT = 'inquisit-app/CLIENT_PORTAL/UPDATE_SELECTED_AVAILABLE_REPORT';

const GENERIC = [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL];
const ALL_AVAILABLE_REPORTS = [ALL_AVAILABLE_REPORTS_REQUEST, ALL_AVAILABLE_REPORTS_SUCCESS, ALL_AVAILABLE_REPORTS_FAIL];
const initialState = {
  loading: true,
  allAvailableReportsList: [],
  selectedAvailableReportId: null,
}

export default function reducer(state = initialState, action = {}) {
  switch(action.type) {
    case ALL_AVAILABLE_REPORTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ALL_AVAILABLE_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allAvailableReportsList: action.result && action.result.list ? action.result.list : []
      };
    case ALL_AVAILABLE_REPORTS_FAIL:
      return {
        ...state,
        loading: false
      };
    case UPDATE_SELECTED_AVAILABLE_REPORT:
      return {
        ...state,
        selectedAvailableReportId: action.result?.available_report_id ? action.result.available_report_id : null
      }
    default:
      return state;
  }
}

export function getAllAvailableReportsList(params) {
  return {
    types: ALL_AVAILABLE_REPORTS,
    promise: (client) => client.get('/client_portal/get_all_available_report_list', {
      params: params
    }),
  };
}

export function getReport(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/client_portal/get_report', {
      params: params
    }),
  };
}

export function updateSelectedAvailableReport(data) {
  return {
    type: UPDATE_SELECTED_AVAILABLE_REPORT,
    result: data
  };
}
