const OUTREACH_DASHBOARD_SUCCESS_GENERIC = 'inquisit-app/outreach_dashboard/OUTREACH_DASHBOARD_SUCCESS_GENERIC';
const OUTREACH_DASHBOARD_REQUEST_GENERIC = 'inquisit-app/outreach_dashboard/OUTREACH_REQUEST_GENERIC';
const OUTREACH_DASHBOARD_FAIL_GENERIC = 'inquisit-app/outreach_dashboard/OUTREACH_FAIL_GENERIC';

const GENERIC = [OUTREACH_DASHBOARD_REQUEST_GENERIC, OUTREACH_DASHBOARD_SUCCESS_GENERIC, OUTREACH_DASHBOARD_FAIL_GENERIC];

const OUTREACH_DASHBOARD_ADMIN_ORG_LIST = 'inquisit-app/outreach_dashboard/OUTREACH_DASHBOARD_ADMIN_ORG_LIST';

const OUTREACH_MANUAL_TIME_LISTING_FOR_APPROVAL = 'inquisit-app/outreach_dashboard/OUTREACH_MANUAL_TIME_LISTING_FOR_APPROVAL';

const initialState = {
  loading: false,
  manualTimeListingForApproval: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OUTREACH_DASHBOARD_REQUEST_GENERIC:
      return {
        ...state,
        loading: true
      };
    case OUTREACH_DASHBOARD_FAIL_GENERIC:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case OUTREACH_DASHBOARD_ADMIN_ORG_LIST:
      return {
        ...state,
        loading: false,
        adminOrgs: (action.result) ? action.result : []
      };
    case OUTREACH_MANUAL_TIME_LISTING_FOR_APPROVAL:
      return {
        ...state,
        manualTimeListingForApproval: (action.result) ? action.result : []
      }
    default:
      return state;
  }
}

export function getAdminOrgList(data) {
  return {
    types: [OUTREACH_DASHBOARD_REQUEST_GENERIC, OUTREACH_DASHBOARD_ADMIN_ORG_LIST, OUTREACH_DASHBOARD_FAIL_GENERIC],
    promise: (client) => client.get('/admin/getadmindata/admin_org_list', {
      params: data
    })
  };
}

export function getManualTimeListingForApproval(data) {
  return {
    types: [OUTREACH_DASHBOARD_REQUEST_GENERIC, OUTREACH_MANUAL_TIME_LISTING_FOR_APPROVAL, OUTREACH_DASHBOARD_FAIL_GENERIC],
    promise: (client) => client.get('/getcoordinatordata/get_manual_time_listing_for_approval', {
      params: data
    })
  };
}

export function getOutreachDasbhoardFilterLists(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_dashboard_filter_lists', {
      params: params
    })
  };
}

export function getOutreachCallStats(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_call_stats', {
      params: params
    })
  };
}

export function getOutreachAgentStats(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_agent_stats', {
      params: params
    })
  };
}

export function getOutreachNetworkStats(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_network_stats', {
      params: params
    })
  };
}

export function getOutreachInboundCallStats(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_inbound_call_stats', {
      params: params
    })
  };
}

export function getOutreachEnrollmentGraphStats(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_enrollment_graph_stats', {
      params: params
    })
  };
}

export function getOutreachEnrollmentTableStats(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_enrollment_table_stats', {
      params: params
    })
  };
}

export function getOutreachCallStatusFunnel(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_call_status_funnel', {
      params: params
    })
  };
}

export function getOutreachEnrollmentsPerHour(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_enrollments_per_hour_stats', {
      params: params
    })
  };
}

export function getOutreachEngagedOfEligible(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_engaged_of_eligible', {
      params: params
    })
  };
}

export function getOutreachMenteesNeedingCallback(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_mentee_needing_callback', {
      params: params
    })
  };
}

export function getCoordinatorEfficiencyStats(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_coordinator_efficiency_stats', {
      params: params
    })
  };
}

export function getCoordinatorEnrollmentCallStats(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_coordinator_enrollment_call_length_stats', {
      params: params
    })
  };
}

export function getOutreachCallSankeyData(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_call_sankey_data', {
      params: params
    })
  };
}

export function getOutreachIVRStats(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/dashboard/analytics/get_outreach_ivr_stats', {
      params: params
    })
  };
}

// Post
export function updateOrgIvrStatus(data) {
  return {
    types: [OUTREACH_DASHBOARD_REQUEST_GENERIC, OUTREACH_DASHBOARD_SUCCESS_GENERIC, OUTREACH_DASHBOARD_FAIL_GENERIC],
    promise: (client) => client.post('/admin/setadministrationdata/update_org_ivr_status', {
      data: data
    })
  };
}

export function updateOrgIvrMaxAttempts(data) {
  return {
    types: [OUTREACH_DASHBOARD_REQUEST_GENERIC, OUTREACH_DASHBOARD_SUCCESS_GENERIC, OUTREACH_DASHBOARD_FAIL_GENERIC],
    promise: (client) => client.post('/admin/setadministrationdata/update_org_ivr_max_attempts', {
      data: data
    })
  };
}

export function updateOrgMaxAttempts(data) {
  return {
    types: [OUTREACH_DASHBOARD_REQUEST_GENERIC, OUTREACH_DASHBOARD_SUCCESS_GENERIC, OUTREACH_DASHBOARD_FAIL_GENERIC],
    promise: (client) => client.post('/admin/setadministrationdata/update_org_max_attempts', {
      data: data
    })
  };
}
