const MENTEE_MASTER_FOLLOWUP_LIST_REQUESTED = 'inquisit-app/menteeMasterFollowUp/MENTEE_MASTER_FOLLOWUP_LIST_REQUESTED';
const MENTEE_MASTER_FOLLOWUP_LIST_SUCCESS = 'inquisit-app/menteeMasterFollowUp/MENTEE_MASTER_FOLLOWUP_LIST_SUCCESS';
const MENTEE_MASTER_FOLLOWUP_LIST_FAILED = 'inquisit-app/menteeMasterFollowUp/MENTEE_MASTER_FOLLOWUP_LIST_FAILED';
const MENTEE_MASTER_FOLLOWUP_PROFILE_REQUESTED = 'inquisit-app/menteeMasterFollowUp/MENTEE_MASTER_FOLLOWUP_PROFILE_REQUESTED';
const MENTEE_MASTER_FOLLOWUP_PROFILE_SUCCESS = 'inquisit-app/menteeMasterFollowUp/MENTEE_MASTER_FOLLOWUP_PROFILE_SUCCESS';
const MENTEE_MASTER_FOLLOWUP_PROFILE_FAILED = 'inquisit-app/menteeMasterFollowUp/MENTEE_MASTER_FOLLOWUP_PROFILE_FAILED';
const ADD_PROFILE_TAB = 'inquisit-app/menteeMasterFollowUp/ADD_PROFILE_TAB';
const REMOVE_PROFILE_TAB = 'inquisit-app/menteeMasterFollowUp/REMOVE_PROFILE_TAB';
const MENTEE_MASTER_FOLLOWUP_REQUEST_GENERIC = 'inquisit-app/menteeMasterFollowUp/MENTEE_MASTER_FOLLOWUP_REQUEST_GENERIC';
const MENTEE_MASTER_FOLLOWUP_SUCCESS_GENERIC = 'inquisit-app/menteeMasterFollowUp/MENTEE_MASTER_FOLLOWUP_SUCCESS_GENERIC';
const MENTEE_MASTER_FOLLOWUP_FAIL_GENERIC = 'inquisit-app/menteeMasterFollowUp/MENTEE_MASTER_FOLLOWUP_FAIL_GENERIC';
const UPDATE_ACTIVE_PAGE_NUMBER_AND_PAGE_SIZE = 'inquisit-app/outreach/UPDATE_ACTIVE_PAGE_NUMBER_AND_PAGE_SIZE';
const MMGENERIC = [MENTEE_MASTER_FOLLOWUP_REQUEST_GENERIC, MENTEE_MASTER_FOLLOWUP_SUCCESS_GENERIC, MENTEE_MASTER_FOLLOWUP_FAIL_GENERIC];
const initialState = {
  menteeMasterFollowUpList: [],
  loadingList: false,
  menteeMasterFollowUpProfile: {},
  loadingProfile: false,
  openProfileTabs: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MENTEE_MASTER_FOLLOWUP_LIST_REQUESTED:
      return {
        ...state,
        loadingList: true,
      };
    case MENTEE_MASTER_FOLLOWUP_LIST_SUCCESS:
      return {
        ...state,
        loadingList: false,
        menteeMasterFollowUpList: action.result?.list ? action.result.list : [],
      };
    case MENTEE_MASTER_FOLLOWUP_LIST_FAILED:
      return {
        ...state,
        loadingList: false,
      };
    case MENTEE_MASTER_FOLLOWUP_PROFILE_REQUESTED:
      return {
        ...state,
        loadingProfile: true,
      };
    case MENTEE_MASTER_FOLLOWUP_PROFILE_SUCCESS:
      if (action?.result?.success) {
        if (!state.openProfileTabs) state.openProfileTabs = {};
        state.openProfileTabs["profile_" + action.result.profile.id] = action.result.profile;
        return {
          ...state,
          loadingProfile: false,
          menteeMasterFollowUpProfile: action.result.profile,
        };
      } 
      return {
        ...state,
        loadingProfile: false,
      };
    case MENTEE_MASTER_FOLLOWUP_PROFILE_FAILED:
      return {
        ...state,
        loadingProfile: false,
      };
    case ADD_PROFILE_TAB:
      if (!state.openProfileTabs) state.openProfileTabs = {};
      state.openProfileTabs["profile_" + action.payload.id] = action.payload;
      return {
        ...state,
      };
    case REMOVE_PROFILE_TAB:
      delete state.openProfileTabs["profile_" + action.payload];
      return {
        ...state,
      };
    case MENTEE_MASTER_FOLLOWUP_SUCCESS_GENERIC:
      return state;
    case UPDATE_ACTIVE_PAGE_NUMBER_AND_PAGE_SIZE:
      return {
        ...state,
        tabsManager:
          action.result && !isNaN(action.result.currentPage)
            ? {
                ...state.tabsManager,
                page: {
                  ...state.tabsManager?.page,
                  [action.result.tab]: action.result.currentPage,
                },
              }
            : initialState.tabsManager,
      };
    default:
      return state;
  }
}

export function addProfileTab(data) {
  return {
    type: ADD_PROFILE_TAB,
    payload: data
  }
}

export function removeProfileTab(data) {
  return {
    type: REMOVE_PROFILE_TAB,
    payload: data

  }
}

export function updateActivePageNumberAndSizePerPage(data) {
  return {
    type: UPDATE_ACTIVE_PAGE_NUMBER_AND_PAGE_SIZE,
    result: data,
  };
}

/*************** Getter ***************/

export function getMenteeMasterFollowUpList(data) {
  return {
    types: [MENTEE_MASTER_FOLLOWUP_LIST_REQUESTED, MENTEE_MASTER_FOLLOWUP_LIST_SUCCESS, MENTEE_MASTER_FOLLOWUP_LIST_FAILED],
    promise: (client) => client.get('/mentee_master_follow_up/get_mentee_master_follow_up_list', {
      params: data
    })
  };
}

export function getMenteeMasterFollowUpProfile(data) {
  return {
    types: [MENTEE_MASTER_FOLLOWUP_PROFILE_REQUESTED, MENTEE_MASTER_FOLLOWUP_PROFILE_SUCCESS, MENTEE_MASTER_FOLLOWUP_PROFILE_FAILED],
    promise: (client) => client.get('/mentee_master_follow_up/get_mentee_master_follow_up_profile', {
      params: data
    })
  };
}

export function getMenteeMasterFollowUpNotes(data) {
  return {
    types: MMGENERIC,
    promise: client =>
      client.get('/mentee_master_follow_up/get_mentee_master_follow_up_notes', {
        params: data,
      }),
  };
}

export function getMenteeMasterFollowUpCalls(data) {
  return {
    types: MMGENERIC,
    promise: client =>
      client.get('/mentee_master_follow_up/get_mentee_master_follow_up_calls', {
        params: data,
      }),
  };
}

export function getExternalEntityCallTxListByMenteeMasterFollowUpProfileId(params) {
  return {
    types: [MENTEE_MASTER_FOLLOWUP_REQUEST_GENERIC, MENTEE_MASTER_FOLLOWUP_SUCCESS_GENERIC, MENTEE_MASTER_FOLLOWUP_FAIL_GENERIC],
    promise: (client) => client.get('/mentee_master_follow_up/get_external_entity_call_tx_list_by_mentee_master_follow_up_profile_id', {
      params: params
    })
  };
}

export function getMenteeMasterFollowUpInactiveStatuses() {
  return {
    types: MMGENERIC,
    promise: client =>
      client.get('/mentee_master_follow_up/get_mentee_master_follow_up_list_inactive_statuses'),
  };
}

/*************** Setter ***************/

export function updateMenteeMasterFollowUp(data) {
  return {
    types: MMGENERIC,
    promise: client =>
      client.post('/mentee_master_follow_up/update_mentee_master_follow_up', {
        data: data,
      }),
  };
}

export function addMenteeMasterFollowUpNote(data) {
  return {
    types: MMGENERIC,
    promise: client =>
      client.post('/mentee_master_follow_up/add_mentee_master_follow_up_note', {
        data: data,
      }),
  };
}

export function editMenteeMasterFollowUpNote(id, updatedNote) {
  return {
    types: MMGENERIC,
    promise: client =>
      client.post('/mentee_master_follow_up/edit_mentee_master_follow_up_note', {
        params: {
          id: id,
          note: updatedNote
        },
      }),
  };
}

export function deleteMenteeMasterFollowUpNote(id) {
  return {
    types: MMGENERIC,
    promise: client =>
      client.post('/mentee_master_follow_up/delete_mentee_master_follow_up_note', {
        params: {
          id: id
        },
      }),
  };
}