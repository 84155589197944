const ISSUES_GENERIC_REQUEST = 'inquisit-app/partnerMenteeMessaging/ISSUES_GENERIC_REQUEST';
const ISSUES_GENERIC_SUCCESS = 'inquisit-app/partnerMenteeMessaging/ISSUES_GENERIC_SUCCESS';
const ISSUES_FAIL = 'inquisit-app/partnerMenteeMessaging/ISSUES_FAIL';

const initialState = {

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    default:
      return state;
  }
}

export function getPartnerMenteeMessages(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/get/portal/get_partner_mentee_messages', {
        params: data,
      }),
  };
}

export function markPartnerMenteeMessagesRead(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/set/portal/mark_partner_mentee_messages_read', {
        data: data,
      })
  }
}

export function addPartnerMenteeMessage(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/set/portal/add_partner_mentee_message', {
        data: data,
      }),
  };
}

export function movePartnerMenteeMessage(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/set/portal/move_partner_mentee_message', {
        data: data,
      }),
  };
}