const LOAD_REQUESTED = 'inquisit-app/supporter/LOAD_REQUESTED';
const GENERIC_SUCCESS = 'inquisit-app/supporter/GENERIC_SUCCESS';
const REQUEST_FAIL = 'inquisit-app/supporter/REQUEST_FAIL';


const initialState = {
};
const GENERIC = [LOAD_REQUESTED, GENERIC_SUCCESS, REQUEST_FAIL];
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

// GET

  // export function getMenteeRecipeGuider(data) {
  //   return {
  //     types: GENERIC,
  //     promise: (client) => client.get('recipe/get_recipe_guider', {
  //       params: data
  //     })
  //   };
  // }


// SET

export function setSupporterMatch(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('supporter/set_supporter_match', {
      data: data
    })
  };
}

export function unMatchSupporter(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('supporter/unmatch_supporter', {
      data: data
    })
  };
}

export function updateSupporterCallStatus(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('supporter/update_supporter_tx_call_status', {
      data: data
    })
  };
}