const CMS_SURVEY_REQUEST_GENERIC = 'inquisit-app/CMS/CMS_SURVEY_REQUEST_GENERIC';
const CMS_SURVEY_SUCCESS_GENERIC = 'inquisit-app/CMS/CMS_SURVEY_SUCCESS_GENERIC';
const CMS_SURVEY_FAIL_GENERIC = 'inquisit-app/CMS/CMS_SURVEY_FAIL_GENERIC';

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getRootIssues(rootId, conditions) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_root_issues', {
      params: {
        root_id: rootId,
        conditions
      }
    })
  };
}

export function getSurveySets(data) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_survey_sets', {
      params: data
    })
  };
}

export function getSurveySetData(ssId) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_survey_set_data', {
      params: {
        ss_id: ssId
      }
    })
  };
}

export function getExistingMasterQuestions(ssId) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_existing_master_questions', {
      params: {
        ss_id: ssId
      }
    })
  };
}

export function getSurveyQuestionsByLevel(params) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/survey_questions_by_level', {
      params: params
    })
  };
}

export function getSurveyQuestionsBySurveyIdAndLevel(params) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/survey_questions_by_survey_id_and_level', {
      params: params
    })
  };
}

export function getIssueTracks(iss_issue_ids) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_issue_tracks', {
      params: {
        iss_issue_ids: iss_issue_ids
      }
    })
  };
}

export function getMasterQuestionAnswerChoices(params) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_master_question_answer_choices', {
      params: params
    })
  };
}

export function getIssueCategories() {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_issue_categories')
  };
}

export function getRootConditionSurveySets(params) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_root_condition_survey_sets', {
      params: params
    })
  };
}

export function getSurveysForRecipeNode(params) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_surveys_for_recipe_node', {
      params: params
    })
  };
}

export function getSurveySetsProfileAccess() {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_survey_set_x_profile_access')
  };
}

export function getAdditionalMenteeSurveyConfig(params) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.get('getcmsdata/get_additional_mentee_survey_config', { params })
  };
}

export function insertSurveySet({title, description, selectedConditions, surveyTypeId, sequence, programOptionId, checkInInd, profileAccess}) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_survey_set', {
      data: {
        title: title,
        description,
        conditions: selectedConditions,
        survey_type_id: surveyTypeId,
        sequence,
        program_option_id: programOptionId,
        check_in_ind: checkInInd ? 1 : 0,
        profile_access: profileAccess,
        et_ind: 1
      }
    })
  };
}

export function insertSurveySetQuestionAssociation(data) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_survey_set_x_master_question', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function insertSurveySetQuestionAndAssociation(data) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_master_question', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function insertSurveySetQuestionAnswer(master_question_answer_id, master_question_id, answer) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_master_question_answer', {
      data: {
        master_question_answer_id,
        master_question_id,
        answer,
        et_ind: 1
      }
    })
  };
}

export function insertSurveySetQuestionSequence(master_question_answer_id, master_question_id, sequence) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_master_question_sequence', {
      data: {
        master_question_answer_id,
        master_question_id,
        sequence
      }
    })
  };
}

export function insertUpdateSurveySetQuestionResponseScore(master_question_answer_id, master_question_id, score) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_master_question_response_score', {
      data: {
        master_question_answer_id,
        master_question_id,
        score
      }
    })
  };
}

export function insertOrUpdateSurveyMasterQuestionIssueAssociation(set_x_master_question_id, master_question_answer_id, issues) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_survey_master_question_x_issue_association', {
      data: {
        set_x_master_question_id, 
        master_question_answer_id,
        issues,
        et_ind: 1
      }
    })
  };
}


export function insertSurveyAnswerCondition(data) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_master_question_response_condition', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function insertOrUpdateSurveyPathwayDecider(set_x_master_question_id, master_question_answer_id, kb_challenge_id, challenge_sequence) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_survey_pathway_decider', {
      data: {
        set_x_master_question_id, 
        master_question_answer_id,
        kb_challenge_id,
        challenge_sequence,
        et_ind: 1
      }
    })
  };
}

export function insertNLevelSurveySet(data) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_n_level_survey_set', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function updateSurveyQuestionRequiredIndicator(set_x_master_question_id, required_ind_value, update_required_ind){
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_n_level_survey_set', {
      data: {
        required_ind_value,
        set_x_master_question_id,
        update_required_ind,
        et_ind: 1
      }
    })
  };
}

export function removeSurveyQuestionGuider(guiderId) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/remove_survey_question_guider', {
      data: {
        guider_id: guiderId,
        et_ind: 1
      }
    })
  };
}

export function removeSurveyQuestionFromSet(set_x_master_question_id) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/remove_survey_question_from_set', {
      data: {
        set_x_master_question_id,
        et_ind: 1
      }
    })
  };
}

export function removeSurveyAnswerOption(set_x_master_question_id, master_question_answer_id) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/remove_survey_answer_option', {
      data: {
        set_x_master_question_id,
        master_question_answer_id,
        et_ind: 1
      }
    })
  };
}

export function removeLevelTwoQuestion(set_x_master_question_id) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/remove_level_two_question', {
      data: {
        set_x_master_question_id,
        et_ind: 1
      }
    })
  };
}

export function updateSurveyLevelTracks(set_x_master_question_id, master_question_answer_id, iss_track_id_arr) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_survey_level_tracks', {
      data: {
        set_x_master_question_id, 
        master_question_answer_id,
        iss_track_id_arr,
        et_ind: 1
      }
    })
  };
}
export function updateSurveyQuestionCondition(set_x_master_question_id, conditions) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_survey_set_question_conditions', {
      data: {
        set_x_master_question_id, 
        conditions,
        et_ind: 1
      }
    })
  };
}

export function updateSurveyQuestionOrganizations(set_x_master_question_id, orgs) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_survey_set_question_organizations', {
      data: {
        set_x_master_question_id, 
        orgs,
        et_ind: 1
      }
    })
  };
}

export function updateSurveyQuestionCategory(set_x_master_question_id, category) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_survey_set_question_category', {
      data: {
        set_x_master_question_id, 
        category,
        et_ind: 1
      }
    })
  };
}

export function updateSurveyQuestionProp(data) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_survey_set_question_prop', {
      data: {...data, et_ind: 1 }
    })
  };
}

export function updateSurveySetAssociation(ss_id, type, values) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_survey_set_association', {
      data: {
        ss_id, 
        type,
        values,
        et_ind: 1,
      }
    })
  };
}

export function updateSurveySetField(data) {
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/update_survey_set_field', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function insertUpdateSurveyQuestionGuider(data){
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/insert_update_survey_question_guider', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function saveRootOutreachAssignment(data){
  return {
    types: [CMS_SURVEY_REQUEST_GENERIC, CMS_SURVEY_SUCCESS_GENERIC, CMS_SURVEY_FAIL_GENERIC],
    promise: (client) => client.post('setcmsdata/save_root_outreach_assignment', {
      data: {...data, et_ind: 1 }
    })
  };
}
