const IVR_LOAD_REQUESTED = 'inquisit-app/ivr/IVR_LOAD_REQUESTED';
const IVR_GENERIC_SUCCESS = 'inquisit-app/ivr/IVR_GENERIC_SUCCESS';
const IVR_REQUEST_FAIL = 'inquisit-app/ivr/IVR_REQUEST_FAIL';

const initialState = {
};

const GENERIC = [IVR_LOAD_REQUESTED, IVR_GENERIC_SUCCESS, IVR_REQUEST_FAIL];

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getOcActiveCycle() {
  return {
    types: GENERIC,
    promise: (client) => client.get('/getivr/active_oc_ivr_cycle')
  };
}

export function getMenteeOutreachAvailableIvrCycles(orgs, filterDate, filter_tags, subentityIds) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/getivr/mentee_outreach_available_ivr_cycles', {
      params: {
        orgs: orgs,
        filter_date: filterDate,
        filter_tags: JSON.stringify(filter_tags),
        subentity_ids: subentityIds
      }
    })
  };
}

export function getPreviousMenteeOutreachIvrStats(orgs) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/getivr/previous_mentee_outreach_ivr_stats', {
      params: {
        orgs: orgs
      }
    })
  };
}

export function getOutreachSystemTags() {
  return {
    types: GENERIC,
    promise: (client) => client.get('/getivr/get_outreach_system_added_tags')
  };
}

export function startIvrCycle(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/setivr/start_ivr_cycle', {
      data: {
        et_ind: 1,
        ...data
      },
    })
  }
}

export function stopIvrCycle(ivrCycleId) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/setivr/stop_ivr_cycle', {
      data: {
        ivr_cycle_id: ivrCycleId,
        et_ind: 1
      }
    })
  }
}

export function resumeIvrCycle(ivrCycleId) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/setivr/resume_ivr_cycle', {
      data: {
        ivr_cycle_id: ivrCycleId,
        et_ind: 1
      }
    })
  }
}

export function joinExistingIvrCycle(ivrCycleInmemId) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/setivr/join_existing_ivr_cycle', {
      data: {
        ivr_cycle_inmem_id: ivrCycleInmemId,
        et_ind: 1
      }
    })
  }
}