const WORKBOOK_SUMMARY_GENERIC = 'inquisit-app/mentoringProgramSummary/WORKBOOK_SUMMARY_GENERIC';
const WORKBOOK_LIST_REQUESTED = 'inquisit-app/mentoringProgramSummary/WORKBOOK_LIST_REQUESTED';
const WORKBOOK_LIST_SUCCESS = 'inquisit-app/mentoringProgramSummary/WORKBOOK_LIST_SUCCESS';
const WORKBOOK_LIST_FAIL = 'inquisit-app/mentoringProgramSummary/WORKBOOK_LIST_FAIL';
const WORKBOOK_SUMMARY_STATS_REQUESTED = 'inquisit-app/mentoringProgramSummary/WORKBOOK_SUMMARY_STATS_REQUESTED';
const WORKBOOK_SUMMARY_STATS_SUCCESS = 'inquisit-app/mentoringProgramSummary/WORKBOOK_SUMMARY_STATS_SUCCESS';
const WORKBOOK_SUMMARY_STATS_FAIL = 'inquisit-app/mentoringProgramSummary/WORKBOOK_SUMMARY_STATS_FAIL';
const RESET_WORKBOOK_SUMMARY_STATS = 'inquisit-app/mentoringProgramSummary/RESET_WORKBOOK_SUMMARY_STATS';

const initialState = {
  workbooks: [],
  workbookSummaryStats: {},
  challengesActivitySummary: {},
  drivers: [],
  goals: [],
  minimodules: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case WORKBOOK_LIST_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case WORKBOOK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        workbooks: (action.result) ? action.result : [],
      };
    case WORKBOOK_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case WORKBOOK_SUMMARY_STATS_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case WORKBOOK_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        workbookSummaryStats: (action.result.workbook_summary_stats) ? action.result.workbook_summary_stats : {},
        challengesActivitySummary: (action.result.challenges_activity_summary) ? action.result.challenges_activity_summary : {},
        drivers: (action.result.drivers) ? action.result.drivers : [],
        goals: (action.result.goals) ? action.result.goals : [],
        goalUpdates: (action.result.goal_updates) ? action.result.goal_updates : [],
        coveredTalkingPoints: (action.result.covered_talking_points) ? action.result.covered_talking_points : [],
        checkinChallengeQuestions: (action.result.checkin_challenge_questions) ? action.result.checkin_challenge_questions : [],
        calls: (action.result.calls) ? action.result.calls : [],
        minimodules: (action.result.minimodules) ? action.result.minimodules : [],
      };
    case RESET_WORKBOOK_SUMMARY_STATS:
      return {
        ...state,
        loading: false,
        workbookSummaryStats: {},
        challengesActivitySummary: {},
        drivers: [],
        goals: [],
        goalUpdates: [],
        coveredTalkingPoints: [],
        checkinChallengeQuestions: [],
        calls: [],
        minimodules: [],
      };
    case WORKBOOK_SUMMARY_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getMenteeWorkbookList(data) {
  return {
    types: [WORKBOOK_LIST_REQUESTED, WORKBOOK_LIST_SUCCESS, WORKBOOK_LIST_FAIL],
    promise: (client) => client.get('/admin/getmentoringprogramsummary/mentee_workbook_list', {
      params: {
        mentee_id: data.mentee_id,
      }
    })
  };
}

export function getMenteeWorkbookSummaryStats(data) {
  return {
    types: [WORKBOOK_SUMMARY_STATS_REQUESTED, WORKBOOK_SUMMARY_STATS_SUCCESS, WORKBOOK_SUMMARY_STATS_FAIL],
    promise: (client) => client.get('/admin/getmentoringprogramsummary/mentee_workbook_summary_stats', {
      params: {
        mpn_id: data.mpnId,
        mpb_pathway_id: data.mpbPathwayId,
      }
    })
  };
}

export function upsertCallQualityScore(object) {
  return {
    types: [WORKBOOK_SUMMARY_GENERIC, WORKBOOK_SUMMARY_GENERIC, WORKBOOK_SUMMARY_GENERIC],
    promise: (client) => client.post('/admin/setmenteedata/upsert_call_quality_score', {
      data: object
    })
  };
}

export function resetWorkbookSummaryStats() {
  return {
    type: RESET_WORKBOOK_SUMMARY_STATS,
    result: {}
  };
}

