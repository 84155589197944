const CMS_RECIPE_REQUEST_GENERIC = 'inquisit-app/CMS/CMS_RECIPE_REQUEST_GENERIC';
const CMS_RECIPE_SUCCESS_GENERIC = 'inquisit-app/CMS/CMS_RECIPE_SUCCESS_GENERIC';
const CMS_RECIPE_FAIL_GENERIC = 'inquisit-app/CMS/CMS_RECIPE_FAIL_GENERIC';
const RECIPE_FLOW = 'inquisit-app/CMS/RECIPE_FLOW';
const GENERIC_TYPES = [CMS_RECIPE_REQUEST_GENERIC, CMS_RECIPE_SUCCESS_GENERIC, CMS_RECIPE_FAIL_GENERIC];

const initialState = {
  copiedRecipeFlow: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    case RECIPE_FLOW:
      return {
        ...state,
        copiedRecipeFlow: action.result.data
      };
    default:
      return state;
  }
}

// GET

export function getRecipeSubTypes(slug, conditions){
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/recipe/get_recipe_sub_types', {
      params: {
        slug,
        conditions
      }
    })
  };
}
export function getRecipeMtTypes(){
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/recipe/get_recipe_mt_types', {
      params: {}
    })
  };
}

export function getRecipeInstances(params) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/recipe/get_recipe_instances', {
      params
    })
  };
}

export function getRecipeGuiderTree(params) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/recipe/get_recipe_guider_tree', {
      params
    })
  };
}

export function getRecipeGuiderTreeByVersion(params) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/recipe/get_recipe_guider_tree_by_version', {
      params
    })
  };
}

export function getRecipeTracks(params) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/recipe/get_recipe_instance_tracks', {
      params
    })
  };
}

export function getRecipeTrackSteplets(params) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/recipe/get_recipe_instance_track_steplets', {
      params
    })
  };
}

export function getRecipeTrackSteps(params) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/recipe/get_recipe_instance_track_steps', {
      params
    })
  };
}

export function getRecipeIntentMasterData(params) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/recipe/get_recipe_intent_master_data', {
      params
    })
  };
}

export function getRecipeActions(params) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/recipe/get_recipe_actions', {
      params
    })
  };
}




// POST

export function copyRecipeFlow(data) {
  return {
    type: RECIPE_FLOW,
    result: {
      data
    }
  };
}

export function insertUpdateRecipe(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/recipe/insert_update_recipe', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function processAndStoreRecipeTree(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/recipe/process_and_store_recipe_tree', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function publishRecipeGuiderVersion(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/recipe/publish_recipe_guider_version', {
      data: { ...data, et_ind: 1 }
    })
  };
}

