const CHANGED_ES_SCREEN_COLUMNS = "CHANGE_ENGAGED_SUPPORT_MENTEE_PROFILE_COLUMNS"; 
const CHANGED_MENTOR_DETAIL_COLUMNS = "CHANGED_MENTOR_DETAIL_COLUMNS";
const initialState = {
  es_mentee_profile_columns: {
    col1: 3, 
    col2: 3, 
    col3: 3, 
    col4: 3
  }, 
  mentor_detail_columns: {
    col1: 9,
    col2: 3
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGED_ES_SCREEN_COLUMNS:
      return {
        ...state,
        es_mentee_profile_columns: {...state.es_mentee_profile_columns, ...action.data }
      };
    case CHANGED_MENTOR_DETAIL_COLUMNS: 
      return {
        ...state,
        mentor_detail_columns: {...state.mentor_detail_columns, ...action.data }
      };
    default:
      return state;
  }
}

// Get
export function changeESMenteeProfileColumns(data) {
  return {
    type: CHANGED_ES_SCREEN_COLUMNS,
    data: data
  };
}

export function changeMentorDetailColumns(data) {
  return {
    type: CHANGED_MENTOR_DETAIL_COLUMNS, 
    data: data
  }  
}