const SET_INBOUND_CALL_DEVICE = 'inquisit-app/inboundTelephony/SET_INBOUND_CALL_DEVICE';
const SET_INBOUND_CALL_CONNECTION = 'inquisit-app/inboundTelephony/SET_INBOUND_CALL_CONNECTION';
const SET_INBOUND_CALL_MODAL_VISIBILITY = 'inquisit-app/inboundTelephony/SET_INBOUND_CALL_MODAL_VISIBILITY';
const SET_INBOUND_CALL_USER_PROPERTIES = 'inquisit-app/inboundTelephony/SET_INBOUND_CALL_USER_PROPERTIES';
const SET_INBOUND_THIRD_PARTY_CALL_USER_PROPERTIES = 'inquisit-app/inboundTelephony/SET_INBOUND_THIRD_PARTY_CALL_USER_PROPERTIES';
const SET_INBOUND_CALL_PROGRESS = 'inquisit-app/inboundTelephony/SET_INBOUND_CALL_PROGRESS';
const SET_INBOUND_CONFERENCING_OPTIONS = 'inquisit-app/inboundTelephony/SET_INBOUND_CONFERENCING_OPTIONS';
const START_CONFERENCE_SUCCESS = 'inquisit-app/inboundTelephony/START_CONFERENCE_SUCCESS';
const REMOVE_CONFERENCING_OPTIONS = 'inquisit-app/outboundTelephony/REMOVE_CONFERENCING_OPTIONS';
const RESET_TELEPHONY_DATA = 'inquisit-app/inboundTelephony/RESET_TELEPHONY_DATA';

const INBOUND_TELEPHONY_GENERIC_REQUEST = 'inquisit-app/inboundTelephony/INBOUND_TELEPHONY_GENERIC_REQUEST';
const INBOUND_TELEPHONY_GENERIC_SUCCESS = 'inquisit-app/inboundTelephony/INBOUND_TELEPHONY_GENERIC_SUCCESS';
const INBOUND_TELEPHONY_GENERIC_FAIL = 'inquisit-app/inboundTelephony/INBOUND_TELEPHONY_GENERIC_FAIL';

const initialState = {
  conferencingOptions: [],
  inboundCallDevice: false,
  inboundCallConnection: false,
  inboundCallUserProperties: {},
  inboundThirdPartyCallUserProperties: {},
  inboundCallModalVisible: false,
  inboundCallInProgress: false,
  inboundConferenceId: null,
  inboundConferenceType: null,
  inboundExternalEntityTxId: null,
};

export default function reducer(state = initialState, action = {}) {
  let index = -1;
  let conferencingOptions = state.conferencingOptions;
  switch (action.type) {
    case SET_INBOUND_CALL_DEVICE:
      return {
        ...state,
        inboundCallDevice: action.data,
      };
    case SET_INBOUND_CALL_CONNECTION:
      if(!action.data && state.inboundCallConnection){
        state.inboundCallConnection.removeAllListeners();
      }
      return {
        ...state,
        inboundCallConnection: action.data,
      };
    case SET_INBOUND_CALL_MODAL_VISIBILITY:
      return {
        ...state,
        inboundCallModalVisible: action.data,
      };
    case SET_INBOUND_CALL_USER_PROPERTIES:
      return {
        ...state,
        inboundCallUserProperties: action.data,
      };
    case SET_INBOUND_THIRD_PARTY_CALL_USER_PROPERTIES:
      return {
        ...state,
        inboundThirdPartyCallUserProperties: action.data,
      };
    case SET_INBOUND_CALL_PROGRESS:
      return {
        ...state,
        inboundCallInProgress: action.data,
      };
    case SET_INBOUND_CONFERENCING_OPTIONS:
      return {
        ...state,
        conferencingOptions: action.data,
      };
    case REMOVE_CONFERENCING_OPTIONS:
      if(action.data.number){
        index = conferencingOptions.findIndex((obj) => obj.number===action.data.number);
        if(index !== -1) conferencingOptions.splice(index,1);
      } else if (action.data.menteeId){
        conferencingOptions = conferencingOptions.filter((obj) => obj.menteeId!==action.data.menteeId);
      } else if (action.data.menteeOutreachId){
        conferencingOptions = conferencingOptions.filter((obj) => obj.menteeOutreachId!==action.data.menteeOutreachId);
      } else if (action.data.callType){
        conferencingOptions = conferencingOptions.filter((obj) => obj.callType!==action.data.callType);
      }
      return {
        ...state,
        conferencingOptions: conferencingOptions
      };
    case START_CONFERENCE_SUCCESS:
      return {
        ...state,
        inboundConferenceId: action.result && action.result.success ? action.result.conference_id : null,
        inboundConferenceType: action.result && action.result.success ? action.result.conferenceType : null,
        inboundExternalEntityTxId: action.result && action.result.success ? action.result.external_entity_tx_id : null,
      };
    case RESET_TELEPHONY_DATA:
      return initialState;
    default:
      return state;
  }
}

export function setInboundCallDevice(value) {
  return {
    type: SET_INBOUND_CALL_DEVICE,
    data: value
  };
}

export function setInboundCallConnection(value) {
  return {
    type: SET_INBOUND_CALL_CONNECTION,
    data: value
  };
}

export function setInboundCallUserProperties(callUserPropertiesObject) {
  return {
    type: SET_INBOUND_CALL_USER_PROPERTIES,
    data: callUserPropertiesObject
  };
}

export function setInboundThirdPartyCallUserProperties(callUserPropertiesObject) {
  return {
    type: SET_INBOUND_THIRD_PARTY_CALL_USER_PROPERTIES,
    data: callUserPropertiesObject
  };
}

export function setInboundCallModalVisibility(flag) {
  return {
    type: SET_INBOUND_CALL_MODAL_VISIBILITY,
    data: flag
  };
}

export function setInboundCallProgress(flag) {
  return {
    type: SET_INBOUND_CALL_PROGRESS,
    data: flag
  };
}


export function setInboundConferencingOptions(data) {
  return {
    type: SET_INBOUND_CONFERENCING_OPTIONS,
    data: data
  };
}

export function removeInboundConferencingOption(data) {
  return {
    type: REMOVE_CONFERENCING_OPTIONS,
    data: data,
  };
}

export function transferInboundCallToConference(data) {
  return {
    types: [INBOUND_TELEPHONY_GENERIC_REQUEST,START_CONFERENCE_SUCCESS,INBOUND_TELEPHONY_GENERIC_FAIL],
    promise: (client) => client.post('/outboundcalltelephony/transfer_call_to_conference_for_three_way', {
      data: data
    })
  };
}

export function addCallToConference(data) {
  return {
    types: [INBOUND_TELEPHONY_GENERIC_REQUEST,INBOUND_TELEPHONY_GENERIC_SUCCESS,INBOUND_TELEPHONY_GENERIC_FAIL],
    promise: (client) => client.post('/outboundcalltelephony/add_call_to_conference', {
      data: data
    })
  };
}

export function resetInboundTelephony() {
  return {
    type: RESET_TELEPHONY_DATA,
    data: false
  };
}
