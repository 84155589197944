const ISSUES_GENERIC_REQUEST = 'inquisit-app/issues/ISSUES_GENERIC_REQUEST';
const ISSUES_DETAILS_SUCCESS = 'inquisit-app/issues/ISSUES_DETAILS_SUCCESS';
const ISSUES_GENERIC_SUCCESS = 'inquisit-app/issues/ISSUES_GENERIC_SUCCESS';
const ISSUES_LIST_SUCCESS = 'inquisit-app/issues/ISSUES_LIST_SUCCESS';
const ISSUES_DETAILS_REQUEST = 'inquisit-app/issues/ISSUES_DETAILS_REQUEST';

const STATUS_SUCCESS = 'inquisit-app/issues/STATUS_SUCCESS';
const UPDATE_ISSUES_FULL_TREE = 'inquisit-app/issues/UPDATE_ISSUES_FULL_TREE';
const UPDATE_ISSUES_LIST = 'inquisit-app/issues/UPDATE_ISSUES_LIST';
// const UPDATE_TRACKS_GROUPED = 'inquisit-app/issues/UPDATE_TRACKS_GROUPED';

const ISSUES_FAIL = 'inquisit-app/issues/ISSUES_FAIL';
// Steplet requests
const ISSUES_STEPLET_REQUEST = 'inquisit-app/issues/ISSUES_STEPLET_REQUEST';
const ISSUES_STEPLET_SUCCESS = 'inquisit-app/issues/ISSUES_STEPLET_SUCCESS';

const initialState = {
  loading: true,
  issuesFullTree: [],
  // tracksGrouped: [],
  issuesList: [],
  stepStatusList: [],
  trackStatusList: [],
  issueStatusList: [],
  actionRequiredStepsInd: false,
  actionRequiredStepsArr: [],
  stepletsByIds: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // case ISSUES_SUCCESS:
    //   return {
    //     ...state,
    //     issuesList: action.result.issues_list
    //   };
    case ISSUES_DETAILS_REQUEST:
      return {
        ...state,
        issuesFullTree: [],
        issuesList: [],
        actionRequiredStepsInd: false,
        actionRequiredStepsArr: [],
        loading: true,
      };
    case ISSUES_DETAILS_SUCCESS:
      return {
        ...state,
        issuesFullTree: action.result.issues_full_tree,
        issuesList: action.result.issues_list ? action.result.issues_list : [],
        actionRequiredStepsInd: action.result.action_required_steps_ind
          ? action.result.action_required_steps_ind
          : false,
        actionRequiredStepsArr: action.result.action_required_steps_arr
          ? action.result.action_required_steps_arr
          : [],
        loading: action.result.loading,
      };
    case ISSUES_LIST_SUCCESS:
      return {
        ...state,
        issuesList: action.result.issues_list ? action.result.issues_list : [],
      };
    case STATUS_SUCCESS:
      return {
        ...state,
        issueStatusList: action.result.issue_status_list ? action.result.issue_status_list : [],
        trackStatusList: action.result.track_status_list ? action.result.track_status_list : [],
        stepStatusList: action.result.step_status_list ? action.result.step_status_list : [],
      };
    case UPDATE_ISSUES_FULL_TREE:
      return {
        ...state,
        issuesFullTree: action.result,
      };

    case UPDATE_ISSUES_LIST:
      return {
        ...state,
        issuesList: action.result
      };

    case ISSUES_STEPLET_SUCCESS: {
      let steplets = [];
      if (action.result.steplets)
        steplets = action.result.steplets.map(s => ({ [s.id]: s }));
      return {
        ...state,
        stepletsByIds: Object.assign({}, ...steplets),
      };
    }

    default:
      return state;
  }
}

/*
  *******
  *******
   Get
  *******
  *******
  */

export function getIssuesDetails(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/mentee_issues_details', {
        params: data,
      }),
  };
}

// export function getIssuesList(data) {
//   return {
//     types: [ISSUES_GENERIC_REQUEST, ISSUES_LIST_SUCCESS, ISSUES_FAIL],
//     promise: client =>
//       client.get('/issues/issues_list', {
//         params: data,
//       }),
//   };
// }

// export function getTracksAndStepsDetails(data) {
//   return {
//     types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
//     promise: (client) => client.get('/issues/mentee_tracks_and_steps_details', {
//       params: data
//     })
//   };
// }

// export function getIssuesList(data) {
//   return {
//     types: [ISSUES_GENERIC_REQUEST, ISSUES_SUCCESS, ISSUES_FAIL],
//     promise: (client) => client.get('/issues/mentee_issues_list', {
//       params: data
//     })
//   };
// }
export function getIssueProps(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/get_issue_props', {
        params: data,
      }),
  };
}

export function getAddIssuesList(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/add_issues_list', {
        params: data,
      }),
  };
}

export function getAddTracksList(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/add_tracks_list', {
        params: data,
      }),
  };
}

export function getIssueTrackStepStatusList() {
  return {
    types: [ISSUES_GENERIC_REQUEST, STATUS_SUCCESS, ISSUES_FAIL],
    promise: client => client.get('/issues/get_issue_track_step_status_list'),
  };
}

export function getTrackInactiveSteps(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/track_inactive_steps', {
        params: data,
      }),
  };
}

export function getEspStepHistory(esp_step_id) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/step_history', {
        params: { esp_step_id },
      }),
  };
}

export function getTrackHistory(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/track_history', {
        params: data,
      }),
  };
}

export function getEspStepSteplets(espTrackStepId, menteeId, issStepId, resetGoalSteplet = 0) {
  return {
    types: [ISSUES_STEPLET_REQUEST, ISSUES_STEPLET_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/step_steplet', {
        params: {
          esp_track_step_id: espTrackStepId,
          mentee_id: menteeId,
          iss_step_id: issStepId,
          reset_goal_steplet: resetGoalSteplet,
        },
      }),
  };
}

export function generatePreviewImage(url) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/generate_preview_image', {
        params: {
          url: url,
        },
      }),
  };
}

export function getIssueFeedbackQuestions(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/issue_feedback_questions', {
        params: data,
      }),
  };
}

export function getStepThread(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/step_thread', {
        params: data,
      }),
  };
}

export function getDietActivity(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/dietactivity/get_diet_activity', {
        params: data,
      }),
  };
}

export function getDietActivityFeedbackPreview(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/dietactivity/get_diet_activity_feedback_preview', {
        params: data,
      }),
  };
}

export function getDietActivityInstance(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/dietactivity/get_diet_activity_instance', {
        params: data,
      }),
  };
}
export function getActiveMenteesOnTrack(data){
  return{
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client => 
      client.get('issues/get_active_mentees_on_track', {
        params: data,
    })
  };
}

export function getActiveMenteesforStep(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/active_mentees_by_step', {
        params: data,
      })
  }
}

export function getIrrelevantReasons(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/get_irrelevant_reasons', {
        params: data
      })
  }
}

export function getTrackVersionHistory(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/get_track_version_history', {
        params: data
      })
  }
}

export function getMenteesWTrackAndStatus(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.get('/issues/get_mentees_w_track_and_status', {
        params: data
      })
  }
}


/*
  *******
  *******
   Set
  *******
  *******
  */
export function updateIssuesFullTree(data) {
  return {
    type: UPDATE_ISSUES_FULL_TREE,
    result: data,
  };
}

export function updateIssuesList(data) {
  return {
    type: UPDATE_ISSUES_LIST,
    result: data,
  };
}

export function updateESTrackStep(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/update_es_track_step', {
        data,
      }),
  };
}

export function addIssues(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/add_issues', {
        data: data,
      }),
  };
}

export function addTracks(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/add_tracks', {
        data,
      }),
  };
}

export function addUpdateSteplet(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/update_steplet', {
        data: data,
      }),
  };
}

export function resendSteplet(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/resend_steplet', {
        data: data,
      }),
  };
}

export function updateStepletStatusWithReason(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/update_steplet_status_with_reason', {
        data: data,
      }),
  };
}

export function activateStepOption(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/activate_step_option', {
        data,
      }),
  };
}

export function updateESTrack(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/update_es_track', {
        data: data,
      }),
  };
}

export function updateMultipleESTracks(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/update_multiple_es_tracks', {
        data: data,
      }),
  };
}

export function updateESIssue(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/update_es_issue', {
        data: data,
      }),
  };
}

export function storeIssueStatusFeedback(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/store_issue_status_feedback', {
        data: data,
      }),
  };
}

export function addStepThreadMessage(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/add_message_to_thread', {
        data: data,
      }),
  };
}

export function deleteStepThreadMessage(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/delete_message_from_thread', {
        data: data,
      }),
  };
}

export function undoMarkStepComplete(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/undo_mark_step_complete', {
        data,
      }),
  };
}

// export function updateTracksGrouped(data) {
//   return {
//     type: UPDATE_TRACKS_GROUPED, 
//     data: data
//   }  
// }

export function updateDietActivity(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/dietactivity/update_diet_activity', {
        data: data,
      }),
  };
}

export function deleteDietActivityItem(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/dietactivity/delete_diet_activity_item', {
        data: data,
      }),
  };
}

export function saveDietActivityFeedback(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/dietactivity/insert_diet_activity_feedback', {
        data: data,
      }),
  };
}

export function sendDietActivityFeedback(data) {
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: client =>
      client.post('/issues/dietactivity/send_diet_activity_feedback', {
        data: data,
      }),
  };
}

export function removeAssociatedIssuewithTrack(data){
  return {
    types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
    promise: (client) => client.post('/issues/request_to_delete_track_associated_issue', {
      data: data
    })
  };
}

export function requestToRemoveStep(data){
  return {types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
  promise: client => 
    client.post('/issues/request_to_delete_step', {
      data: data,
    })
  }
}

export function toggleDesignateImportantIssue(data) {
  return {types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
  promise: client => 
    client.post('/issues/toggle_designate_important_issue', {
      data: data,
    })
  }
}

export function autoPinIssue(data) {
  return {types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
  promise: client => 
    client.post('/issues/auto_pin_issue', {
      data: data,
    })
  }
}

export function changeIssueStatus(data) {
  return {types: [ISSUES_GENERIC_REQUEST, ISSUES_GENERIC_SUCCESS, ISSUES_FAIL],
  promise: client => 
    client.post('/issues/change_issues_status', {
      data: data,
    })
  }
}

export function resetIssuesStore() {
  return {
    type: ISSUES_DETAILS_REQUEST,
    result: {
    }
  };
}