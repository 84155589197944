import store from '../store';
import moment from 'moment-timezone';
import firebase from 'Firebase.js';
import 'firebase/compat/database';
import { fbRefreshToken, updateUser } from 'redux/modules/auth';
import { MatomoHelper } from 'helpers/MatomoHelper';


export const FirebaseTrackerHelper = {
  trackRoute() { // Currently tracks for mentors only
    const user = store.getState().auth.user;
    if (user) {
      MatomoHelper.trackPageView({documentTitle: window.location.hash, href: window.location.href});
      if (user.group === 2 && user.is_super_admin !== 1) {
        try {
          const datetime = moment().format('YYYY-MM-DD HH:mm:ss');
          const path = window.location.hash;
          let menteeId = 0;
          if (window.location.hash.indexOf('/mentee/') > -1) {
            menteeId = path.replace('#/es/mentee/', '').split('/')[0];
          }
          const session_id = user.last_login ? user.last_login : user.id;
          if (firebase.auth().currentUser) {
            let mentorUserRef = firebase.database().ref('session_tracking/mentor/' + user.id + '/' + session_id);
            mentorUserRef.push({mentor_id: user.id, session_id: session_id, event_type: 'auto', mentee_id: menteeId, path: path, datetime: datetime}); 
          } else {
            firebase.auth().signInWithCustomToken(user.fb_token).catch((error) =>  {
              // Handle Errors here.
              let errorCode = error.code;
              if (errorCode === 'auth/invalid-custom-token') {
                store.dispatch(fbRefreshToken()).then((res) => {
                  if (res.result) {
                    firebase.auth().signInWithCustomToken(res.result).then(() =>  {
                      store.dispatch(updateUser({'fb_token': res.result}));
                    });
                  }
                });
              }
            });
          }
        } catch(e){
        } 
      }
    }
  },
  trackEvent(eventName, additionalData = false) {
    const user = store.getState().auth.user;
    if (user) {
      if (user.group === 2 && user.is_super_admin !== 1) {
        try {
          const datetime = moment().format('YYYY-MM-DD HH:mm:ss');
          const path = window.location.hash;
          let menteeId = 0;
          if (window.location.hash.indexOf('/mentee/') > -1) {
            menteeId = path.replace('#/es/mentee/', '').split('/')[0];
          }
          const session_id = user.last_login ? user.last_login : user.id;
        let mentorUserRef = firebase.database().ref('session_tracking/mentor/' + user.id + '/' + session_id);
          mentorUserRef.push({mentor_id: user.id, session_id: session_id, event_type: 'custom', mentee_id: menteeId, path: path, event: eventName, datetime: datetime, additional_data: (additionalData) ? additionalData : null});  
        } catch(e){} 
      }
    }
  }
};

