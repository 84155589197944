const ACTION_PLAN_GENERIC_REQUEST = 'inquisit-app/action_plan/ACTION_PLAN_GENERIC_REQUEST';
const ACTION_PLAN_GENERIC_SUCCESS = 'inquisit-app/action_plan/ACTION_PLAN_GENERIC_SUCCESS';
const ACTION_PLAN_GENERIC_FAIL = 'inquisit-app/action_plan/ACTION_PLAN_GENERIC_FAIL';

const TOGGLE_ACTION_PLAN = 'inquisit-app/action_plan/TOGGLE_ACTION_PLAN';

const ACTION_PLAN_SUCCESS = 'inquisit-app/action_plan/ACTION_PLAN_SUCCESS';
const ACTION_PLAN_LOADING = 'inquisit-app/action_plan/ACTION_PLAN_LOADING';
const ACTION_PLAN_FAIL = 'inquisit-app/action_plan/ACTION_PLAN_FAIL';
const UPDATE_ACTION_PLAN_FIELD = 'inquisit-app/action_plan/UPDATE_ACTION_PLAN_FIELD';
const START_ACTION_PLAN_SUCCESS = 'inquisit-app/action_plan/START_ACTION_PLAN_SUCCESS';
const ACTION_PLAN_TASKS_SUCCESS = 'inquisit-app/action_plan/ACTION_PLAN_TASKS_SUCCESS';
const ADD_ACTION_PLAN_TASK = 'inquisit-app/action_plan/ADD_ACTION_PLAN_TASK';
const UPDATE_ACTION_PLAN_TASK = 'inquisit-app/action_plan/UPDATE_ACTION_PLAN_TASK';
const ACTION_PLAN_TASK_LOADING = 'inquisit-app/action_plan/ACTION_PLAN_TASK_LOADING';
const DELETE_ACTION_PLAN_TASK = 'inquisit-app/action_plan/DELETE_ACTION_PLAN_TASK';
const RESET_ACTION_PLAN = 'inquisit-app/action_plan/RESET_ACTION_PLAN';


const GENERIC = [ACTION_PLAN_GENERIC_REQUEST, ACTION_PLAN_GENERIC_SUCCESS, ACTION_PLAN_GENERIC_FAIL];
const initialState = {
  showActionPlan: false,
  loading: false,
  actionPlan: {},
  actionPlanTasks: [],
  actionPlanTasksLoading: false,
  actionPlanLoading: true
};

export default function reducer(state = initialState, action = {}) {
  let {actionPlanTasks} = state;
  let {actionPlan} = state;

  switch (action.type) {
    case RESET_ACTION_PLAN: 
      return {
        ...initialState
      }
    case ACTION_PLAN_TASK_LOADING: 
      return {
        ...state,
        actionPlanTasksLoading: true,
      }
    case TOGGLE_ACTION_PLAN:
      return {
        ...state,
        showActionPlan: !state.showActionPlan,
      }
    case ACTION_PLAN_LOADING:
      return {
        ...state,
        actionPlanLoading: true
      }
    case ACTION_PLAN_SUCCESS:
      return {
        ...state,
        actionPlan: action.result && action.result.success && action.result.action_plan ? action.result.action_plan : {},
        actionPlanLoading: false
      }
    case ACTION_PLAN_FAIL:
      return {
        ...state,
        actionPlanLoading: false
      }
    case START_ACTION_PLAN_SUCCESS:
      actionPlan.status=2;
      return {
        ...state,
        actionPlan: actionPlan,
      }
    case ACTION_PLAN_TASKS_SUCCESS:
      return {
        ...state,
        actionPlanTasks: action.result && action.result.success && action.result.task_list ? action.result.task_list : [],
        actionPlanTasksLoading: false,
      }
    case ADD_ACTION_PLAN_TASK:
      
      if(action.result && action.result.task) actionPlanTasks.push(action.result.task);
      return {
        ...state,
        actionPlanTasks: actionPlanTasks,
        actionPlanTasksLoading: false,
      }
    case UPDATE_ACTION_PLAN_TASK:
      if(action.result && action.result.task){
        let index = actionPlanTasks.findIndex((oldTask) => oldTask.id===action.result.task.id);
        if(index>-1){
          actionPlanTasks[index] = action.result.task;
        }else {
          actionPlanTasks.push(action.result.task);
        }
      }
      return {
        ...state,
        actionPlanTasks: actionPlanTasks,
        actionPlanTasksLoading: false,
      }
    case DELETE_ACTION_PLAN_TASK:
      if(action.data && action.data.actpl_task_id)  actionPlanTasks = actionPlanTasks.filter((task) => {return task.id!==action.data.actpl_task_id});
      return {
        ...state,
        actionPlanTasks: actionPlanTasks,
      }
    case UPDATE_ACTION_PLAN_FIELD:
      if(actionPlan && actionPlan.id && actionPlan.id===action.data.action_plan_id){
        actionPlan[action.data.field_name] = action.data.field_value;
      }
      return {
        ...state,
        actionPlan: actionPlan,
      }
    default:
      return state;
  }
}

//Redux State Functions

export function toggleActionPlan() {
  return {
    type: TOGGLE_ACTION_PLAN,
  };
}

export function resetActionPlan() {
  return {
    type: RESET_ACTION_PLAN,
  };
}

// Get

export function getActionPlan(data) {
  return {
    types: [ACTION_PLAN_TASK_LOADING, ACTION_PLAN_SUCCESS, ACTION_PLAN_FAIL],
    promise: (client) => client.get('/action_plan/get_action_plan', {
      params: data
    })
  };
}

export function getActionPlanTasks(data) {
  return {
    types: [ACTION_PLAN_GENERIC_REQUEST, ACTION_PLAN_TASKS_SUCCESS, ACTION_PLAN_GENERIC_FAIL],
    promise: (client) => client.get('/action_plan/get_action_plan_tasks', {
      params: data
    })
  };
}

export function getDetailsForTask(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/action_plan/get_details_for_task', {
      params: data
    })
  };
}

export function getMasterTasks(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/action_plan/get_master_tasks', {
      params: data
    })
  };
}

export function getActionPlanTypes(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/action_plan/get_action_plan_types', {
      params: data
    })
  };
}

export function getActionPlanNotes(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/action_plan/get_mentee_action_plan_notes', {
      params: data
    })
  };
}

export function checkForTaskNotInNewPlanType(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/action_plan/check_for_task_not_in_plan_type', {
      params: data
    })
  };
}




// Post

export function createActionPlan(data) {
  return {
    types: [ACTION_PLAN_LOADING, ACTION_PLAN_SUCCESS, ACTION_PLAN_GENERIC_FAIL],
    promise: client =>
      client.post('/action_plan/create_action_plan', {
        data: data,
      }),
  };
} 

export function startActionPlan(data) {
  return {
    types: [ACTION_PLAN_GENERIC_REQUEST, START_ACTION_PLAN_SUCCESS, ACTION_PLAN_GENERIC_FAIL],
    promise: client =>
      client.post('/action_plan/start_action_plan', {
        data: data,
      }),
  };
}

export function updateActionPlanField(data) {
  return {
    types: [UPDATE_ACTION_PLAN_FIELD, ACTION_PLAN_GENERIC_SUCCESS , ACTION_PLAN_GENERIC_FAIL],
    data: data,
    promise: client =>
      client.post('/action_plan/update_action_plan_field', {
        data: data,
      }),
  };
}

export function createActionPlanTask(data) {
  return {
    types: [ACTION_PLAN_TASK_LOADING, ADD_ACTION_PLAN_TASK, ACTION_PLAN_GENERIC_FAIL],
    promise: client =>
      client.post('/action_plan/create_action_plan_task', {
        data: data,
      }),
  };
}

export function updateActionPlanTask(data) {
  return {
    types: [ACTION_PLAN_TASK_LOADING, UPDATE_ACTION_PLAN_TASK, ACTION_PLAN_GENERIC_FAIL],
    promise: client =>
      client.post('/action_plan/update_action_plan_task', {
        data: data,
      }),
  };
}

export function addActionPlanNote(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/action_plan/add_action_plan_note', {
        data: data,
      }),
  };
}

export function deleteActionPlanTask(data) {
  return {
    types: [ACTION_PLAN_GENERIC_REQUEST, DELETE_ACTION_PLAN_TASK, ACTION_PLAN_GENERIC_FAIL],
    data: data,
    promise: client =>
      client.post('/action_plan/delete_action_plan_task', {
        data: data,
      }),
  };
}

export function updateActionPlanNote(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/action_plan/update_action_plan_note', {
        data: data,
      }),
  };
}

export function deleteActionPlanNote(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/action_plan/delete_action_plan_note', {
        data: data,
      }),
  };
}