// @flow
const GENERIC_REQUEST = 'inquisit-app/menteeSOP/GENERIC';

const GENERIC = [GENERIC_REQUEST, GENERIC_REQUEST, GENERIC_REQUEST];

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
 
  switch (action.type) {
    default:
      return state;
  }
}

/*
 * GET
 */

export function getMenteeSOPList(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('mentee/get_mentee_sop_list', {
      params: data
    })
  };
}

export function getSOPMenteeList(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('mentee/get_sop_mentee_list', {
      params: data
    })
  };
}

export function getMenteeSOPTimeline(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('mentee/get_mentee_sop_timeline', {
      params: data
    })
  };
}


/*
 * POST
 */


export function resumeMenteeSOP(data) {
  return {
    types: GENERIC,
    promise: client => client.post('mentee/resume_sop',{
      data: data
    })
  };
}

export function suspendMenteeSOP(data) {
  return {
    types: GENERIC,
    promise: client => client.post('mentee/suspend_sop',{
      data: data
    })
  };
}

export function resolveMenteeSOP(data) {
  return {
    types: GENERIC,
    promise: client => client.post('mentee/resolve_sop',{
      data: data
    })
  };
}

