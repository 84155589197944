const GENERIC_REQUEST = 'inquisit-app/COMMAND_CENTER/GENERIC_REQUEST';
const GENERIC_FAIL = 'inquisit-app/COMMAND_CENTER/GENERIC_FAIL';
const GENERIC_SUCCESS = 'inquisit-app/COMMAND_CENTER/GENERIC_SUCCESS';

const GENERIC = [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL];

const initialState = {
}

export default function reducer(state = initialState, action = {}) {
  switch(action.type) {
    default:
      return state;
  }
}

export function getAorTicketCount(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/cc/tickets/get_aor_ticket_count', {
      params: params
    }),
  };
}

export function getTicketListByType(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/cc/tickets/get_ticket_list_by_type', {
      params: params
    }),
  };
}

export function getCompensationNodeDetails(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/cc/tickets/get_closed_workbook_details', {
      params: params
    }),
  };
}

export function getManagementAccess(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/cc/tickets/get_management_access', {
      params: params
    }),
  };
}

export function getTicketStatistics(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/cc/tickets/get_ticket_statistics', {
      params: params
    }),
  };
}

export function getTicketStatisticsFilters(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/cc/tickets/get_ticket_statistics_filter_data', {
      params: params
    }),
  };
}

export function checkTicketAssignment(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/cc/tickets/check_ticket_assignment_by_user', {
      params: params
    }),
  };
}

export function getTicketNotes (params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/cc/tickets/get_ticket_notes', {
      params: params
    }),
  };
}

export function dismissTicket(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/cc/tickets/dismiss_ticket', {
      data: data
    }),
  };
}

export function approveWorkbook(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/cc/tickets/approve_workbook', {
      data: data
    }),
  };
}

export function takeActionOnCallReview(data) {
    return {
    types: GENERIC,
    promise: (client) => client.post('cc/tickets/take_action_on_call_review', {
      data: data
    }),
  };
}

export function assignTicketAction(params) {
    return {
    types: GENERIC,
    promise: (client) => client.post('cc/tickets/assign_ticket_action', {
      data: params
    }),
  };
}

export function saveManagementAccess(params) {
    return {
    types: GENERIC,
    promise: (client) => client.post('cc/tickets/save_management_access', {
      data: params
    }),
  };
}

export function addTicketNote (params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/cc/tickets/add_ticket_note', {
      data: params
    }),
  };
}

export function updateTicketNote (params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/cc/tickets/update_ticket_note', {
      data: params
    }),
  };
}

export function deleteTicketNote (params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/cc/tickets/delete_ticket_note', {
      data: params
    }),
  };
}

export function addTicketTag(params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/cc/tickets/add_ticket_tag', {
      data: params
    }),
  };
}

export function deleteTicketTag (params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/cc/tickets/delete_ticket_tag', {
      data: params
    }),
  };
}
