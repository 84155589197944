const CMS_ISS_REQUEST_GENERIC = 'inquisit-app/CMS/CMS_ISS_REQUEST_GENERIC';
const CMS_ISS_SUCCESS_GENERIC = 'inquisit-app/CMS/CMS_ISS_SUCCESS_GENERIC';
const CMS_ISS_FAIL_GENERIC = 'inquisit-app/CMS/CMS_ISS_FAIL_GENERIC';

const GENERIC = [CMS_ISS_REQUEST_GENERIC, CMS_ISS_SUCCESS_GENERIC, CMS_ISS_FAIL_GENERIC];
const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getIssueTracks(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('getcmsdata/get_tracks_by_issue', {
      params: params
    })
  };
}

export function updateIssueAreaField(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('setcmsdata/update_issue_area', {
      data: { ...data, et_ind: 1 }
    })
  };
}

export function insertUpdateIssueMetadata(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('setcmsdata/insert_update_issue_metadata', {
      data: data
    })
  };
}